/**
 * GSZhiZuo
 * GSBrandPage.js
 *
 * @author: zengzhiming
 * @date:   2023/5/29
 * @desc:   GSBrandPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    FlatList,
    ImageBackground,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import { StatusBar } from 'expo-status-bar';
import * as Font from "expo-font";
import {ToolFont} from "../../Common/Utils/GSDefined";

class GSBrandPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            brandList:[],
            loadFont:false,
        };
    }

    componentDidMount() {

        // 读取上一次数据
        storageLoad(StorageKey.CURRENT_BRAND_INFO, (code, result)=>{
            if (code === 1) {
                addShare(ShareKey.BRAND_INFO, result);
                requestAnimationFrame(()=>{
                    this.push(SceneKey.Home)
                });
            }
        });

        // 获取品牌列表数据
        this.getBrandListData();

        // 加载字体
        this.loadFont();
    }

    // 加载字体
    loadFont() {
        Font.loadAsync({
            'DFKai-SB': require('../../Resources/Fonts/DFKai-SB.ttf'),
            'MingLiU': require('../../Resources/Fonts/MingLiU.ttf'),
            'SimHei': require('../../Resources/Fonts/SimHei.ttf'),
            'SimSun': require('../../Resources/Fonts/SimSun.ttf'),
            'KaiTi': require('../../Resources/Fonts/KaiTi.ttf'),
        }).then(async ()=>{
            this.setState({loadFont:true});
        });
    }


    // 获取品牌列表数据
    getBrandListData = () => {
        showLoading();
        storageLoad(StorageKey.BRAND_LIST, (code, brandList) => {
            closeLoading();
            if (code === 1) {
                // 获取成功
                this.setState({
                    brandList:brandList
                });
            } else {
                // 获取失败
                showAlert(T("获取网络数据失败，是否需要重试?"),"",[T("否"), T("是")], (index, title)=>{
                    if (index === 1) {
                        // 重新获取数据
                        this.getBrandListData();
                    }
                });
            }
        });
    };


    // 点击Item
    onItemPress = async (data, index) => {
        // showToast('点击：'+index);
        // 处理连续点击
        if (isDoublePress(3, 1000)) return;

        // 清理缓存
        const oldBrand = getShare(ShareKey.BRAND_INFO);
        if (!isEmptyObject(oldBrand) && (oldBrand['BrandNameEn'] !== data['BrandNameEn'])) {
            showLoading();
            await storage.remove({key:StorageKey.DRAWER_LIST});
            await storage.remove({key:StorageKey.BOX_LIST});
            await storage.remove({key:StorageKey.PROGRESS_LIST});
            await storage.remove({key:StorageKey.BOX_LAST_DATA});
            await storage.remove({key:StorageKey.TEMPLATE_LIST});
            await storage.remove({key:StorageKey.SPECIAL_CHAR_LIST});
            await storage.remove({key:StorageKey.BOX_USER_INFO});
            await storage.remove({key:StorageKey.CURRENT_BRAND_INFO});
            closeLoading();
        }

        // 保存数据
        addShare(ShareKey.BRAND_INFO, data);
        storageSave(StorageKey.CURRENT_BRAND_INFO, data);

        this.push(SceneKey.Home);
    };

    render() {

        const styles = this.getStyles();
        const {Colors} = Theme;
        const {brandList, loadFont} = this.state || {};

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <FlatList renderItem={({item, index})=>this.brandItem(item, index)}
                                  data={brandList}
                                  keyExtractor={(item, index) => index+''}
                                  numColumns={1}
                                  showsVerticalScrollIndicator = {false}
                                  style={styles.flat_list}
                        />
                        {
                            loadFont &&
                            <View style={{flexDirection:"row", justifyContent:"center"}}>
                                <Text style={{fontFamily:ToolFont.BiaoKaiTi, color:Colors.theme_FFFFFF}}>{T("标楷体")}</Text>
                                <Text style={{fontFamily:ToolFont.MingLiu, color:Colors.theme_FFFFFF}}>{T("新细明体")}</Text>
                                <Text style={{fontFamily:ToolFont.HeiTi, color:Colors.theme_FFFFFF}}>{T("黑体")}</Text>
                                <Text style={{fontFamily:ToolFont.SongTi, color:Colors.theme_FFFFFF}}>{T("宋体")}</Text>
                                <Text style={{fontFamily:ToolFont.KaiTi, color:Colors.theme_FFFFFF}}>{T("楷体")}</Text>
                            </View>
                        }
                    </View>
                </SafeAreaView>
                <StatusBar hidden={true} style="dark" backgroundColor={Colors.theme_first_s} />
            </View>
        );
    }

    brandItem(item, index){

        const {Images} = Theme;
        const styles = this.getStyles();

        const itemWidth = screenWidth-40;
        const itemHeight = itemWidth*0.5;
        return (
            <TouchableOpacity style={styles.item} onPress={()=>this.onItemPress(item, index)}>
                <ImageBackground source={Images.brand_item_bg} style={{width:itemWidth, height:itemHeight}}>
                    <View style={styles.item_mask}>
                        <Text style={styles.item_text}>{item['BrandNameCn']}</Text>
                    </View>
                </ImageBackground>
            </TouchableOpacity>
        );

    };

    getStyles() {

        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            flat_list:{
                marginBottom: 10,
            },
            item:{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
            },
            item_mask:{
                width:'100%',
                height:'100%',
                justifyContent: 'center',
                alignItems: 'center',
            },
            item_text:{
                fontSize: 60,
                fontWeight:'bold',
                color:Colors.theme_FFFFFF_s,
            },
        });
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSBrandPage)


// export default GSBrandPage;