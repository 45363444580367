/**
 * GSZhiZuo
 * GSBaseView.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:18
 * @desc:   GSBaseView
 **/
'use strict';

import React, {PureComponent} from 'react';


export default class GSBaseView extends PureComponent {


}
