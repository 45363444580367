/**
 * GSZhiZuo
 * index.js
 *
 * @author zengzhiming
 * @date   2019/1/18
 **/
"use strict";


export const Colors = {

    theme_FFFFFF:"#FFFFFF",
    theme_000000:"#000000",

    box_edit_sign_EDEDED:"#EDEDED",
    box_edit_sign_282817:"#282817",

    box_list_FFF8DA:"#FFF8DA",
    box_list_80807A:"#80807A",
    box_list_D7D7D7:"#D7D7D7",

    box_userinfo_D7D7D7:"#D7D7D7",

    box_template_F5F5F5:"#F5F5F5",
    box_template_EAEAEA:"#EAEAEA",
    box_template_2A2121:"#2A2121",

    box_progress_DDDDDD:"#DDDDDD",
    box_progress_80807A:"#80807A",
    box_progress_FFF8DA:"#FFF8DA",

    boxbg_select_666666:"#666666",
    boxbg_select_F0F4F7:"#F0F4F7",

    boxbg_szie_select_FFF8DA:"#FFF8DA",
    boxbg_szie_select_80807A:"#80807A",
    boxbg_szie_select_D7D7D7:"#D7D7D7",

    boxbg_userinfo_D7D7D7:"#D7D7D7",

    theme_first_s:"#FFBF25",
    theme_FFFFFF_s:"#FFFFFF",
    theme_000000_s:"#000000",
    theme_00000000_s:"#00000000",
    theme_second_s:"#EB8463",

    goldbar_home_00000066_s:"#00000066",
    goldbar_home_remind_FFF7D3_s:"#FFF7D3",
    goldbar_home_remind_312E17_s:"#312E17",

    goldbar_drawer_672A32_s:"#672A32",
    goldbar_drawer_EE5E51_s:"#EE5E51",

    goldbar_edit_EB4E00_s:"#EB4E00",
    goldbar_edit_674D41_s:"#674D41",
    goldbar_edit_39393A_s:"#39393A",
    goldbar_edit_B4B4B4_s:"#B4B4B4",

    goldbar_edit_tool_333333_s:"#333333",

    goldbar_addtext_9A9A9A_s:"#9A9A9A",

    box_list_00000055_s:"#00000055",

    box_userinfo_888888_s:"#888888",

    box_edit_add_888888_s:"#888888",
    box_edit_add_80807A_s:"#80807A",

    box_edit_tool_FF9E7F_s:"#FF9E7F",

    box_edit_view_B4B4B4_s:"#B4B4B4",
    box_edit_view_FFF7D388_s:"#FFF7D388",
    box_edit_view_FFF7D3_s:"#FFF7D3",
    box_edit_view_EB4E00_s:"#EB4E00",

    boxbg_edit_tool_FF9E7F_s:"#FF9E7F",
    boxbg_edit_view_B4B4B4_s:"#B4B4B4",
    boxbg_edit_view_FFF7D388_s:"#FFF7D388",
    boxbg_edit_view_FFF7D3_s:"#FFF7D3",
    boxbg_edit_view_EB4E00_s:"#EB4E00",

    boxbg_edit_add_888888_s:"#888888",
    boxbg_edit_add_80807A_s:"#80807A",

    boxbg_preview_555555_s:"#555555",
    boxbg_userinfo_888888_s:"#888888",

    boxbg_share_FE5C57_s:"#FE5C57",
    boxbg_share_FFFAEE_s:"#FFFAEE",
    boxbg_share_FFDE97_s:"#FFDE97",

};

export default Colors;