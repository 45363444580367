/**
 * GSZhiZuo
 * GSGoldPreviewPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/14
 * @desc:   GSGoldPreviewPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    View,
    TouchableOpacity,
    Image,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";

import {saveLocalUriImage} from "../../Common/Utils/GSMediaLibrary";
import {isWXAppInstalled, registerApp, shareLocalImage} from  "../../Common/Utils/GSWeChatShare";

class GSGoldPreviewPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        // 获取参数
        const {editData, imgUri} = props.route.params||{editData:{},imgUri:""};
        const { Width, Length} = editData || {};

        // 计算图片区域宽高
        const imgWidth = screenWidth;
        const imgHeight = screenWidth*(Length/Width);

        this.state = {
            imgUri:imgUri,
            imgWidth:imgWidth,
            imgHeight:imgHeight,
            isWeChat:false,
        };

    }


    componentDidMount() {

        // registerApp().then((res)=>{
        //     // 判断微信是否安装
        //     this.isWeChatInstalled();
        // }).catch((res)=>{
        //     // log(-1,res)
        // })
    }



    // 判断微信是否安装
    isWeChatInstalled = () => {
        isWXAppInstalled().then((isInstalled) => {
            if (isInstalled) {
                this.setState({
                    isWeChat:true
                });
            }
        })
    };


    // 点击取消
    onCancelPress = () => {
        // 处理连续点击
        if (isDoublePress()) return;
        this.goBack();
    };

    // 点击保存
    onSavePress = () => {

        // 处理连续点击
        if (isDoublePress()) return;

        const {imgUri} = this.state;
        // 保存图片到相册
        saveLocalUriImage(imgUri, (code, msg)=>{
            if (code === 1) {
                // 保存成功
                showToast(T("编辑图片已经保存到相册"));
                this.navigate(SceneKey.Home)
            } else if (code === 0) {
                // 保存失败，没有权限
                showAlert(T("访问相册受限"),T("请在设置中打开相册访问权限"),  [T("确定")], ()=>{ });
            } else {
                // 保存失败，出现错误
                showAlert(T("保存图片失败"),msg,  [T("确定")], ()=>{ });
            }
        });
    };

    // 点击分享
    onSharePress = () => {
        // 处理连续点击
        if (isDoublePress()) return;

        const {imgUri} = this.state;

        // 微信分享图片
        shareLocalImage({
            scene: 0,
            imageUrl: imgUri,
        }) .catch((err)=>{
            showToast(T("分享失败"), err.message);
        });

        // // 微信分享图片
        // shareToSession({
        //     type: 'imageFile',
        //     imageUrl: "file://" + imgUri,
        // }) .catch((err)=>{
        //     showToast(T("分享失败"), err.message);
        // });

        // 保存图片
        saveLocalUriImage(imgUri);
    };


    render() {

        const {Images} = Theme;
        const styles = this.getStyles();

        const {imgUri, imgWidth, imgHeight, isWeChat} = this.state || {};

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View style={[styles.header_button_view]}>
                            <TouchableOpacity style={styles.header_button_left} onPress={()=>this.onCancelPress()}>
                                <Image style={styles.header_button_Image} source={Images.preview_btn_cancel}/>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={()=>this.onSavePress()}>
                                <Image style={styles.header_button_Image} source={Images.preview_btn_save}/>
                            </TouchableOpacity>
                            {
                                isWeChat &&
                                <TouchableOpacity onPress={()=>this.onSharePress()}>
                                    <Image style={styles.header_button_Image} source={Images.preview_btn_share}/>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={{flex:1,}}>
                            <Image style={{
                                width:imgWidth,
                                height:imgHeight,
                                marginTop:(screenHeight-statusNavBarHeight-imgHeight)*0.4,
                            }} resizeMode={'contain'} source={{uri:imgUri}} />
                        </View>

                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_000000_s,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_000000_s,
            },
            header_button_view:{
                flexDirection: 'row',
                justifyContent:'flex-end',
                height:44,
            },
            header_button_Image:{
                margin: 10,
                width:28,
                height:28,
            },
            header_button_left:{
                position: 'absolute',
                left:0,
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSGoldPreviewPage)


// export default GSGoldPreviewPage;
