/**
 * GSZhiZuo
 * GSGlobals.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:40
 * @desc:   GSGlobals
 **/

'use strict';

import {Platform, StatusBar, PixelRatio} from  'react-native';
import {cloneDeep} from 'lodash';

import {statusBarHeight} from '../Common/Utils/GSConstants';

import {GSLog} from '../Common/Utils/GSLog';
import {StorageKey, storage, storageSave, storageLoad, storageRemove} from '../Common/Storage/GSStorage';

import {getScreenSize, getScreenHeight, getScreenWidth, Frame, isTablet} from '../Common/Utils/GSFrame';
import {isNull, isEmptyObject, isEmptyString, isPhoneNumber} from '../Common/Utils/GSCheck';

import {showToast} from '../Common/Utils/GSToast';
import {showAlert} from '../Common/Utils/GSAlert';
import {showLoading, closeLoading} from '../Common/Utils/GSLoading';

import {showActionSheet} from '../Common/Utils/GSActionSheet';
import * as ReduxStore  from '../Common/Redux/GSReduxStore';
import {SceneKey} from './GSNavigation';

import {isDoublePress} from "../Common/Utils/GSDoublePress";
import {ShareKey, getShare, addShare, delShare} from "../Common/Storage/GSShare";
import * as Languages from '../Common/Languages/GSLanguages';
import * as Theme from "../Common/Theme/GSTheme";

import {HttpCode} from '../Common/Http/GSHttpFilter';
import * as HttpRequest from '../Common/Http/GSHttpRequest';

const pixelRatio = PixelRatio.get();
const onePixelRatio = 1.0/pixelRatio;
const isAndroid = (Platform.OS === 'android');
const isIOS = (Platform.OS === 'ios');
const isWeb = (Platform.OS === 'web');


const navBarHeight = 44;
const statusNavBarHeight = statusBarHeight+navBarHeight;


global.Http = HttpRequest;


// 全局属性导出
global.cloneDeep = cloneDeep;
global.pixelRatio = pixelRatio;
global.onePixelRatio = onePixelRatio;
global.isAndroid = isAndroid;
global.isIOS = isIOS;
global.isWeb = isWeb;

global.statusBarHeight = statusBarHeight;
global.navBarHeight = navBarHeight;
global.statusNavBarHeight = statusNavBarHeight;
global.HttpCode = HttpCode;

global.Theme = Theme;
global.initTheme = Theme.initTheme;
global.changeTheme = Theme.changeTheme;
global.ThemeType = Theme.ThemeType;

global.StorageKey = StorageKey;
global.storage = storage;
global.storageSave = storageSave;
global.storageLoad = storageLoad;
global.storageRemove = storageRemove;

global.ShareKey = ShareKey;
global.getShare = getShare;
global.addShare = addShare;
global.delShare = delShare;

global.Languages = Languages;
global.LanguageType =Languages.LanguageType;
global.changeLanguage = Languages.changeLanguage;
global.initLanguage = Languages.initLanguage;
global.T = Languages.getText;

// 全局方法导出
global.log = GSLog;
global.isNull = isNull;
global.isEmptyObject = isEmptyObject;
global.isEmptyString = isEmptyString;
global.isDoublePress = isDoublePress;
global.isPhoneNumber = isPhoneNumber;

global.Frame = Frame;
global.screenWidth = getScreenWidth();
global.screenHeight = getScreenHeight();
global.isTablet = isTablet();

global.showToast = showToast;
global.showAlert = showAlert;
global.showLoading = showLoading;
global.closeLoading = closeLoading;
global.showActionSheet = showActionSheet;

global.ReduxStore = ReduxStore;


global.SceneKey = SceneKey;

