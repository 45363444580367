/**
 * GSZhiZuo
 * GSAlert.js
 *
 * @author: zengzhiming
 * @date:   2022/8/29 10:50
 * @desc:   GSAlert
 **/
'use strict';


import React from 'react';
import {StyleSheet, Text, View, TouchableOpacity} from 'react-native';
import 'setimmediate';
import RootSiblings from "react-native-root-siblings";


let sibling = null;


/**
 * 显示AlertView
 * title 标题
 * message 消息
 * btnTitles 按钮数组
 * clickBlock 点击回调
 * noHide 点击按钮后不隐藏
 **/
export const showAlert = (title = '', message = '', btnTitles = [], clickBlock, noHide) => {

    // 空值判断
    if (isEmptyString(title) && isEmptyString(message)){
        return;
    }

    // 添加默认按钮
    if (isEmptyObject(btnTitles)){
        btnTitles = ['OK'];
    }

    // 渲染 AlertView
    hideAlert();
    sibling = new RootSiblings(
        <View style={styles.container}>
            <View style={styles.alert_view}>
                <View style={styles.text_view}>
                    {!isEmptyString(title) &&<Text numberOfLines={0} style={styles.text_title}>{title}</Text> }
                    {!isEmptyString(message)&&<Text numberOfLines={0} style={styles.text_message}>{message}</Text>}
                </View>
                <View style={styles.btn_view}>
                    {
                        btnTitles.map((btnTitle, index)=>
                            <TouchableOpacity  key={index} style={styles.btn_btn} activeOpacity={0.8} onPress={()=>{
                                !noHide&&hideAlert();
                                clickBlock&&clickBlock(index, btnTitle)
                            }}>
                                <Text style={styles.btn_text}>{btnTitle}</Text>
                            </TouchableOpacity>
                        )
                    }
                </View>
            </View>
        </View>
    );
};


// 关闭
export const hideAlert = () =>{
    sibling && sibling.destroy();
};


// CSS样式
const styles = StyleSheet.create({
    container:{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:'#00000055',
    },
    alert_view:{
        width:270,
        borderRadius:12,
        overflow:'hidden',
        backgroundColor: '#FFFFFF',
    },
    text_view:{
        padding: 16,
        borderBottomWidth: 0.5,
        borderBottomColor:'#DDDDDD',
    },
    text_title:{
        margin: 2,
        textAlign:'center',
        fontSize: 17,
        fontWeight: 'bold',
    },
    text_message:{
        paddingTop:5,
        paddingBottom:5,
        textAlign:'center',
        fontSize: 13,
        margin: 2,
    },
    btn_view:{
        height:45,
        flexDirection:'row',
    },
    btn_btn:{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderLeftWidth: 0.5,
        borderLeftColor:'#DDDDDD',
    },
    btn_text:{
        fontSize: 16,
        fontWeight: 'bold',
    }

});
