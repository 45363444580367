/**
 * GSZhiZuo
 * GSDrawerView.js
 *
 * @author: zengzhiming
 * @date:   2023/5/31
 * @desc:   GSDrawerView
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    SectionList,
    Image,
} from 'react-native';
import BaseView from "../../../Common/Component/BaseComponent/GSBaseView";

class GSDrawerView extends BaseView {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            drawerList : [],
            selSectionId : 0,
            selItemId : 0,
            showSectionId:0,
        };
    }

    componentDidMount() {
        // 获取侧边栏数据
        this.getDrawerListData();
    }


    // 获取侧边栏数据
    getDrawerListData = () =>{
        showLoading();
        storageLoad(StorageKey.DRAWER_LIST, (code, drawerList) => {
            closeLoading();
            if (code === 1) {
                // 查找默认SectionId
                let selSectionId = -1;
                for (let i = 0; i < drawerList.length; i++) {
                    if (!isEmptyObject(drawerList[i])) {
                        selSectionId = i;
                        break;
                    }
                }
                // 获取成功
                this.setState({
                    drawerList:drawerList,
                    showSectionId:selSectionId,
                    selSectionId:selSectionId,
                    selItemId:0,
                });
                if (!isEmptyObject(drawerList)){
                        const homeData = drawerList[selSectionId]["data"][0];
                        this.props.onPress && this.props.onPress(0, homeData);
                }

            } else {
                // 获取失败
                showAlert(T("获取网络数据失败，是否需要重试?"),"",[T("否"), T("是")], (index, title)=>{
                    if (index === 1) {
                        // 重新获取数据
                        this.getDrawerListData();
                    }
                });
            }
        });
    };

    // 点击Section
    onSectionPress = (obj) => {
        let sectionId = obj.section.sectionId;
        if (sectionId === this.state.showSectionId) {
            sectionId = -1;
        }
        this.setState ({
            showSectionId:sectionId,
        });
    };


    // 点击Item
    onItemPress = (obj) => {
        const sectionId = obj.section.sectionId;
        const itemId = obj.index;
        this.setState ({
            showSectionId:sectionId,
            selSectionId:sectionId,
            selItemId:itemId,
        });
        const homeData = obj.item;
        this.props.onPress && this.props.onPress(0, homeData);
    };


    // 点击侧边栏下面的按钮
    onSidebarButtonPress = (tag) => {
        // 处理连续点击
        if (isDoublePress()) return;

        this.props.onPress && this.props.onPress(tag, {});
    };

    // 渲染Item
    renderItem = (obj) => {
        const styles = this.getStyles();
        if (obj.section.sectionId === this.state.showSectionId) {
            const isSelect = obj.section.sectionId === this.state.selSectionId && obj.index === this.state.selItemId;
            const templateName = obj.item['TemplateName'];
            const textStyle = isSelect ? styles.section_item_title_sel : styles.section_item_title;
            return (
                <TouchableOpacity style={styles.section_item_view} onPress={()=>this.onItemPress(obj)}>
                    <Text style={textStyle}>{templateName}</Text>
                </TouchableOpacity>
            );
        }
        return null;
    };

    // 渲染Section
    renderSectionHeader = (obj) => {
        const {Images, Colors} = Theme;
        const styles = this.getStyles();
        const typeName = obj.section['TypeName'];
        const isShow = obj.section.sectionId === this.state.showSectionId;
        const imageSource = isShow ? Images.drawer_more_up : Images.drawer_more_down;
        const selStyle = isShow ? {backgroundColor: Colors.theme_first_s} : {};
        return (
            <TouchableOpacity activeOpacity={1} style={[styles.section_header_view, selStyle]} onPress={()=>this.onSectionPress(obj)}>
                <Text style={styles.section_header_title}>{typeName}</Text>
                <Image source={imageSource} style={styles.section_header_image}/>
            </TouchableOpacity>
        );
    };

    render() {

        const {drawerList} = this.state || {};
        const brandInfo = getShare(ShareKey.BRAND_INFO);

        const {Images} = Theme;
        const styles = this.getStyles();

        return (
            <View style={styles.container}>
                <View style={{marginTop: 20, marginLeft:16, marginRight:16,}}>
                    <TouchableOpacity style={styles.brand_view_btn} onPress={()=>this.onSidebarButtonPress(3)}>
                        <Text style={styles.brand_view_btn_title}>{brandInfo['BrandNameCn']}</Text>
                        <Image style={styles.brand_view_btn_img} source={Images.drawer_brand_switch}/>
                    </TouchableOpacity>
                </View>
                <SectionList
                    renderSectionHeader={this.renderSectionHeader}
                    renderItem={this.renderItem}
                    sections={drawerList}
                    keyExtractor={(item, index) => index+''}
                    style={{marginTop: 20}}
                    alwaysBounceVertical={false}
                />
                <View style={styles.button_view}>
                    <View style={styles.button_row_view}>
                        <TouchableOpacity  style={styles.button_view_btn} onPress={()=>this.onSidebarButtonPress(4)}>
                            <Text style={styles.button_view_btn_title}>{T("推荐模板")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button_view_btn} onPress={()=>this.onSidebarButtonPress(1)}>
                            <Text style={styles.button_view_btn_title}>{T("胶箱制作")}</Text>
                        </TouchableOpacity>
                    </View>
                    <View  style={styles.button_row_view}>
                        <TouchableOpacity  style={styles.button_view_btn} onPress={()=>this.onSidebarButtonPress(2)}>
                            <Text style={styles.button_view_btn_title}>{T("进度查询")}</Text>
                        </TouchableOpacity>
                        {
                            // <View style={{width:'45%'}}/>
                            <TouchableOpacity  style={styles.button_view_btn} onPress={()=>this.onSidebarButtonPress(5)}>
                                <Text style={styles.button_view_btn_title}>{T("胶箱背景制作")}</Text>
                            </TouchableOpacity>
                        }
                    </View>
                </View>

            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create(
            {
                container: {
                    flex: 1,
                    backgroundColor:Colors.theme_second_s,
                },
                section_header_view:{
                    flexDirection: 'row',
                    justifyContent:'space-between',
                    paddingLeft:20,
                    paddingRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                },
                section_header_title:{
                    color:Colors.theme_FFFFFF_s,
                    fontSize:18,
                },
                section_header_image:{
                    width: 10,
                    height:10,
                    resizeMode:'contain',
                },
                section_item_view:{
                    paddingLeft: 40,
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor:Colors.theme_first_s
                },
                section_item_title:{
                    color:Colors.goldbar_drawer_672A32_s,
                    fontSize:16,
                },
                section_item_title_sel:{
                    color:Colors.goldbar_drawer_EE5E51_s,
                    fontSize:16,
                },
                brand_view_btn:{
                    width:'100%',
                    backgroundColor:Colors.theme_first_s,
                    flexDirection: 'row',
                    justifyContent:'center',
                    alignItems: 'center',
                    borderRadius:5,
                    height:44,
                },
                brand_view_btn_title:{
                    fontSize:18,
                },
                brand_view_btn_img:{
                    width:16,
                    height:16,
                    marginLeft: 1,
                },
                button_view:{
                    padding:10,
                    height:120,
                },
                button_row_view:{
                    flexDirection:'row',
                    justifyContent:'space-around',
                    alignItems: 'center',
                    padding:10,
                },
                button_view_btn:{
                    backgroundColor:Colors.theme_first_s,
                    width:'45%',
                    justifyContent:'center',
                    alignItems: 'center',
                    borderRadius:12,
                    height:30,
                },
                button_view_btn_title:{
                    fontSize:12,
                },
            }
        )
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSDrawerView)


// export default GSDrawerView;
