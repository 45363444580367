/**
 * GSZhiZuo
 * index.js
 *
 * @author zengzhiming
 * @date   2019/1/18
 **/
"use strict";


export const Images = {
    launch_screen:require('./launch_screen.png'),
    launch_screen_bg:require('./launch_screen_bg.png'),
    brand_item_bg:require('./brand_item_bg.png'),
    drawer_more_down:require('./drawer_more_down.png'),
    drawer_more_up:require('./drawer_more_up.png'),
    drawer_brand_switch:require('./drawer_brand_switch.png'),
    main_def_img:require('./main_def_img.png'),
    home_left_menu:require('./home_left_menu.png'),
    tool_btn_color_def:require('./tool_btn_color_def.png'),
    tool_btn_color_sel:require('./tool_btn_color_sel.png'),
    tool_btn_edit_def:require('./tool_btn_edit_def.png'),
    tool_btn_font_def:require('./tool_btn_font_def.png'),
    tool_btn_font_sel:require('./tool_btn_font_sel.png'),
    tool_btn_move_def:require('./tool_btn_move_def.png'),
    tool_btn_move_sel:require('./tool_btn_move_sel.png'),
    tool_btn_size_def:require('./tool_btn_size_def.png'),
    tool_btn_size_sel:require('./tool_btn_size_sel.png'),
    tool_color_blue_def:require('./tool_color_blue_def.png'),
    tool_color_blue_sel:require('./tool_color_blue_sel.png'),
    tool_color_brown_def:require('./tool_color_brown_def.png'),
    tool_color_brown_sel:require('./tool_color_brown_sel.png'),
    tool_color_red_def:require('./tool_color_red_def.png'),
    tool_color_red_sel:require('./tool_color_red_sel.png'),
    tool_color_white_def:require('./tool_color_white_def.png'),
    tool_color_white_sel:require('./tool_color_white_sel.png'),
    tool_color_bg_beige_def:require('./tool_color_bg_beige_def.png'),
    tool_color_bg_beige_sel:require('./tool_color_bg_beige_sel.png'),
    tool_color_bg_black_def:require('./tool_color_bg_black_def.png'),
    tool_color_bg_black_sel:require('./tool_color_bg_black_sel.png'),
    tool_color_bg_brown_def:require('./tool_color_bg_brown_def.png'),
    tool_color_bg_brown_sel:require('./tool_color_bg_brown_sel.png'),
    tool_color_bg_purple_def:require('./tool_color_bg_purple_def.png'),
    tool_color_bg_purple_sel:require('./tool_color_bg_purple_sel.png'),
    tool_color_bg_white_def:require('./tool_color_bg_white_def.png'),
    tool_color_bg_white_sel:require('./tool_color_bg_white_sel.png'),
    tool_move_down:require('./tool_move_down.png'),
    tool_move_left:require('./tool_move_left.png'),
    tool_move_right:require('./tool_move_right.png'),
    tool_move_up:require('./tool_move_up.png'),
    tool_szie_add:require('./tool_szie_add.png'),
    tool_szie_reduce:require('./tool_szie_reduce.png'),
    preview_btn_cancel:require('./preview_btn_cancel.png'),
    preview_btn_save:require('./preview_btn_save.png'),
    preview_btn_share:require('./preview_btn_share.png'),
    model_explain_close:require('./model_explain_close.png'),
    model_explain_image:require('./model_explain_image.png'),
    box_nav_explain:require('./box_nav_explain.png'),
    edit_text_delete:require('./edit_text_delete.png'),
    box_edit_grid_phone:require('./box_edit_grid_phone.png'),
    box_edit_grid_pad:require('./box_edit_grid_pad.png'),
    draw_sign_back:require('./draw_sign_back.png'),
    boxbg_arrows_right:require('./boxbg_arrows_right.png'),
    boxbg_preview_qrcode_1:require('./boxbg_preview_qrcode_1.png'),
    boxbg_preview_qrcode_2:require('./boxbg_preview_qrcode_2.png'),
    boxbg_def_img:require('./boxbg_def_img.png'),
};

export default Images;