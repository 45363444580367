/**
 * GSZhiZuo
 * GSBoxBgAddTextPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/26
 * @desc:   GSBoxBgAddTextPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    TextInput,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import {TextModel} from "../../Common/Utils/GSDefined";
import {
    plasticBoxBgUpdatedEditButtonAction,
    plasticBoxBgAddEditButtonAction,
    plasticBoxBgDeleteEditButtonAction,
} from "../../Common/Redux/GSReduxAction";

class GSBoxBgAddTextPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        let textValue = '';
        let textDirection = 1;

        const currEditBtn = props.route.params?.["currEditBtn"];

        if (!isEmptyObject(currEditBtn)){
            textValue = currEditBtn.textValue;
            textDirection = currEditBtn.textDirection;
        }

        this.state = {
            textValue:textValue,
            textDirection:textDirection,
        };
    }

    componentDidMount() {
        setTimeout(()=>{
            this.textInput.focus();
        },100);
    }

    // 点击取消
    onCancelPress = () => {
        this.goBack();
    };

    // 点击完成
    onFinishPress = () => {

        let textValue = this.state.textValue;
        let textDirection = this.state.textDirection;
        const currEditBtn = this.props.route.params?.["currEditBtn"];
        if (!isEmptyObject(currEditBtn)){
            if (isEmptyString(textValue)){
                // 移除对象
                plasticBoxBgDeleteEditButtonAction();
            }  else {
                // 修改原来对象
                let newEditBtn= {...currEditBtn};
                newEditBtn.textValue = textValue;
                newEditBtn.textDirection = textDirection;
                plasticBoxBgUpdatedEditButtonAction(newEditBtn);
            }
        }else if(!isEmptyString(textValue)) {
            // 添加新对象
            let newEditBtn= cloneDeep(TextModel);
            newEditBtn.textValue = textValue;
            newEditBtn.textDirection = textDirection;

            // log(newEditBtn);

            plasticBoxBgAddEditButtonAction(newEditBtn);
        }

        // 返回
        this.goBack();


    };

    // 文本方向点击
    onTextDirectionPress = (tag) => {
        this.setState({
            textDirection:tag,
        });
    };

    render() {

        const {textDirection, textValue} = this.state;

        const styles = this.getStyles();
        const {Colors} = Theme;

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View style={[styles.header_navigation]}>
                            <TouchableOpacity onPress={this.onCancelPress}>
                                <Text style={styles.header_navigation_text} >{T("取消")}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={this.onFinishPress}>
                                <Text style={styles.header_navigation_text}>{T("完成")}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.btn_view}>
                            <View style={styles.switch_view}>
                                <TouchableOpacity onPress={()=>this.onTextDirectionPress(1)}>
                                    <Text style={textDirection === 1 ? styles.switch_view_text_sel : styles.switch_view_text}>{T("竖排")}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={()=>this.onTextDirectionPress(0)}>
                                    <Text style={textDirection === 0 ? styles.switch_view_text_sel : styles.switch_view_text}>{T("横排")}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={styles.text_input_view}>
                            <TextInput style={styles.text_input}
                                       multiline={true}
                                       autoFocus={false}
                                       placeholder={T("请输入文本，本区域不能缩放和移动")}
                                       placeholderTextColor={Colors.boxbg_edit_add_888888_s}
                                       onChangeText={(text) => this.setState({textValue:text})}
                                       value={textValue}
                                       ref={(ref)=>{this.textInput = ref}}
                            />
                        </View>
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            header_navigation:{
                height:44,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems:'center',
            },
            header_navigation_text:{
                color:Colors.theme_000000,
                paddingTop:5,
                paddingBottom:5,
                paddingRight:16,
                paddingLeft:16,
                fontSize:18,
            },
            btn_view:{
                marginLeft: 20,
                marginTop:10,
                flexDirection: 'row',
                alignItems: 'center',
            },
            switch_view:{
                // width:100,
                // height:30,
                flexDirection: 'row',
                borderWidth: 1,
                borderRadius:3,
                borderColor:Colors.theme_first_s,
                overflow:'hidden',
            },
            switch_view_text:{
                padding:5,
                fontSize:16,
                textAlign:'center',
                color:Colors.boxbg_edit_add_80807A_s,
                backgroundColor:Colors.theme_FFFFFF,
            },
            switch_view_text_sel:{
                padding:5,
                fontSize:16,
                textAlign:'center',
                color: Colors.theme_000000_s,
                backgroundColor:Colors.theme_first_s,
            },
            text_input_view:{
                flex: 1,
                margin:20,
                overflow:"hidden",
            },
            text_input:{
                flex: 1,
                fontSize:20,
                padding: 1,
                margin:-1,
                textAlignVertical: 'top',
                color:Colors.theme_000000,
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSBoxBgAddTextPage)


// export default GSBoxBgAddTextPage;