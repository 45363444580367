/**
 * GSZhiZuo
 * GSImageToolView.js
 *
 * @author: zengzhiming
 * @date:   2023/6/6
 * @desc:   GSImageToolView
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    Image,
} from 'react-native';
import Slider from '@react-native-community/slider';
import BaseView from "../../../Common/Component/BaseComponent/GSBaseView";
import {AnimatedDict, goldBarUpdatedEditButtonAction} from "../../../Common/Redux/GSReduxAction";
import {maxScale, minScale} from "../../../Common/Utils/GSDefined";
import {launchCameraPhoto, launchSingleImageLibrary} from "../../../Common/Utils/GSImagePicker";

const defOffset = 1;

class GSImageToolView extends BaseView {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            toolSel:1,
        };
    }


    // 选中工具
    onToolButtonPress = (tag) => {

        if (tag === 0) {
            // 修改图片
            this.showImageActionSheet((code, response)=>{
                if (code === 1) {
                    // log(response);
                    // 获取成功
                    const {editBoxList, currBoxIndex, currBtnIndex} = this.props || {};
                    const currEditBtn = editBoxList[currBoxIndex]['BtnList'][currBtnIndex];
                    let imageBtn= {...currEditBtn};
                    imageBtn.imgUri = response.uri;
                    imageBtn.width = response.width;
                    imageBtn.height = response.height;
                    goldBarUpdatedEditButtonAction(imageBtn);
                } else if (code === 0) {
                    // 获取失败，没有权限
                    showAlert(T("访问相册受限"),T("请在设置中打开相册访问权限"),  [T("确定")], ()=>{ });
                } else if (code !== 2) {
                    // 获取失败，出现错误
                    showAlert(T("保存图片失败"),response.message,  [T("确定")], ()=>{ });
                }
            });

            tag = 1;
        }

        this.setState({
            toolSel:tag,
        });
    };


    // 显示图片选择器
    showImageActionSheet = (callback) => {

        showActionSheet([T("相机"),T("相册")],T("取消"), (index)=>{

            if (index === 0)  {
                // 相机
                launchCameraPhoto( (code, msg, response) => {
                    // log(code, msg, response);
                    callback && callback(code, response);
                });
            } else if (index === 1) {
                // 相册
                launchSingleImageLibrary((code, msg,  response) => {
                    callback && callback(code, response);
                });
            }
        });
    };



    // 数据改变
    onDataChange = (type, currEditBtn, changeValue) => {
        let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
        switch (type) {
            case 1:{ // 字体大小
                let sizeScale = changeValue.sizeScale;
                currEditBtn.sizeScale = sizeScale;
                currEditBtnAnimated.baseScale.setValue(sizeScale);
                currEditBtnAnimated.pinchScale.setValue(1);
                goldBarUpdatedEditButtonAction(currEditBtn);
                break;
            }
            case 2:{ // 移动工具
                let lastOffsetX = currEditBtn.lastOffset.x + changeValue.lastOffset.x;
                let lastOffsetY = currEditBtn.lastOffset.y + changeValue.lastOffset.y;
                let lastOffset =  {x:lastOffsetX,y:lastOffsetY};
                currEditBtn.lastOffset = lastOffset;
                currEditBtnAnimated.offsetValue.setOffset(lastOffset);
                currEditBtnAnimated.offsetValue.setValue({x:0,y:0});
                goldBarUpdatedEditButtonAction(currEditBtn);
                break;
            }
        }

    };

    // 大小改变
    onSizeScaleChange = (type, currEditBtn, changeValue) => {
        let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
        let sizeScale = changeValue.sizeScale;
        currEditBtn.sizeScale = sizeScale;
        currEditBtnAnimated.baseScale.setValue(sizeScale);
        currEditBtnAnimated.pinchScale.setValue(1);

    };



    // 渲染界面
    render() {
        const {toolSel} = this.state;
        const {editBoxList, currBoxIndex, currBtnIndex} = this.props || {};

        let isShow = false;
        if (currBtnIndex >= 0 && currBoxIndex >= 0) {
            const currEditBtn = {...editBoxList[currBoxIndex]['BtnList'][currBtnIndex]};
            if (!isEmptyString(currEditBtn.imgUri)) {
                isShow = true;
            }
        }

        const {ButtonList} = this.getListDict();
        const styles = this.getStyles();

        return (
            isShow &&
            <View style={styles.tool_view}>
                <TouchableOpacity activeOpacity={1} style={styles.tool_details_stance}>
                    {this.toolDetailsView(toolSel)}
                </TouchableOpacity>
                <View style={styles.tool_main_view}>
                    {
                        ButtonList.map((obj, index)=>
                            <TouchableOpacity key={index} style={styles.tool_main_button} onPress={()=>this.onToolButtonPress(obj.tag)}>
                                <Image style={styles.tool_main_image} source={toolSel===obj.tag ? obj.selIcon : obj.defIcon} />
                            </TouchableOpacity>
                        )
                    }
                </View>
            </View>
        );
    }


    // 工具详情
    toolDetailsView = (tag) => {

        const {currBtnIndex, currBoxIndex, editBoxList} = this.props || {};
        const currEditBtn = {...editBoxList[currBoxIndex]['BtnList'][currBtnIndex]};
        const {sizeScale} = currEditBtn;

        const {Images, Colors} = Theme;
        const {MoveList} = this.getListDict();
        const styles = this.getStyles();

        switch(tag){
            case 1:{ // 字体大小
                return (
                    <View style={styles.tool_details_view}>
                        <View style={styles.tool_details_size_view}>
                            <Slider style={styles.tool_details_size_slider}
                                    trackStyle={{height:2}}
                                    thumbStyle={{height:26, width:26, borderRadius:18,}}
                                    maximumValue={maxScale}
                                    minimumValue={minScale}
                                    thumbTintColor={Colors.theme_first_s}
                                    minimumTrackTintColor={Colors.theme_first_s}
                                    maximumTrackTintColor={Colors.theme_FFFFFF_s}
                                    thumbTouchSize={{width: 80, height: 80}}
                                    onValueChange={(value)=>this.onSizeScaleChange(tag, currEditBtn, {sizeScale:value})}
                                    onSlidingComplete={(value)=>this.onDataChange(tag, currEditBtn, {sizeScale:value})}
                                    value={sizeScale}
                                    step={0.1}
                            />
                            <TouchableOpacity style={{position:'absolute', left:0}} onPress={()=>{this.onDataChange(tag, currEditBtn, {sizeScale:sizeScale-0.1})}}>
                                <Image style={{margin:10,width:18,height:18}} source={Images.tool_szie_reduce} />
                            </TouchableOpacity>
                            <TouchableOpacity  onPress={()=>{this.onDataChange(tag, currEditBtn, {sizeScale:sizeScale+0.1})}}>
                                <Image style={{margin:10,width:18,height:18}} source={Images.tool_szie_add} />
                            </TouchableOpacity>
                        </View>

                    </View>
                );
            }
            case 2:{ // 移动工具
                return (
                    <View style={styles.tool_details_view}>
                        {
                            MoveList.map((obj, index)=>
                                <TouchableOpacity key={index} style={styles.tool_details_move_btn} onPress={()=>this.onDataChange(tag, currEditBtn, obj.data)}>
                                    <Image style={styles.tool_main_image} source={obj.icon} />
                                </TouchableOpacity>
                            )
                        }
                    </View>
                );
            }
            default:break;
        }

    };


    // 样式
    getStyles() {

        const {Colors} = Theme;

        return StyleSheet.create({
            tool_view:{
                width:screenWidth,
                height:150,
                position:'absolute',
                bottom:0,
            },
            tool_details_stance: {
                flex:1,
                backgroundColor:Colors.theme_00000000_s,
            },
            tool_details_view: {
                flex:1,
                backgroundColor:Colors.theme_000000_s,
                borderBottomWidth: 0.5,
                borderBottomColor:Colors.theme_FFFFFF_s,
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'row',
            },
            tool_details_size_view: {
                flexDirection:'row',
                justifyContent:'center',
                alignItems:'center',
                marginLeft:20,
                marginRight:20,
            },
            tool_details_size_slider: {
                marginLeft:40,
                width:220,
                height:80,
            },
            tool_details_move_btn:{
                width:80,
                height:80,
                alignItems:'center',
                justifyContent:'center',
            },
            tool_main_view: {
                height:60,
                backgroundColor:Colors.theme_000000_s,
                flexDirection:'row',
            },
            tool_main_button: {
                flex:1,
                alignItems:'center',
                justifyContent:'center',
            },
            tool_main_image: {
                width:20,
                height:20,
                resizeMode:'contain',
            },
        });
    }

    getListDict(){

        const {Images} = Theme;

        return {
            ButtonList : [
                {
                    tag:0,
                    defIcon:Images.tool_btn_edit_def,
                    selIcon:Images.tool_btn_edit_def,
                },
                {
                    tag:1,
                    defIcon:Images.tool_btn_size_def,
                    selIcon:Images.tool_btn_size_sel,
                },
                {
                    tag:2,
                    defIcon:Images.tool_btn_move_def,
                    selIcon:Images.tool_btn_move_sel,
                },
            ],
            MoveList : [
                {
                    data:{lastOffset:{x:-defOffset, y:0}},
                    icon:Images.tool_move_left,
                },
                {
                    data:{lastOffset:{x:defOffset, y:0}},
                    icon:Images.tool_move_right,
                },
                {
                    data:{lastOffset:{x:0, y:-defOffset}},
                    icon:Images.tool_move_up,
                },
                {
                    data:{lastOffset:{x:0, y:defOffset}},
                    icon:Images.tool_move_down,
                },
            ]
        };

    }

}



// Redux Connect
export default connect(
    (state) => ({
        language: state.language,
        theme: state.theme,
        editBoxList:state.goldBar.editBoxList,
        currBoxIndex:state.goldBar.currBoxIndex,
        currBtnIndex:state.goldBar.currBtnIndex,
    })
)(GSImageToolView)


// export default GSImageToolView;
