/**
 * GSZhiZuo
 * GSSpliceURI.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:30
 * @desc:   GSSpliceURI
 **/
'use strict';



/** 拼接Log网址参数
 *
 * uristr string URI字符串
 * params object 参数
 */
export const spliceURILogString = (uristr, params) => {


    if (isEmptyObject(params)) {
        return uristr;
    }

    // 将参数转为数组
    const paramArr = [];
    Object.keys(params).forEach((key) => {
        let value = params[key];
        if(isNull(value))value = '';
        if (!isEmptyObject(value)) value = JSON.stringify(value);
        paramArr.push([key, value].join('='));
    });

    // 拼接参数字符串
    let paramStr = paramArr.join('&');

    // 返回拼接URI
    return isEmptyString(paramStr) ? uristr : uristr + "?" + paramStr;
};


/** 拼接URI编码网址参数
 *
 * uristr string URI字符串
 * params object 参数
 */
export const spliceURIEncodeString = (uristr, params) => {

    if (isEmptyObject(params)) {
        return uristr;
    }

    // 将参数转为数组
    const paramArr = [];
    Object.keys(params).forEach((key) => {
        let value = params[key];
        if(isNull(value)){ value = ''; }
        paramArr.push([key, encodeURIComponent(value)].join('='));
    });

    // 拼接参数字符串
    let paramStr = paramArr.join('&');

    // 返回拼接URI
    return isEmptyString(paramStr) ? uristr : uristr + "?" + paramStr;
};

