/**
 * GSZhiZuo
 * GSHttpRequest.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:21
 * @desc:   GSHttpRequest
 **/
'use strict';

import {
    httpPostFilter,
    httpGetFilter,
    HttpCode,
    httpUploadFilter,
    createUploadFile,
    createUploadBase64File,
} from './GSHttpFilter'
import {httpPostManager} from "./GSHttpManager";
import {spliceURILogString} from "../Utils/GSSpliceURI";
import {nativeApplicationVersion, applicationId} from "../Utils/GSApplication";
import {Platform} from "react-native";

const URI_STRING = 'https://www.gsgoldprice.com/picmake/PicMakeAPI.ashx';
const URI_STRING_BG = 'https://www.gsgoldprice.com/picmake/Model/Model.ashx';


/** 胶箱背景制作,图片合成
 *
 * bgImageUrl String 背景图片地址
 * txtBase64Image String 文本Base64图片
 * success function 成功回调
 * failure function 失败回调
 */
export const compositeImage = (bgImageUrl, txtBase64Image, success, failure) =>
{
    if (isEmptyString(bgImageUrl) || isEmptyString(txtBase64Image)) {
        if (failure) failure(HttpCode.FAIL, {'msg': 'Parameter is null'});
        return;
    }

    const uriStr = "https://zz.szxjx.cn/image/";
    // const uriStr = "http://192.168.4.108:108";

    let params = {"bgImageUrl":bgImageUrl, "txtBase64Image":txtBase64Image};

    httpPostFilter(uriStr, params, success, failure)
};


/** 胶箱背景制作,提交信息、上传图片
 *
 * submitInfos Object 提交信息
 * images Object 图片地址
 * success function 成功回调
 * failure function 失败回调
 */
export const submitUploadBoxBackgroundInfo = (submitInfos, images, success, failure) =>
{
    if (isEmptyObject(submitInfos)) {
        if (failure) failure(HttpCode.FAIL, {'msg': 'Parameter is null'});
        return;
    }

    submitInfos["fileName"] =  submitInfos["fileName"].replace('.jpg','')

    const params = {"method":"Upload", "image1":images['image1'], "image3":images['image3'], "Data":submitInfos};
    // const params = {"method":"Upload", "image1":images['image1'], "image2":images['image2'], "image3":images['image3'], "Data":submitInfos};
    httpPostFilter(URI_STRING_BG, params, success, failure)
};


/** 获取胶箱背景数据列表
 *
 * success function 成功回调
 * failure function 失败回调
 */
export const getBoxBackgroundListData = (success, failure) => {
    const params = {"method":"GetBoxModel"};
    httpPostFilter(URI_STRING_BG, params, success, failure)
};



/** 获取品牌列表数据
 *
 * success function 成功回调
 * failure function 失败回调
 */
export const getBrandListData = (success, failure) => {
    const params = {"method":"GetBrand"};
    httpPostFilter(URI_STRING, params, success, failure)
};



/** 获取侧边栏数据
 *
 * success function 成功回调
 * failure function 失败回调
 */
export const getDrawerListData = (success, failure) => {
    const params = {"method":"GetData"};
    httpPostFilter(URI_STRING, params, (code, dataArr)=>{
        // 解析数据
        let dataSource = [];
        if (!isEmptyObject(dataArr)) {
            let sectionId = -1;
            dataArr.map((obj1, index) => {
                obj1.list.map((obj2, index) => {
                    sectionId++;
                    obj2.sectionId = sectionId;
                    if (!isEmptyObject(obj2["TemplateList"])) {
                        obj2["data"] = obj2["TemplateList"];
                    } else {
                        obj2["data"]=[];
                    }
                    dataSource.push(obj2);
                });
            });
        }
        // 返回数据
        if (success) success(code, dataSource);
    }, failure)
};


/** 获取胶箱列表数据
 *
 * success function 成功回调
 * failure function 失败回调
 */
export const getBoxListData = (success, failure) => {
    const params = {"method":"GetBoxModel", "Data":{"Model":""}};
    httpPostFilter(URI_STRING, params, success, failure)
};


/** 获取进度数据
 *
 * success function 成功回调
 * failure function 失败回调
 */
export const getProgressListData = (success, failure) => {
    const params = {"method":"GetQuery", "Data":{"Status":0}};
    httpPostFilter(URI_STRING, params, success, failure)
};


/** 胶箱制作,提交信息、上传图片
 *
 * submitInfos Object 提交信息
 * images Object 图片地址
 * success function 成功回调
 * failure function 失败回调
 */
export const submitUploadInfo = (submitInfos, images, success, failure) =>
{
    if (isEmptyObject(submitInfos)) {
        if (failure) failure(HttpCode.FAIL, {'msg': 'Parameter is null'});
        return;
    }
    const params = {"method":"Upload", "image1":images['image1'], "image2":images['image2'], "Data":submitInfos};
    httpPostFilter(URI_STRING, params, success, failure)
};


/** 获取模板列表
 *
 * success function 成功回调
 * failure function 失败回调
 */
export const getTemplateList = (success, failure) => {
    const params = {"method":"GetSample"};
    httpPostFilter(URI_STRING, params, success, failure)
};

/** 获取特殊字符列表
 *
 * success function 成功回调
 * failure function 失败回调
 */
export const getSpecialCharList = (success, failure) => {
    const params = {"method":"GetSimple"};
    httpPostFilter(URI_STRING, params, success, failure)
};


/** 获取版本检测
 *
 * success function 成功回调
 * failure function 失败回调
 */
export const getCheckVersion = (success, failure) => {

    // 获取提交信息
    const dataInfo = {
        "Ver":nativeApplicationVersion, // '1.3.5', //
        "PackageName":applicationId,
        "Platform":Platform.OS,
    };

    // 提交版本检测
    const params = {"method":"CheckVersion", "Data":dataInfo};
    const uristr = 'https://238server.gsgoldprice.com/Utility/Handler.ashx';

    httpPostFilter(uristr, params, (code, data)=>{
        log(data);
        const tips = data['Tips'];
        const downUrl = data['DownUrl'];
        const updateType = parseInt(data['UpdateType']);
        if (success) success(tips, updateType, downUrl);
    }, failure);
};
