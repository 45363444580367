/**
 * GSZhiZuo
 * GSBoxBgEditPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/26
 * @desc:   GSBoxBgEditPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
    Animated, PixelRatio,
} from 'react-native';
import { Icon } from '@rneui/themed';
import {captureViewToBase64Async} from "../../Common/Utils/GSCaptureImage";
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import GSDrawSignView from '../PlasticBox/View/GSDrawSignView';
import GSEditTextView from './View/GSEditTextView';
import BoxToolView from './View/GSBoxToolView'
import {isDesktopBrowser} from "../../Common/Utils/GSDevice";

import {PanGestureHandler, PinchGestureHandler, RotationGestureHandler, TapGestureHandler, State} from 'react-native-gesture-handler';
import {
    plasticBoxBgDeselectEditButtonAction,
    updatedSpecialCharAction,
    plasticBoxBgUpdatedEditButtonAction,
    plasticBoxBgAddEditButtonAction,
    plasticBoxBgClearEditBoxListAction,
    AnimatedDict,
} from "../../Common/Redux/GSReduxAction";
import {TextModel} from "../../Common/Utils/GSDefined";
import {compositeImage} from "../../Common/Http/GSHttpRequest";

class GSBoxBgEditPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.calculateSize(props.route.params?.boxData);

        this.signEmpty = true;
        this.state = {
            isEdit:true,
        };

        this.updatedSpecialCharList();

        this.initNavi();

    }

    initNavi = () => {

        // 设置返回按钮
        this.setBackTitle(T("重选"));
        this.setBackPress(()=>{
            // 保存上次数据
            this.saveLastListData();
            // 清除数据
            plasticBoxBgClearEditBoxListAction();
            // 返回
            this.goBack();

        });

        // 设置导航栏右边按钮
        this.props.navigation.setOptions({
            headerRight: (props) => {
                return (
                    <TouchableOpacity style={{flexDirection:"row", alignItems:"center", padding:8}} onPress={()=>{this.onRightButtonPress()}} >
                        <Text style={{fontSize:17}}>{T("下一步")}</Text>
                    </TouchableOpacity>
                );
            },
        });
    };


    // 计算数据
    calculateSize = (boxData) => {

        let scale = boxData['Length'] / boxData['Height'];
        this.editWidth = screenWidth-20;
        this.editHeight = screenWidth-20;
        if (scale > 1) {
            this.editHeight = this.editWidth/scale;
        } else if(scale < 1) {
            this.editWidth = this.editHeight*scale;
        }

        this.magniScale = boxData["WidthPX"]/PixelRatio.getPixelSizeForLayoutSize(this.editWidth);

        // console.log("====>PixelRatio.get() ", PixelRatio.get())
        // console.log("====>this.magniScale ", this.magniScale )
        // console.log("====>this.editWidth ", this.editWidth )
        // console.log("====>boxData[WidthPX] ", boxData["WidthPX"] )
        // console.log("====>getPixelSizeForLayoutSize ", PixelRatio.getPixelSizeForLayoutSize(this.editWidth))
    };

    // 更新特殊字符列表
    updatedSpecialCharList = () => {
        // storageRemove(StorageKey.SPECIAL_CHAR_LIST);
        storageLoad(StorageKey.SPECIAL_CHAR_LIST, (code, specialCharList) => {
            if (code === 1) {
                // 获取成功
                updatedSpecialCharAction(specialCharList);
            } else {
                // 获取失败
                showAlert(T("获取网络数据失败，是否需要重试?"),"",[T("否"), T("是")], (index, title)=>{
                    if (index === 1) {
                        // 重新获取数据
                        this.updatedSpecialCharList();
                    }
                });
            }
        });
    };


    // 导航栏右侧按钮点击
    onRightButtonPress = () => {
        // 取消选中状态
        plasticBoxBgDeselectEditButtonAction();

        this.setState({
            isEdit:false,
        })

        // 判断编辑是否为空
        const editButtonList = this.props.editButtonList;
        if (isEmptyObject(editButtonList)){
            showToast(T("请输入编辑文本"));
            return;
        }

        // 判断签名是否为空
        if (this.signEmpty) {
            showToast(T("请输入签名"));
            return;
        }

        // 保存上次数据
        this.saveLastListData();

        // 拼接数据
        const boxData = this.props.route.params?.boxData || {};
        const boxmodel = boxData['SizeName'];
        let boxWord = {"boxmodel":boxmodel};
        editButtonList.map((textObj, index)=>{
            boxWord['word'+(index+1)] = textObj.textValue;
        });

        showLoading();
        setTimeout(()=>{
            // 截取图片
            this.captureImages().then((images)=>{
                this.setState({
                    isEdit:true,
                })

                // closeLoading();
                // // 跳转到用户信息页面
                // this.push(SceneKey.BoxBgPreview, {
                //     images:images,
                //     boxWord:boxWord,
                //     boxData:boxData,
                // });

                // 合并图片
                const ImageUrl = this.props.route.params?.boxData['ImageUrl'];
                compositeImage(ImageUrl, images["image1"],(code, data)=>{
                    closeLoading();
                    // log(code, data);
                    const imageUrl = data["imageUrl"];
                    // 跳转到用户信息页面
                    this.push(SceneKey.BoxBgPreview, {
                        images:{"image1":imageUrl},
                        boxWord:boxWord,
                        boxData:boxData,
                    });

                },(code, error)=>{
                    closeLoading();
                    log(code, error);
                    showToast(T("保存图片失败"));
                });

            }).catch((error)=>{
                closeLoading();
                // 截图失败
                showAlert(T("保存图片失败"),error.message,  [T("确定")], ()=>{ });
            });
        },200);

    };

    // 截取图片
    captureImages = async () => {
        const base64Image1 = await captureViewToBase64Async(this.editView, {
            format: "png",
            quality: 1,
            result:'data-uri'
        });
        // const base64Image2 = await captureViewToBase64Async(this.screenView, null);
        return {
            'image1':base64Image1,
            // 'image2':base64Image2,
        };
    };



    // // 点击标题
    // titleOnPress = () => {
    //     // 保存上次数据
    //     const editButtonList = this.props.editButtonList;
    //     if (!isEmptyObject(editButtonList)){
    //         this.saveLastListData();
    //         showToast(T("暂存成功"));
    //     }
    // };


    // 保存上次数据
    saveLastListData = () =>{
        const editButtonList = this.props.editButtonList;
        if (isEmptyObject(editButtonList)){
            return;
        }

        let saveList = [...editButtonList];
        storageSave(StorageKey.BOX_BG_LAST_DATA, saveList);
    };


    // 签名改变回调
    onSignPathChange = (drawPath) => {
        this.signEmpty = isEmptyString(drawPath);
    };

    // 点击编辑
    onEditPress = () =>{

        // 取消选中
        plasticBoxBgDeselectEditButtonAction();

        this.setState({
            isEdit:!this.state["isEdit"],
        })
    }

    /*===================手势监听Start====================*/

    // 旋转状态改变
    onRotateHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onRotateHandlerStateChange:',event.nativeEvent);
            if(this.props.currIndex < 0){
                return;
            }
            let currEditBtn = {...this.props.editButtonList[this.props.currIndex]};
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            currEditBtn.lastRotate += event.nativeEvent.rotation;
            currEditBtnAnimated.rotate.setOffset(currEditBtn.lastRotate);
            currEditBtnAnimated.rotate.setValue(0);

            plasticBoxBgUpdatedEditButtonAction(currEditBtn);
        }
    };


    // 捏合状态改变
    onPinchHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onPinchHandlerStateChange:',event.nativeEvent);
            if(this.props.currIndex < 0){
                return;
            }
            let currEditBtn = {...this.props.editButtonList[this.props.currIndex]};
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            let sizeScale = currEditBtn.sizeScale;
            sizeScale *= event.nativeEvent.scale;
            currEditBtn.sizeScale = sizeScale;
            currEditBtnAnimated.baseScale.setValue(sizeScale);
            currEditBtnAnimated.pinchScale.setValue(1);

            plasticBoxBgUpdatedEditButtonAction(currEditBtn);
        }
    };

    // 平移状态改变
    onPanHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onPanHandlerStateChange:',event.nativeEvent);
            if(this.props.currIndex < 0){
                return;
            }
            let currEditBtn = {...this.props.editButtonList[this.props.currIndex]};
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            let lastOffsetX = currEditBtn.lastOffset.x + event.nativeEvent.translationX;
            let lastOffsetY = currEditBtn.lastOffset.y + event.nativeEvent.translationY;

            currEditBtn.lastOffset = {x:lastOffsetX, y:lastOffsetY};
            currEditBtnAnimated.offsetValue.setOffset({x:lastOffsetX, y:lastOffsetY});
            currEditBtnAnimated.offsetValue.setValue({x:0,y:0});

            plasticBoxBgUpdatedEditButtonAction(currEditBtn);
        }
    };

    // 点击内容面板
    onContentTapHandlerStateChange = (event) => {

        if (!this.state["isEdit"]) {
            return;
        }

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onContentTapHandlerStateChange:',event.nativeEvent);
            if(this.props.currIndex < 0){
                // 判断双击
                if (isDoublePress(2, 1000)) {
                    // 添加文本
                    this.push(SceneKey.BoxBgAddText);
                    // let test1= cloneDeep(TextModel);
                    // test1.textValue = '测试文本1';
                    // plasticBoxBgAddEditButtonAction(test1);
                }
            } else {
                // 取消选中
                plasticBoxBgDeselectEditButtonAction();
            }
        }
    };

    /*===================手势监听End====================*/

    render() {

        const {isEdit} = this.state;
        const {currIndex, editButtonList} = this.props || {};
        const picPath = this.props.route.params?.boxData['ImageUrl'];

        let emptyEvent = Animated.event([{ nativeEvent: { } }],{useNativeDriver: false});
        let deselect = currIndex < 0;

        let currEditBtn = deselect ? undefined : editButtonList[currIndex];
        let currEditBtnAnimated = deselect ? undefined : AnimatedDict[currEditBtn.animatedId];

        const {Colors} = Theme;
        const styles = this.getStyles();

        const switchSel = {backgroundColor:Colors.theme_first_s, color:Colors.theme_000000_s};
        const switchDeSel = {backgroundColor:Colors.theme_FFFFFF, color:Colors.theme_000000}

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View ref={(ref)=>{this.screenView = ref}} style={{flex: 1, backgroundColor:Colors.theme_FFFFFF}}>
                            <View style={styles.edit_comp_view}>
                                <TapGestureHandler onHandlerStateChange={this.onContentTapHandlerStateChange}>
                                    <PanGestureHandler
                                        onGestureEvent={ deselect ? emptyEvent : Animated.event([{ nativeEvent: {translationX: currEditBtnAnimated.offsetValue.x, translationY: currEditBtnAnimated.offsetValue.y}}],{useNativeDriver: false})}
                                        onHandlerStateChange={deselect ? ()=>{} : this.onPanHandlerStateChange}
                                        minDist={20}
                                    >
                                        <Animated.View style={styles.edit_comp_view}>
                                            <RotationGestureHandler
                                                // onGestureEvent={deselect ? emptyEvent :Animated.event([{ nativeEvent: {rotation: currEditBtnAnimated.rotate} }],{useNativeDriver: false})}
                                                // onHandlerStateChange={deselect ? ()=>{} : this.onRotateHandlerStateChange}
                                            >
                                                <Animated.View style={styles.edit_comp_view}>
                                                    <PinchGestureHandler
                                                        onGestureEvent={deselect ? emptyEvent :Animated.event([{ nativeEvent: {scale: currEditBtnAnimated.pinchScale}}],{useNativeDriver: false})}
                                                        onHandlerStateChange={deselect ? ()=>{} : this.onPinchHandlerStateChange}
                                                    >
                                                        <Animated.View style={styles.edit_comp_view}>
                                                            <View style={{position:'absolute', top:0, left:0, overflow:"hidden",}}>
                                                                <View  ref={(ref)=>{this.editView = ref}}  style={{position: 'absolute',backgroundColor:"#00000000"}}>
                                                                    {isEdit === false && <GSEditTextView isCapture={true} magniScale={this.magniScale} isEdit={isEdit} picPath={picPath} editWidth={this.editWidth*this.magniScale} editHeight={this.editHeight*this.magniScale} />}
                                                                </View>
                                                            </View>
                                                            <View style={{backgroundColor:Colors.theme_FFFFFF_s, width: this.editWidth, height:this.editHeight}}>
                                                                <GSEditTextView  isCapture={false}  magniScale={1} isEdit={isEdit}  picPath={picPath} editWidth={this.editWidth} editHeight={this.editHeight} navigation={this.props.navigation}  />
                                                            </View>
                                                        </Animated.View>
                                                    </PinchGestureHandler>
                                                </Animated.View>
                                            </RotationGestureHandler>
                                        </Animated.View>
                                    </PanGestureHandler>
                                </TapGestureHandler>
                            </View>
                            <View style={styles.draw_sign_view}>
                                <GSDrawSignView onPathChange={this.onSignPathChange} />
                            </View>
                        </View>

                        <View style={styles.eidt_comp_switch_view}>
                            <TouchableOpacity activeOpacity={0.8} onPress={()=>this.onEditPress()} style={styles.eidt_comp_switch_btn}>
                                <Text style={[styles.eidt_comp_switch_btn_txt, isEdit?switchSel:switchDeSel]}>{T("编辑")}</Text>
                                <Text style={[styles.eidt_comp_switch_btn_txt, isEdit?switchDeSel:switchSel]}>{T("原图")}</Text>
                            </TouchableOpacity>
                        </View>

                        <BoxToolView navigation={this.props.navigation} />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            edit_comp_view:{
                flex: 1,
                justifyContent:'center',
                alignItems:'center',
                backgroundColor: Colors.theme_FFFFFF,
            },
            edit_comp_btn:{
                position:'absolute',
                top:0,
                left:0,
                right:0,
                bottom:0
            },
            eidt_comp_switch_view:{
                position: "absolute",
                right:0,
                left:0,
                top: 8,
                alignItems:"center",
            },
            eidt_comp_switch_btn:{
                flexDirection: "row",
                borderWidth:1,
                borderColor:Colors.theme_first_s,
                borderRadius:2,
            },
            eidt_comp_switch_btn_txt:{
                fontSize:16,
                padding:2,
            },
            draw_sign_view:{
                width: screenWidth,
                height:180,
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
            currIndex:state.plasticBoxBg.currIndex,
            editButtonList:state.plasticBoxBg.editButtonList,
        })
    }
)(GSBoxBgEditPage)


// export default GSBoxBgEditPage;
