/**
 * GSZhiZuo
 * GSDrawSignView.js
 *
 * @author: zengzhiming
 * @date:   2023/6/19
 * @desc:   GSDrawSignView
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    PanResponder,
    Image,
    ScrollView,
} from 'react-native';
import Svg, {Path} from "react-native-svg";
import BasePage from "../../../Common/Component/BaseComponent/GSBasePage";


const strokeWidth = 3;
const titleHeight = 30;
const compHeight = 180;

class GSDrawSignView extends BasePage {


    // 构造函数
    constructor(props, context) {
        super(props, context);

        // currPath: 'M25 10 L98 65 L70 25 L16 77 L11 30 L0 4 L90 50 L50 10 L11 22 L77 95 L20 25'
        this.pathArr = []
        this.currPath = ''
        this.state = {
            drawPath: '',
            showHint:true,
        }
        this.panResponder = this.createPanResponder();
    }


    // 创建手势
    createPanResponder = () => {
        return PanResponder.create({
            // 要求成为响应者：
            onStartShouldSetPanResponder: (evt, gestureState) => true,
            onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
            onMoveShouldSetPanResponder: (evt, gestureState) => true,
            onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,

            onPanResponderGrant: (evt, gestureState) => {
                //开始手势操作。 上一次的画的全部的点(this.allPoint)，拼接上当前这次画的M点，在svg中M为线的起始点,拼接上后在 onPanResponderMove 中将当前移动的所有点再次拼接,当前和之前的拼接完之后，更新页面线条
                let firstX = evt.nativeEvent.locationX
                let firstY = evt.nativeEvent.locationY
                this.firstPoint = ' M' + firstX + ' ' + firstY
                this.currPath = this.firstPoint
                this.updatePath();
            },
            onPanResponderMove: (evt, gestureState) => {
                // 最近一次的移动距离为gestureState.move{X,Y}
                // 从成为响应者开始时的累计手势移动距离为gestureState.d{x,y}
                let pointX = evt.nativeEvent.locationX
                let pointY = evt.nativeEvent.locationY
                let point = ' L' + pointX + ' ' + pointY
                this.currPath += point
                this.updatePath();
            },
            onPanResponderRelease: (evt, gestureState) => {
                // 用户放开了所有的触摸点，且此时视图已经成为了响应者。
                // 一般来说这意味着一个手势操作已经成功完成。
                this.pathArr.push(this.currPath);
                this.currPath = "";
                this.updatePath();

            },
            onPanResponderTerminate: (evt, gestureState) => {
                // 另一个组件已经成为了新的响应者，所以当前手势将被取消。
                this.pathArr.push(this.currPath);
                this.currPath = "";
                this.updatePath();
            },
            onShouldBlockNativeResponder: (evt, gestureState) => {
                // 返回一个布尔值，决定当前组件是否应该阻止原生组件成为JS响应者
                // 默认返回true。目前暂时只支持android。
                return true;
            },
            onPanResponderTerminationRequest: (evt, gestureState) => {
                return true;
            },
        });
    };


    // 更新Path
    updatePath = () => {
        let drawPath = this.pathArr.join("")+this.currPath;
        this.setState({
            drawPath: drawPath,
            showHint:isEmptyString(drawPath),
        });
        // 回调路径改变
        this.props.onPathChange&&this.props.onPathChange(drawPath);
    };


    // 点击回退按钮
    onBackDrawPress = () => {
        this.currPath = "";
        this.pathArr.pop();
        this.updatePath();
    };

    render() {

        const {drawPath, showHint} = this.state;

        const {Images, Colors} = Theme;
        const styles = this.getStyles();

        const strokeColor = Colors.theme_000000;

        return (
            <ScrollView scrollEnabled={false}>
                <View style={{width: screenWidth, height:compHeight}}>
                    <View style={styles.sign_comp_view} >
                        <Text style={styles.sign_comp_title} numberOfLines={1}>{T("顾客已确定刻字内容，明白字体及位置可能会有偏差，签名作实:")}</Text>
                    </View>
                    <View style={styles.sign_content_view}   >
                        <View style={{width:"100%", height:"100%"}}  {...this.panResponder.panHandlers}>
                            <Svg height="100%" width="100%">
                                <Path
                                    d={drawPath}
                                    fill={"none"}
                                    stroke={strokeColor}
                                    strokeWidth={strokeWidth}
                                />
                            </Svg>
                        </View>
                        <TouchableOpacity  style={styles.sign_content_back_btn} onPress={()=>this.onBackDrawPress()}>
                            <Image style={styles.sign_content_back_image} source={Images.draw_sign_back}/>
                        </TouchableOpacity>
                        {/*pointerEvents='none' 不捕捉任何事件 */}
                        {showHint && <View pointerEvents='none' style={styles.sign_content_hint_view}><Text style={styles.sign_content_hint_text}>{T("请在此处签名")}</Text></View>}
                    </View>
                </View>
            </ScrollView>
        );
    }

    getStyles() {
        const {Colors} = Theme;
        return StyleSheet.create({
            sign_comp_view:{
                height:titleHeight,
                paddingLeft: 16,
                backgroundColor: Colors.box_edit_sign_EDEDED,
                justifyContent:'center',
            },
            sign_comp_title:{
                fontSize:10,
                color: Colors.box_edit_sign_282817,
            },
            sign_content_view:{
                justifyContent:'center',
                alignItems:'center',
                flex:1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            sign_content_back_btn:{
                width:40,
                height:40,
                position: 'absolute',
                right:6,
                top:6,
                justifyContent:'center',
                alignItems:'center',
            },
            sign_content_back_image:{
                width:20,
                height:20,
                resizeMode:'contain',
            },
            sign_content_hint_view:{
                position: 'absolute'
            },
            sign_content_hint_text:{
                fontSize:18,
                color: Colors.box_edit_sign_282817,
            }
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSDrawSignView)


// export default GSDrawSignView;