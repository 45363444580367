/**
 * GSZhiZuo
 * GSBoxUserInfoPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/19
 * @desc:   GSBoxUserInfoPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TextInput,
    TouchableOpacity,
    Image,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import {saveBase64Image} from "../../Common/Utils/GSMediaLibrary";
import {submitUploadInfo} from "../../Common/Http/GSHttpRequest";


class GSBoxUserInfoPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);


        this.state = {
            data:[],
        };

        this.headerRightButton();
    }

    headerRightButton = () => {
        this.props.navigation.setOptions({
            headerRight: (props) => {
                return (
                    <TouchableOpacity style={{flexDirection:"row", alignItems:"center", padding:8}} onPress={()=>{this.onSubmitPress()}} >
                        <Text style={{fontSize:17}}>{T("提交")}</Text>
                    </TouchableOpacity>
                );
            },
        });
    };

    componentDidMount() {
        storageLoad(StorageKey.BOX_USER_INFO, (code, data)=>{
            if (code === 1) {
                this.setState({
                    data:data
                });
            }
        });
    }


    // 点击提交按钮
    onSubmitPress = () => {
        // 取消焦点
        this.blur.focus();
        this.blur.blur();

        const InfoList = this.getInfoList();

        // 获取用户信息
        let dataDict = {};
        const dataList = this.state.data;
        for (let i = 0; i < InfoList.length; i++){
            const key = InfoList[i]["key"];
            let data = dataList[i];
            if (isEmptyString(data)) {
                if (key !== 'remark' )  {
                    showToast(T("请完善用户信息"));
                    return;
                }
                data = "";
            }
            dataDict[key] = data;
        }

        // 获取编辑内容
        const {images, boxWord} = this.props.route.params || {};

        // 保存图片到相册
        saveBase64Image(images['image1']);

        // this.navigate(SceneKey.BoxList);
        // return;

        // 合并提交数据
        const submitInfos = Object.assign({}, boxWord, dataDict);
        showLoading(T("正在提交数据..."));
        submitUploadInfo(submitInfos, images, (code, data)=>{
            closeLoading();
            // 保存用户信息
            // storageSave(StorageKey.BOX_USER_INFO, dataList);
            this.navigate(SceneKey.BoxList);
            showToast(T("提交成功"));
        },(code, error)=>{
            closeLoading();
            log(code, error);
            showToast(T("提交失败"));
        });
    };

    render() {

        const {data} = this.state||{};
        const InfoList = this.getInfoList();
        const styles = this.getStyles();
        const {Colors} = Theme;

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        {
                            InfoList.map((info, index)=> {
                               return index !== InfoList.length-1 ?
                                    (
                                        <View key={index} style={styles.list_item_view}>
                                            <Text style={styles.list_item_title}>{info.title}</Text>
                                            <View style={styles.list_item_input_view}>
                                                <TextInput style={styles.list_item_input}
                                                           placeholder={info.placeholder}
                                                           placeholderTextColor={Colors.box_userinfo_888888_s}
                                                           value={data[index]||""}
                                                    // autoFocus={index===0}
                                                           ref={(ref)=>this.blur=ref}
                                                           keyboardType={info.keyboardType}
                                                           onChangeText={(text) => {
                                                               if (index === 2)  {
                                                                   text = text.toUpperCase();
                                                               } else if (index === 1) {
                                                                   text = text.replace(/[^0-9]/ig,"");
                                                               }
                                                               data[index]=text;
                                                               this.setState(data)
                                                           }}
                                                />
                                            </View>
                                        </View>
                                    ) : (
                                        <View key={index} style={[styles.list_item_view,{alignItems:'flex-start',paddingTop:10}]}>
                                            <Text style={[styles.list_item_title]}>{info.title}</Text>
                                            <View style={[styles.list_item_input_view,{height: 200}]}>
                                                <TextInput style={styles.list_item_input}
                                                           multiline={true}
                                                           placeholder={info.placeholder}
                                                           placeholderTextColor={Colors.box_userinfo_888888_s}
                                                           value={data[index]||""}
                                                    // autoFocus={index===0}
                                                           ref={(ref)=>this.blur=ref}
                                                           keyboardType={info.keyboardType}
                                                           onChangeText={(text) => {
                                                               if (index === 2)  {
                                                                   text = text.toUpperCase();
                                                               } else if (index === 1) {
                                                                   text = text.replace(/[^0-9]/ig,"");
                                                               }
                                                               data[index]=text;
                                                               this.setState(data)
                                                           }}
                                                />
                                            </View>
                                        </View>
                                    )

                            })
                        }
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getInfoList() {
        return [
            {
                key:'contactuser',
                title:T("联系人"),
                placeholder:T("请输入联系人"),
                keyboardType:'default',
            },
            {
                key:'contacttel',
                title:T("联系电话"),
                placeholder:T("请输入联系电话"),
                keyboardType:'phone-pad',
            },
            {
                key:'contactbranch',
                title:T("联系分行"),
                placeholder:T("请输入联系分行"),
                keyboardType:'email-address',
            },
            {
                key:'remark',
                title:T("备注"),
                placeholder:T("请输入备注"),
                keyboardType:'default',
            },
        ]
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,

            },
            list_item_view:{
                flexDirection:'row',
                alignItems:'center',
                marginLeft:16,
                marginRight:16,
                borderBottomColor:Colors.box_userinfo_D7D7D7,
                borderBottomWidth: 1,
            },
            list_item_title:{
                fontSize: 17,
                width: 90,
                color:Colors.theme_000000,
            },
            list_item_input_view:{
                height:44,
                flex: 1,
                overflow:"hidden",
            },
            list_item_input:{
                flex: 1,
                fontSize: 17,
                padding:1,
                margin:-1,
                color:Colors.theme_000000,
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSBoxUserInfoPage)


// export default GSBoxUserInfoPage;
