/**
 * GSZhiZuo
 * GSBoxListPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/19
 * @desc:   GSBoxListPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    Image,
    TouchableOpacity,
    FlatList,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";

const ITEM_HEIGHT = 44.0;

class GSBoxListPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            boxList:[],
            isShowExplain:false,
        };

        this.headerRightButton();
    }

    componentDidMount() {
        // 获取侧边栏数据
        this.getBoxListData();
    }

    headerRightButton = () => {
        const {Images} = Theme;
        this.props.navigation.setOptions({
            headerRight: (props) => {
                return (
                    <TouchableOpacity onPress={()=>{this.setState({isShowExplain:true})}} >
                        <Image style={{width:18, height:18, margin:8}} source={Images.box_nav_explain} />
                    </TouchableOpacity>
                );
            },
        });
    };

    // 获取侧边栏数据
    getBoxListData = () =>{
        showLoading();
        storageLoad(StorageKey.BOX_LIST, (code, boxList) => {
            closeLoading();
            if (code === 1) {
                // 获取成功
                this.setState({
                    boxList:boxList,
                });
            } else {
                // 获取失败
                showAlert(T("获取网络数据失败，是否需要重试?"),"",[T("否"), T("是")], (index, title)=>{
                    if (index === 1) {
                        // 重新获取数据
                        this.getBoxListData();
                    }
                });
            }
        });
    };


    // 点击Item
    onItemPress = (boxData, index) => {
        // 处理连续点击
        if (isDoublePress()) return;
        this.push(SceneKey.BoxEdit, {boxData:boxData});
    };

    render() {

        const {boxList, isShowExplain} = this.state || [];

        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View style={styles.list_header_view}>
                            <Text style={styles.list_header_text_left}>{T("型号")}</Text>
                            <Text style={[styles.list_header_text_right,{left:screenWidth*0.35}]}>{T("阔*高*深 (单位:mm)")}</Text>
                        </View>
                        <FlatList renderItem={({item, index}) => this.listItem(item, index)}
                                  data={boxList}
                                  keyExtractor={(item, index) => index+''}
                                  numColumns={1}
                                  showsVerticalScrollIndicator = {false}
                                  initialNumToRender={Math.ceil(screenHeight/ITEM_HEIGHT)}
                                // getItemLayout={(data, index) => (
                                //     {length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index}
                                // )}
                        />
                        {isShowExplain && this.makeExplain()}
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    listItem = (data, index)=>{

        const styles = this.getStyles();
        return (
            <TouchableOpacity style={styles.item} onPress={()=>this.onItemPress(data, index)}>
                <Text style={styles.item_text_left}>{data['ModelName']}</Text>
                <Text style={[styles.item_text_right, {left:screenWidth*0.35}]}>{data['Length']} x {data['Height']} x {data['Width']}</Text>
            </TouchableOpacity>
        );
    }

    makeExplain = () => {
        const styles = this.getStyles();
        const {Images} = Theme;
        const top = (screenHeight-425-50-statusNavBarHeight)/2;
        return (
            <View style={styles.model_explain_view}>
                <View style={{alignItems: 'center',marginTop:top}}>
                    <Image style={{width:335,height:425}} source={Images.model_explain_image} />
                    <TouchableOpacity style={{marginTop:20}} onPress={()=>{this.setState({isShowExplain:false})}}>
                        <Image style={{width:30,height:30}} source={Images.model_explain_close} />
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            list_header_view:{
                height:30,
                flexDirection: 'row',
                backgroundColor:Colors.box_list_FFF8DA,
                alignItems: 'center',
            },
            list_header_text_left:{
                fontSize:13,
                color:Colors.box_list_80807A,
                marginLeft: 16,
            },
            list_header_text_right:{
                fontSize:13,
                color:Colors.box_list_80807A,
                position: 'absolute',

            },
            item:{
                height:44,
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor:Colors.theme_FFFFFF,
                borderBottomWidth: 0.5,
                borderBottomColor:Colors.box_list_D7D7D7,
            },
            item_text_left:{
                fontSize:17,
                marginLeft: 16,
                color:Colors.theme_000000,
            },
            item_text_right:{
                fontSize:17,
                position: 'absolute',
                color:Colors.theme_000000,
            },
            model_explain_view: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                position: 'absolute',
                alignItems: 'center',
                // justifyContent: 'center',
                backgroundColor:Colors.box_list_00000055_s,
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSBoxListPage)


// export default GSBoxListPage;