/**
 * GSZhiZuo
 * GSShare.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:26
 * @desc:   GSShare
 **/
'use strict';

import {isEmptyString } from "../Utils/GSCheck";

let ShareData = {};

// 共享数据Key
// LANGUAGE：繁体 T 简体 S
export const ShareKey = {
    LANGUAGE:     'Language',
    THEME:       'theme',
    TEST_SHARE_DATA:        'TestShareData',
    BRAND_INFO:     'BrandInfo',
};

// 获取共享数据
export const getShare = (key) => {
    if (isEmptyString(key)) {
        return undefined;
    }
    return ShareData[key];
};

// 添加共享数据
export const addShare = (key, data) => {
    if (isEmptyString(key)) {
        return;
    }
    ShareData[key] = data;
};

// 删除共享数据
export const delShare = (key) => {
    if (isEmptyString(key)) {
        return;
    }
    delete ShareData[key];
};

