/**
 * GSZhiZuo
 * GSWeChatShare.js
 *
 * @author: zengzhiming
 * @date:   2021/10/21 09:37
 * @desc:   GSWeChatShare
 **/
'use strict';


import * as WeChat from "expo-react-native-wechat";

const appId = 'wx54d90c03e383b854';
const universalLink = 'https://zz.szxjx.cn/';

// 注册APP
export const registerApp = () => {
    return WeChat.registerApp(appId, universalLink);
};


// 检测是否安装微信
export const isWXAppInstalled = () => {
    return WeChat.isWXAppInstalled();
};

// 检查微信是否支持打开网址
export const isWXAppSupportApi = () => {
    return WeChat.isWXAppSupportApi();
};

// 获取微信SDK api版本
export const getApiVersion = () => {
    return WeChat.getApiVersion();
};

// 打开微信应用程序
export const openWXApp = () => {
    return WeChat.openWXApp();
};

// 发送身份验证请求
export const sendAuthRequest = (scope,state) => {
    return WeChat.sendAuthRequest(scope,state);
};

// 分享文本
export const shareText = (data) => {
    return WeChat.shareText(data);
};

// 分享文本
export const shareImage = (data) => {
    return WeChat.shareImage(data);
};

// 分享文本
export const shareLocalImage = (data) => {
    return WeChat.shareLocalImage(data);
};


