/**
 * GSZhiZuo
 * GSToast.js
 *
 * @author: zengzhiming
 * @date:   2022/8/29 10:51
 * @desc:   GSToast
 **/
'use strict';

import 'setimmediate';
import Toast from 'react-native-root-toast';

export const showToast = (msg) => {

    if (isEmptyString(msg)) {
        return;
    }

    Toast.show(msg,{
        duration: Toast.durations.SHORT,  // toast显示时长
        position: Toast.positions.CENTER, // toast显示位置
    });
};

