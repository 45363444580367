/**
 * GSZhiZuo
 * GSMediaLibrary.js
 *
 * @author: zengzhiming
 * @date:   2023/6/15
 * @desc:   GSMediaLibrary
 **/
'use strict';

import {Platform} from  'react-native';
import * as MediaLibrary from 'expo-media-library';
import * as FileSystem from 'expo-file-system';


export const saveWebImage = (imgUrl, imgName, backBlock ) => {
    saveWebImageAsync(imgUrl, imgName).then((result)=>{
        if (backBlock) backBlock(result["code"], result["msg"], result["data"]);
    })
}


export const saveBase64Image = (imgBase64, imgName, backBlock ) => {
    saveBase64ImageAsync(imgBase64, imgName).then((result)=>{
        if (backBlock) backBlock(result["code"], result["msg"], result["data"]);
    })
}


export const saveLocalUriImage = (fileUri, backBlock ) => {
    saveLocalUriImageAsync(fileUri).then((result)=>{
        if (backBlock) backBlock(result["code"], result["msg"], result["data"]);
    })
}

export const saveWebImageAsync = async (imgUrl, imgName) => {

    let result = {code: -1, msg: "未知错误", data: []};

    if (imgUrl == null || imgUrl == "") {
        result["msg"] = "参数为空";
        return result;
    }

    // 截取文件名字
    if (imgName == null || imgName == "") {
        imgName =  imgUrl.split("/").pop();
    }

    // 下载网络图片
    let fileUri = "";
    if (Platform.OS !== 'web') {
        fileUri = FileSystem.documentDirectory + imgName;
        const downloadResumable = FileSystem.createDownloadResumable(imgUrl,fileUri);
        const downFile = await downloadResumable.downloadAsync();
        if (downFile == null || downFile["uri"] == null) {
            result["msg"] = "下载图片失败";
            return result;
        }
    } else {
        fileUri = await fetch(imgUrl).then(response => response.blob()).then(blob => URL.createObjectURL(blob));
        if (fileUri == null) {
            result["msg"] = "下载图片失败";
            return result;
        }
    }

    // 保存临时图片到相册
    result = await saveLocalUriImageAsync(fileUri, imgName);

    return result;
};


export const saveBase64ImageAsync = async (imgBase64, imgName) => {

    let result = {code: -1, msg: "未知错误", data: []};

    if (imgBase64 == null || imgBase64 == "") {
        result["msg"] = "参数为空";
        return result;
    }

    // 截取文件名字
    if (imgName == null || imgName == "") {
        let ext = imgBase64.match(/data:(\S*);base64/)[1].split("/").pop();
        const timestamp = new Date().getTime();
        imgName =  timestamp + "." + ext;
    }

    let fileUri = imgBase64;
    if (Platform.OS !== 'web') {
        // 下载Base64图片
        fileUri = FileSystem.documentDirectory + imgName;
        const base64Data = imgBase64.replace(/data:image\/(\w+);base64,/, '');
        await FileSystem.writeAsStringAsync(fileUri, base64Data, {
            encoding: FileSystem.EncodingType.Base64,
        });
    }

    // 保存临时图片到相册
    result = await saveLocalUriImageAsync(fileUri, imgName);

    return result;
};


export const saveLocalUriImageAsync = async (fileUri, imgName) => {

    let result = {code: -1, msg: "未知错误", data: []};

    if (fileUri == null || fileUri == "") {
        result["msg"] = "参数为空";
        return result;
    }

    if (Platform.OS == 'web') {
        const link = document.createElement('a');
        link.download = imgName;
        link.href = fileUri;
        link.click();
        result = {code: 1, msg: "保存图片成功", data: []};
    } else {
        // 请求相册权限
        const mediaPermiss = await MediaLibrary.requestPermissionsAsync();
        switch (mediaPermiss["status"]) {
            case "denied": {
                // 没有权限
                result["code"] = -2;
                result["msg"] = "没有相册权限";
                break;
            }
            case "granted": {
                // 将图片保存到相册
                const imgAsset = await MediaLibrary.createAssetAsync(fileUri);
                result = {code: 1, msg: "保存图片成功", data: imgAsset};
                break
            }
        }
    }

    return  result;

}