/**
 * GSZhiZuo
 * GSProgressPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/19
 * @desc:   GSProgressPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    FlatList,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";

const ITEM_HEIGHT = 44.0;

class GSProgressPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            finishList:[],
            sendList:[],
            tabTag:1,
        };
    }


    componentDidMount() {
        // 获取侧边栏数据
        this.getProgressListData();
    }

    // 获取侧边栏数据
    getProgressListData = () =>{
        showLoading();
        storageLoad(StorageKey.PROGRESS_LIST, (code, data) => {
            closeLoading();
            if (code === 1) {
                // 获取成功
                const finishList = data['Finish'];
                const sendList = data['Send'];
                this.setState({
                    finishList:finishList,
                    sendList:sendList,
                });
            } else {
                // 获取失败
                showAlert(T("获取网络数据失败，是否需要重试?"),"",[T("否"), T("是")], (index, title)=>{
                    if (index === 1) {
                        // 重新获取数据
                        this.getProgressListData();
                    }
                });
            }
        });
    };


    // 点击Item
    onItemPress = (data, index) => {
        // alert(index);
    };

    // 点击标签
    onTabbarPress = (tag) =>{
        this.setState({
            tabTag:tag
        });
    };

    render() {

        const {tabTag, sendList, finishList} = this.state||{};
        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View style={styles.tabbar_view}>
                            <TouchableOpacity style={tabTag===1?styles.tabbar_view_btn_sel:styles.tabbar_view_btn} onPress={()=>{this.onTabbarPress(1)}}>
                                <Text style={tabTag===1?styles.tabbar_view_text_sel:styles.tabbar_view_text}>{T("已完成")}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={tabTag===2?styles.tabbar_view_btn_sel:styles.tabbar_view_btn}  onPress={()=>{this.onTabbarPress(2)}}>
                                <Text style={tabTag===2?styles.tabbar_view_text_sel:styles.tabbar_view_text}>{T("未完成")}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.list_header_view}>
                            <Text style={styles.list_header_text}>{T("单号")}</Text>
                            <Text style={styles.list_header_text_time}>{T("下单时间")}</Text>
                            <Text style={styles.list_header_text}>{T("联络分行")}</Text>
                            <Text style={styles.list_header_text_contacts}>{T("联络人")}</Text>
                        </View>
                        <FlatList renderItem={({item, index}) => this.progressItem(item, index)}
                                  data={tabTag===1?finishList:sendList}
                                  keyExtractor={(item, index) => index+''}
                                  numColumns={1}
                                  showsVerticalScrollIndicator = {false}
                                  initialNumToRender={Math.ceil(screenHeight/ITEM_HEIGHT)}
                            // getItemLayout={(data, index) => (
                            //     {length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index}
                            // )}
                        />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    progressItem = (data, index) =>{
        const styles = this.getStyles();
        return (
            <TouchableOpacity style={styles.item} activeOpacity={0.8}  onPress={()=>this.onItemPress()}>
                <Text numberOfLines={1} style={styles.item_text}>{data['listid']}</Text>
                <Text numberOfLines={1} style={styles.item_text_time}>{data['indate']}</Text>
                <Text numberOfLines={1} style={styles.item_text}>{data['contactbranch']}</Text>
                <Text numberOfLines={2} style={styles.item_text_contacts}>{data['contactuser']}</Text>
            </TouchableOpacity>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            tabbar_view:{
                height:44,
                flexDirection: 'row',
            },
            tabbar_view_btn:{
                flex:1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:Colors.box_progress_FFF8DA,
                borderBottomWidth: 2,
                borderBottomColor:Colors.box_progress_DDDDDD,
            },
            tabbar_view_btn_sel:{
                flex:1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:Colors.box_progress_FFF8DA,
                borderBottomWidth: 2,
                borderBottomColor:Colors.theme_000000,
            },
            tabbar_view_text:{
                color:Colors.box_progress_DDDDDD,
            },
            tabbar_view_text_sel:{
                color:Colors.theme_000000,
            },

            list_header_view:{
                height:30,
                flexDirection: 'row',
                backgroundColor:Colors.box_progress_FFF8DA,
                alignItems: 'center',
            },
            list_header_text:{
                fontSize:13,
                color:Colors.box_progress_80807A,
                flex:1,
                textAlign:'center',
            },
            list_header_text_contacts:{
                fontSize:13,
                color:Colors.box_progress_80807A,
                flex:1.2,
                textAlign:'center',
            },
            list_header_text_time:{
                fontSize:13,
                color:Colors.box_progress_80807A,
                flex:2,
                textAlign:'center',
            },

            item:{
                height:44,
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor:Colors.theme_FFFFFF,
                borderBottomWidth: 0.5,
                borderBottomColor:Colors.box_progress_DDDDDD,
            },

            item_text:{
                fontSize:12,
                flex:1,
                textAlign:'center',
                color:Colors.theme_000000,
            },
            item_text_contacts:{
                fontSize:12,
                flex:1.2,
                textAlign:'center',
                color:Colors.theme_000000,
            },
            item_text_time:{
                fontSize:12,
                flex:2,
                textAlign:'center',
                color:Colors.theme_000000,
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSProgressPage)


// export default GSProgressPage;