/**
 * GSZhiZuo
 * GSGoldAddTextPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/7
 * @desc:   GSGoldAddTextPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    TextInput,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import {TextModel} from "../../Common/Utils/GSDefined";
import {
    goldBarAddEditButtonAction,
    goldBarUpdatedEditButtonAction,
    goldBarDeleteEditButtonAction,
} from "../../Common/Redux/GSReduxAction";

class GSGoldAddTextPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        const currEditBtn = props.route.params?.["currEditBtn"];

        let textValue = '';
        if (!isEmptyObject(currEditBtn)){
            textValue = currEditBtn.textValue;
        }

        this.state = {
            textValue:textValue,
        };
    }

    componentDidMount() {
        setTimeout(()=>{
            this.textInput.focus();
        },100);
    }


    // 点击取消
    onCancelPress = () => {
        this.goBack();
    };

    // 点击完成
    onFinishPress = () => {

        const currEditBtn = this.props.route.params?.["currEditBtn"];

        let textValue = this.state.textValue;
        if (!isEmptyObject(currEditBtn)){
            if (isEmptyString(textValue)){
                // 移除对象
                goldBarDeleteEditButtonAction();
            }  else {
                // 修改原来对象
                let newEditBtn= {...currEditBtn};
                newEditBtn.textValue = textValue;
                goldBarUpdatedEditButtonAction(newEditBtn);
            }
        }else if(!isEmptyString(textValue)) {
            // 添加新对象
            let newEditBtn= cloneDeep(TextModel);
            newEditBtn.textValue = textValue;
            goldBarAddEditButtonAction(newEditBtn);
        }

        // 返回
        this.goBack();

    };


    render() {
        const {textValue} = this.state;

        const {Colors} = Theme;
        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                            <View style={styles.header_navigation}>
                                <TouchableOpacity onPress={this.onCancelPress}>
                                    <Text style={styles.header_navigation_text} >{T("取消")}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={this.onFinishPress}>
                                    <Text style={styles.header_navigation_text}>{T("完成")}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.text_input_view}>
                                <TextInput style={styles.text_input}
                                           multiline={true}
                                           autoFocus={false}
                                           placeholder={T("请输入文本，本区域不能缩放和移动")}
                                           placeholderTextColor={Colors.goldbar_addtext_9A9A9A_s}
                                           onChangeText={(text) => this.setState({textValue:text})}
                                           value={textValue}
                                           ref={(ref)=>{this.textInput = ref}}
                                />
                            </View>
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_000000_s,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_000000_s,
            },
            header_navigation:{
                height:44,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems:'center',
            },
            header_navigation_text:{
                color:Colors.theme_FFFFFF_s,
                paddingTop:5,
                paddingBottom:5,
                paddingRight:16,
                paddingLeft:16,
                fontSize:18,
            },
            text_input_view:{
                flex: 1,
                margin:20,
                overflow:"hidden",
            },
            text_input:{
                flex: 1,
                fontSize:20,
                padding: 1,
                margin:-1,
                textAlignVertical: 'top',
                color:Colors.theme_FFFFFF_s,
            },
        })
    }
}



export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSGoldAddTextPage)


// export default GSGoldAddTextPage;