/**
 * GSZhiZuo
 * GSEditTextView.js
 *
 * @author: zengzhiming
 * @date:   2023/6/19
 * @desc:   GSEditTextView
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    StyleSheet,
    Text,
    View,
    Animated,
    Image,
} from 'react-native';
import {TapGestureHandler, State} from 'react-native-gesture-handler';
import BasePage from "../../../Common/Component/BaseComponent/GSBasePage";
import {TextModel, minFont, maxFont} from "../../../Common/Utils/GSDefined";
import {
    plasticBoxSelectedEditButtonAction,
    plasticBoxLoadLastDataAction,
    plasticBoxDeleteEditButtonAction,
    plasticBoxClearEditBoxListAction,
    plasticBoxUpdatedEditButtonAction,
    AnimatedDict,
} from "../../../Common/Redux/GSReduxAction";


class GSEditTextView extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        // 设置默认位置
        TextModel.lastOffset = {x:props.editWidth*0.2 ,y:props.editHeight*0.2};
        this.magniScale = props["magniScale"] ? props["magniScale"] : 1;

        this.state = {};

    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // 校验大小和偏移量
        const currIndex = nextProps.currIndex;
        if (currIndex >= 0 && currIndex === this.props.currIndex) {
            const currEditBtn = nextProps.editButtonList[currIndex];
            this.checkMoveOffset(currEditBtn);
            this.checkFontSize(currEditBtn);
        }

        return true;
    }

    componentDidMount() {
        if (this.props["isEdit"]) {
            // 加载上次数据
            this.loadLastListData();
        }
    }


    // 加载上次数据
    loadLastListData = () => {
        // 清除缓存
        plasticBoxClearEditBoxListAction();
        // 加载数据
        storageLoad(StorageKey.BOX_LAST_DATA, (code, dataList)=>{
            if (code === 1 && !isEmptyObject(dataList)){
                showAlert(T("是否加载上次编辑信息?"), "", [T("否"),T("是")], (index)=>{
                    if (index === 1) {
                        // 加载上次编辑数据
                        let editButtonList = [...dataList];
                        plasticBoxLoadLastDataAction(editButtonList);
                        // 更新偏移量
                        requestAnimationFrame(()=>{
                            editButtonList.map((textObj)=>{
                                this.checkMoveOffset(textObj);
                            });
                        });
                    }
                });
            }
        });
    };


    // 校验字体大小
    checkFontSize = (editBtn) => {

        if(isEmptyObject(editBtn)){
            return;
        }

        let currEditBtn = {...editBtn};
        let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];

        let sizeScale = currEditBtn.sizeScale;
        sizeScale = parseInt(sizeScale);
        if (sizeScale < minFont) sizeScale = minFont;
        if (sizeScale > maxFont) sizeScale = maxFont;
        if (currEditBtn.sizeScale !== sizeScale) {
            currEditBtn.sizeScale = sizeScale;
            currEditBtnAnimated.baseScale.setValue(sizeScale);
            currEditBtnAnimated.pinchScale.setValue(1);
            if (this.props.currIndex >= 0) {
                plasticBoxUpdatedEditButtonAction(currEditBtn);
            }
        }
    };

    // 校验移动偏移
    checkMoveOffset = (editBtn) => {

        if(isEmptyObject(editBtn)){
            return;
        }

        let currEditBtn = {...editBtn};
        let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];

        let lastOffsetX = currEditBtn.lastOffset.x;
        let lastOffsetY = currEditBtn.lastOffset.y;

        // 判断是否移出框外
        Frame(currEditBtnAnimated.ref, (frame) => {

            let maxOffsetX = this.props.editWidth - frame.width;
            if (lastOffsetX > maxOffsetX) {
                lastOffsetX = maxOffsetX;
            }

            let maxOffsetY = this.props.editHeight - frame.height;
            if (lastOffsetY > maxOffsetY) {
                lastOffsetY = maxOffsetY;
            }

            let minOffsetX = 0;
            if (lastOffsetX < minOffsetX) {
                lastOffsetX = minOffsetX;
            }

            let minOffsetY = 0;
            if (lastOffsetY < minOffsetY) {
                lastOffsetY = minOffsetY;
            }
            if (currEditBtn.lastOffset.x !== lastOffsetX || currEditBtn.lastOffset.y !== lastOffsetY) {
                currEditBtn.lastOffset = {x:lastOffsetX, y:lastOffsetY};
                currEditBtnAnimated.offsetValue.setOffset({x:lastOffsetX, y:lastOffsetY});
                currEditBtnAnimated.offsetValue.setValue({x:0,y:0});
                if (this.props.currIndex >= 0) {
                    plasticBoxUpdatedEditButtonAction(currEditBtn);
                }
            }
        });
    };


    // 点击编辑按钮
    onEditBtnPress = (event, index) => {

        if (!this.props["isEdit"]) {
            return;
        }

        if(this.props.currIndex === index){
            // 判断双击
            if (isDoublePress(1, 1000)) {
                // 修改文本
                let currEditBtn = this.props.editButtonList[index];
                this.props["navigation"].push(SceneKey.BoxAddText, {currEditBtn:currEditBtn});
            }

        } else {
            // 选中对象
            plasticBoxSelectedEditButtonAction(index);
        }
    };

    // 点击删除编辑按钮
    onDeleteEditBtnPress = (event, index) => {
        if(this.props.currIndex === index){
            showAlert(T("确定删除文本?"),'',[T("取消"), T("确定")], (index, btnTitle)=>{
                if (index === 1) {
                    // 删除
                    plasticBoxDeleteEditButtonAction();
                }
            });
        }
    };


    getText = (currEditBtn) => {
        let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
        const textValue = currEditBtn.textValue;
        const textDirection = currEditBtn.textDirection;
        let textArr = [];
        if (textDirection === 1) {
            let verTextValue = textValue;
            this.props.specialCharList.map((cahr, index)=>{
                verTextValue = verTextValue.replace(cahr['hor'], cahr['ver']);
            });

            // 竖排文本
            verTextValue.split('').map((char, index)=>{
                const n = index === verTextValue.length-1  ? '' : '\n';
                if (char === ' ') {
                    const fontSize = Animated.multiply(Animated.multiply(currEditBtnAnimated.baseScale, currEditBtnAnimated.pinchScale), new Animated.Value(0.5));
                    textArr.push(
                        <Animated.Text key={textArr.length} style={{fontSize: Animated.multiply(fontSize, this.magniScale)}}>{char}{n}</Animated.Text>
                    );
                } else {
                    textArr.push(
                        <Text key={textArr.length}>{char}{n}</Text>
                    );
                }
            })
        } else {
            // 横排文本
            textArr.push(
                <Text key={textArr.length}>{textValue}</Text>
            );
        }
        return textArr
    };

    // 创建文本View
    createEditButtonView = (editButtonList) => {

        const {Images, Colors} = Theme;

        let textViewArray = [];
        editButtonList.map((currEditBtn, index) => {
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            const selected = this.props.currIndex===index;
            const borderColor = selected ? Colors.box_edit_view_EB4E00_s : Colors.theme_00000000_s;

            textViewArray.push(
                <Animated.View
                    key={index}
                    ref={(ref)=>{if (this.magniScale === 1) currEditBtnAnimated.ref=ref}}
                    style={{
                        position:'absolute',
                        left:Animated.multiply(currEditBtnAnimated.offsetValue.x, this.magniScale),
                        top:Animated.multiply(currEditBtnAnimated.offsetValue.y, this.magniScale),
                        borderWidth: 1,
                        borderColor:borderColor,
                        padding:3,
                        transform: [
                            { rotate: currEditBtnAnimated.rotate.interpolate({inputRange: [-100, 100], outputRange: ['-100rad', '100rad']}) },
                        ],
                    }}>
                    <TapGestureHandler onHandlerStateChange={(event)=>{
                        if (event.nativeEvent.oldState === State.ACTIVE) { this.onEditBtnPress(event, index) }
                    }}>
                        <Animated.Text
                            style={{
                                fontSize: Animated.multiply(Animated.multiply(currEditBtnAnimated.baseScale, currEditBtnAnimated.pinchScale), this.magniScale),
                                color:currEditBtn.color,
                                fontFamily:currEditBtn.fontFamily,
                                padding:0,
                                letterSpacing: 0,
                            }}
                        >
                            {this.getText(currEditBtn)}
                        </Animated.Text>
                    </TapGestureHandler>
                    {selected&&<View style={{position:'absolute', width: 4, height:4, left:-2, top:-2, backgroundColor:borderColor}} />}
                    {selected&&<View style={{position:'absolute', width: 4, height:4, left:-2, bottom:-2, backgroundColor:borderColor}} />}
                    {selected&&<View style={{position:'absolute', width: 4, height:4, right:-2, bottom:-2, backgroundColor:borderColor}} />}
                    {/*{selected&&<View style={{position:'absolute', width: 4, height:4, right:-2, top:-2, backgroundColor:borderColor}} />}*/}
                    {
                        selected&&
                        <TapGestureHandler onHandlerStateChange={(event)=>{
                            if (event.nativeEvent.oldState === State.ACTIVE) { this.onDeleteEditBtnPress(event, index) }
                        }}>
                            <View style={{position:'absolute', right:-15, top:-15,}}>
                                <Image source={Images.edit_text_delete} style={{ width: 18, height:18,  margin:6,}}/>
                            </View>
                        </TapGestureHandler>
                    }
                </Animated.View>

            )
        });
        return textViewArray;
    };




    render() {

        const {Images} = Theme;

        const { editButtonList, editWidth, editHeight, picPath} = this.props || {};
        const editGrid = isTablet ? Images.box_edit_grid_pad : Images.box_edit_grid_phone;
        const girdHeight = editWidth*(554.0/375.0);

        const styles = this.getStyles();

        return (
            <View style={[styles.edit_content_view,{width:editWidth, height:editHeight}]}>
                {!isEmptyString(picPath)&&<Image resizeMode={'stretch'} source={{uri:picPath}} style={{position:'absolute', width:editWidth, height:editHeight}} />}
                {/*<Image source={editGrid} style={{position:'absolute', width:editWidth, height:girdHeight, resizeMode:'stretch'}} />*/}
                <View style={{position:'absolute', width:editWidth, height:editHeight,  justifyContent:"center", alignItems:"center", overflow:"hidden"}}>
                    <Image source={Images.box_edit_grid_pad} style={{width:768, height:1135}} />
                </View>
                {   !isEmptyObject(editButtonList) ? this.createEditButtonView(editButtonList) :
                    <View style={styles.edit_content_hint_view}>
                        <Text style={styles.edit_content_hint_text}>{T("本区域内的文字\n可以缩放和移动")}</Text>
                    </View>
                }
            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;
        return StyleSheet.create({
            edit_content_view:{
                backgroundColor:Colors.box_edit_view_FFF7D3_s,
                overflow:'hidden',
            },
            edit_content_hint_view:{
                justifyContent:'center',
                alignItems:'center',
                position:'absolute',
                top:0,
                left:0,
                right:0,
                bottom:0
            },
            edit_content_hint_text:{
                fontSize:18,
                color: Colors.box_edit_view_B4B4B4_s,
            }
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
            currIndex:state.plasticBox.currIndex,
            editButtonList:state.plasticBox.editButtonList,
            specialCharList:state.specialChar,
        })
    }
)(GSEditTextView)


// export default GSEditTextView;