/**
 * GSZhiZuo
 * GSReduxAction.js
 *
 * @author: zengzhiming
 * @date:   2023/6/5
 * @desc:   GSReduxAction
 **/
'use strict';

import {
    reduxStore,
    languageChange,
    themeChange,
    specialCharChange,
    goldBarChange,
    goldBarAddEditButton,
    goldBarUpdatedEditButton,
    goldBarDeleteEditButton,
    plasticBoxChange ,
    plasticBoxAddEditButton,
    plasticBoxUpdatedEditButton,
    plasticBoxDeleteEditButton,
    plasticBoxBgChange ,
    plasticBoxBgAddEditButton,
    plasticBoxBgUpdatedEditButton,
    plasticBoxBgDeleteEditButton,
} from "./GSReduxStore";
import {Animated} from "react-native";
import {randomUUID} from '../Utils/GSCrypto';

export let AnimatedDict = {};

/**
 *  公共Action
 **/
export function languageChangeAction(payload) {
    reduxStore.dispatch(languageChange(payload));
}

export function themeChangeAction(payload) {
    reduxStore.dispatch(themeChange(payload));
}

// 更新特殊字符
export function updatedSpecialCharAction(specialCharList) {
    if (isEmptyObject(specialCharList)) {
        specialCharList = [];
    }
    reduxStore.dispatch(specialCharChange(specialCharList));
}


// ==========================GoldBar===================================


/**
 * GoldBar Action
 **/
// 清除编辑框列表
export function goldBarClearEditBoxListAction() {

    const goldBarUpdate = {
        editBoxList:[],
        currBoxIndex:-1,
        currBtnIndex: -1,
    };

    reduxStore.dispatch(goldBarChange(goldBarUpdate));
}


// 添加编辑框列表
export function goldBarAddEditBoxListAction(editBoxList) {
    if (isEmptyObject(editBoxList)) {
        return;
    }

    const goldBarUpdate = {
        editBoxList:editBoxList,
        currBoxIndex:-1,
        currBtnIndex: -1,
    };

    reduxStore.dispatch(goldBarChange(goldBarUpdate));
}


// 选中编辑区，取消编辑按钮选中状态
export function goldBarSelectEditBoxAction(boxIndex) {

    const goldBarUpdate = {
        currBoxIndex:boxIndex,
        currBtnIndex:-1,
    };

    reduxStore.dispatch(goldBarChange(goldBarUpdate));
}


// 取消当前编辑区选中状态
export function goldBarDeselectEditBoxAction() {

    const goldBarUpdate = {
        currBoxIndex:-1,
        currBtnIndex: -1,
    };

    reduxStore.dispatch(goldBarChange(goldBarUpdate));
}


// 添加新对象并选中为当前对象
export function goldBarAddEditButtonAction(newEditBtn) {
    if (isEmptyObject(newEditBtn)) {
        return;
    }

    newEditBtn.animatedId = getAnimatedId("GoldBar", newEditBtn);

    reduxStore.dispatch(goldBarAddEditButton(newEditBtn));
}

// 选中为当前对象
export function goldBarSelectedEditButtonAction(boxIndex, btnIndex) {

    if (boxIndex < 0 || btnIndex < 0) {
        return;
    }

    const goldBarUpdate = {
        currBoxIndex:boxIndex,
        currBtnIndex: btnIndex,
    };

    reduxStore.dispatch(goldBarChange(goldBarUpdate));
}

// 更新当前对象
export function goldBarUpdatedEditButtonAction(newEditBtn) {
    if (isEmptyObject(newEditBtn)) {
        return;
    }

    reduxStore.dispatch(goldBarUpdatedEditButton(newEditBtn));
}


// 删除当前对象
export function goldBarDeleteEditButtonAction() {
    reduxStore.dispatch(goldBarDeleteEditButton());
}


// 开启预览模式
export function goldBarOpenPreviewAction() {

    const goldBarUpdate = {
        isPreview:true,
        currBoxIndex:-1,
        currBtnIndex: -1,
    };

    reduxStore.dispatch(goldBarChange(goldBarUpdate));
}

// 关闭预览模式
export function goldBarClosePreviewAction() {

    const goldBarUpdate = {
        isPreview:false,
    };

    reduxStore.dispatch(goldBarChange(goldBarUpdate));
}



// ==========================PlasticBox===================================


/**
 * PlasticBox Action
 **/
// 更新当前对象
export function plasticBoxUpdatedEditButtonAction(newEditBtn) {
    if (isEmptyObject(newEditBtn)) {
        return;
    }

    reduxStore.dispatch(plasticBoxUpdatedEditButton(newEditBtn));

}


// 添加新对象并选中为当前对象
export function plasticBoxAddEditButtonAction(newEditBtn) {
    if (isEmptyObject(newEditBtn)) {
        return;
    }

    newEditBtn.animatedId = getAnimatedId("PlasticBox", newEditBtn);

    reduxStore.dispatch(plasticBoxAddEditButton(newEditBtn));
}

// 删除当前对象
export function plasticBoxDeleteEditButtonAction() {
    reduxStore.dispatch(plasticBoxDeleteEditButton());
}


// 选中为当前对象
export function plasticBoxSelectedEditButtonAction(currIndex) {
    if (currIndex < 0) {
        return;
    }

    const plasticBoxUpdate = {
        currIndex:currIndex
    };

    reduxStore.dispatch(plasticBoxChange(plasticBoxUpdate));
}



// 取消当前选中状态
export function plasticBoxDeselectEditButtonAction() {

    const plasticBoxUpdate = {
        currIndex:-1
    };

    reduxStore.dispatch(plasticBoxChange(plasticBoxUpdate));
}


// 清除编辑框列表
export function plasticBoxClearEditBoxListAction() {

    const goldBarUpdate = {
        editButtonList:[],
        currIndex:-1
    };

    reduxStore.dispatch(plasticBoxChange(goldBarUpdate));
}

// 加载数据
export function plasticBoxLoadLastDataAction(editButtonList) {

    if (isEmptyObject(editButtonList)) {
        return;
    }

    editButtonList.map((newEditBtn)=>{
        newEditBtn.animatedId = getAnimatedId("PlasticBox", newEditBtn);
    });

    const plasticBoxUpdate = {
        editButtonList:editButtonList,
        currIndex:-1
    };

    reduxStore.dispatch(plasticBoxChange(plasticBoxUpdate));
}



// ==========================PlasticBoxBg===================================


/**
 * PlasticBoxBg Action
 **/
// 更新当前对象
export function plasticBoxBgUpdatedEditButtonAction(newEditBtn) {
    if (isEmptyObject(newEditBtn)) {
        return;
    }

    reduxStore.dispatch(plasticBoxBgUpdatedEditButton(newEditBtn));

}


// 添加新对象并选中为当前对象
export function plasticBoxBgAddEditButtonAction(newEditBtn) {
    if (isEmptyObject(newEditBtn)) {
        return;
    }

    newEditBtn.animatedId = getAnimatedId("PlasticBoxBg", newEditBtn);

    reduxStore.dispatch(plasticBoxBgAddEditButton(newEditBtn));
}

// 删除当前对象
export function plasticBoxBgDeleteEditButtonAction() {
    reduxStore.dispatch(plasticBoxBgDeleteEditButton());
}


// 选中为当前对象
export function plasticBoxBgSelectedEditButtonAction(currIndex) {
    if (currIndex < 0) {
        return;
    }

    const plasticBoxUpdate = {
        currIndex:currIndex
    };

    reduxStore.dispatch(plasticBoxBgChange(plasticBoxUpdate));
}



// 取消当前选中状态
export function plasticBoxBgDeselectEditButtonAction() {

    const plasticBoxUpdate = {
        currIndex:-1
    };

    reduxStore.dispatch(plasticBoxBgChange(plasticBoxUpdate));
}


// 清除编辑框列表
export function plasticBoxBgClearEditBoxListAction() {

    const goldBarUpdate = {
        editButtonList:[],
        currIndex:-1
    };

    reduxStore.dispatch(plasticBoxBgChange(goldBarUpdate));
}

// 加载数据
export function plasticBoxBgLoadLastDataAction(editButtonList) {

    if (isEmptyObject(editButtonList)) {
        return;
    }

    editButtonList.map((newEditBtn)=>{
        newEditBtn.animatedId = getAnimatedId("PlasticBoxBg", newEditBtn);
    });

    const plasticBoxUpdate = {
        editButtonList:editButtonList,
        currIndex:-1
    };

    reduxStore.dispatch(plasticBoxBgChange(plasticBoxUpdate));
}


function getAnimatedId(module, newEditBtn) {
    const animatedId = module+randomUUID();
    const btnAnimated = {
        baseScale : new Animated.Value(newEditBtn.sizeScale),
        pinchScale : new Animated.Value(1),
        rotate : new Animated.Value(newEditBtn.lastRotate),
        offsetValue: new Animated.ValueXY({x:0, y:0}),
        ref:undefined,
    }
    btnAnimated.offsetValue.setOffset(newEditBtn.lastOffset);
    AnimatedDict[animatedId] = btnAnimated;
    return animatedId;
}