/**
 * GSZhiZuo
 * GSConstants.js
 *
 * @author: zengzhiming
 * @date:   2023/2/29 10:51
 * @desc:   GSConstants
 **/
'use strict';

import Constants from 'expo-constants';


/**
 * 是否debug模式
 * false / true
 **/
export const debugMode = Constants.debugMode;

/**
 * 状态栏的高度
 * 54
 **/
export const statusBarHeight = Constants.statusBarHeight;


/**
 * 会话ID,每次启动应用会话ID都会变
 * "765B5F87-9910-48D1-A1A2-FB05A10F6B34"
 **/
export const sessionId = Constants.sessionId;


/**
 * app.json配置信息
 * {}
 **/
export const expoConfig = Constants.expoConfig;




// {
//     "isDevice":false,
//     "debugMode":true,
//     "executionEnvironment":"bare",
//     "deviceName":"iPhone 14 Pro",
//     "nativeBuildVersion":"1",
//     "statusBarHeight":54,
//     "sessionId":"765B5F87-9910-48D1-A1A2-FB05A10F6B34",
//     "nativeAppVersion":"1.0.0",
//     "systemFonts":[
//     "Academy Engraved LET",
//     "AcademyEngravedLetPlain",
//     "Al Nile",
//     "AlNile",
//     "AlNile-Bold",
//     "American Typewriter",
//     "AmericanTypewriter",
//     "AmericanTypewriter-Bold",
//     "AmericanTypewriter-Condensed",
//     "AmericanTypewriter-CondensedBold",
//     "AmericanTypewriter-CondensedLight",
//     "AmericanTypewriter-Light",
//     "AmericanTypewriter-Semibold",
//     "Apple Color Emoji",
//     "Apple SD Gothic Neo",
//     "Apple Symbols",
//     "AppleColorEmoji",
//     "AppleSDGothicNeo-Bold",
//     "AppleSDGothicNeo-Light",
//     "AppleSDGothicNeo-Medium",
//     "AppleSDGothicNeo-Regular",
//     "AppleSDGothicNeo-SemiBold",
//     "AppleSDGothicNeo-Thin",
//     "AppleSDGothicNeo-UltraLight",
//     "AppleSymbols",
//     "Arial",
//     "Arial Hebrew",
//     "Arial Rounded MT Bold",
//     "Arial-BoldItalicMT",
//     "Arial-BoldMT",
//     "Arial-ItalicMT",
//     "ArialHebrew",
//     "ArialHebrew-Bold",
//     "ArialHebrew-Light",
//     "ArialMT",
//     "ArialRoundedMTBold",
//     "Avenir",
//     "Avenir Next",
//     "Avenir Next Condensed",
//     "Avenir-Black",
//     "Avenir-BlackOblique",
//     "Avenir-Book",
//     "Avenir-BookOblique",
//     "Avenir-Heavy",
//     "Avenir-HeavyOblique",
//     "Avenir-Light",
//     "Avenir-LightOblique",
//     "Avenir-Medium",
//     "Avenir-MediumOblique",
//     "Avenir-Oblique",
//     "Avenir-Roman",
//     "AvenirNext-Bold",
//     "AvenirNext-BoldItalic",
//     "AvenirNext-DemiBold",
//     "AvenirNext-DemiBoldItalic",
//     "AvenirNext-Heavy",
//     "AvenirNext-HeavyItalic",
//     "AvenirNext-Italic",
//     "AvenirNext-Medium",
//     "AvenirNext-MediumItalic",
//     "AvenirNext-Regular",
//     "AvenirNext-UltraLight",
//     "AvenirNext-UltraLightItalic",
//     "AvenirNextCondensed-Bold",
//     "AvenirNextCondensed-BoldItalic",
//     "AvenirNextCondensed-DemiBold",
//     "AvenirNextCondensed-DemiBoldItalic",
//     "AvenirNextCondensed-Heavy",
//     "AvenirNextCondensed-HeavyItalic",
//     "AvenirNextCondensed-Italic",
//     "AvenirNextCondensed-Medium",
//     "AvenirNextCondensed-MediumItalic",
//     "AvenirNextCondensed-Regular",
//     "AvenirNextCondensed-UltraLight",
//     "AvenirNextCondensed-UltraLightItalic",
//     "Baskerville",
//     "Baskerville-Bold",
//     "Baskerville-BoldItalic",
//     "Baskerville-Italic",
//     "Baskerville-SemiBold",
//     "Baskerville-SemiBoldItalic",
//     "Bodoni 72",
//     "Bodoni 72 Oldstyle",
//     "Bodoni 72 Smallcaps",
//     "Bodoni Ornaments",
//     "BodoniOrnamentsITCTT",
//     "BodoniSvtyTwoITCTT-Bold",
//     "BodoniSvtyTwoITCTT-Book",
//     "BodoniSvtyTwoITCTT-BookIta",
//     "BodoniSvtyTwoOSITCTT-Bold",
//     "BodoniSvtyTwoOSITCTT-Book",
//     "BodoniSvtyTwoOSITCTT-BookIt",
//     "BodoniSvtyTwoSCITCTT-Book",
//     "Bradley Hand",
//     "BradleyHandITCTT-Bold",
//     "Chalkboard SE",
//     "ChalkboardSE-Bold",
//     "ChalkboardSE-Light",
//     "ChalkboardSE-Regular",
//     "Chalkduster",
//     "Charter",
//     "Charter-Black",
//     "Charter-BlackItalic",
//     "Charter-Bold",
//     "Charter-BoldItalic",
//     "Charter-Italic",
//     "Charter-Roman",
//     "Cochin",
//     "Cochin-Bold",
//     "Cochin-BoldItalic",
//     "Cochin-Italic",
//     "Copperplate",
//     "Copperplate-Bold",
//     "Copperplate-Light",
//     "Courier New",
//     "CourierNewPS-BoldItalicMT",
//     "CourierNewPS-BoldMT",
//     "CourierNewPS-ItalicMT",
//     "CourierNewPSMT",
//     "Damascus",
//     "DamascusBold",
//     "DamascusLight",
//     "DamascusMedium",
//     "DamascusSemiBold",
//     "Devanagari Sangam MN",
//     "DevanagariSangamMN",
//     "DevanagariSangamMN-Bold",
//     "Didot",
//     "Didot-Bold",
//     "Didot-Italic",
//     "DIN Alternate",
//     "DIN Condensed",
//     "DINAlternate-Bold",
//     "DINCondensed-Bold",
//     "DiwanMishafi",
//     "Euphemia UCAS",
//     "EuphemiaUCAS",
//     "EuphemiaUCAS-Bold",
//     "EuphemiaUCAS-Italic",
//     "Farah",
//     "Futura",
//     "Futura-Bold",
//     "Futura-CondensedExtraBold",
//     "Futura-CondensedMedium",
//     "Futura-Medium",
//     "Futura-MediumItalic",
//     "Galvji",
//     "Galvji-Bold",
//     "Geeza Pro",
//     "GeezaPro",
//     "GeezaPro-Bold",
//     "Georgia",
//     "Georgia-Bold",
//     "Georgia-BoldItalic",
//     "Georgia-Italic",
//     "Gill Sans",
//     "GillSans",
//     "GillSans-Bold",
//     "GillSans-BoldItalic",
//     "GillSans-Italic",
//     "GillSans-Light",
//     "GillSans-LightItalic",
//     "GillSans-SemiBold",
//     "GillSans-SemiBoldItalic",
//     "GillSans-UltraBold",
//     "Grantha Sangam MN",
//     "GranthaSangamMN-Bold",
//     "GranthaSangamMN-Regular",
//     "Helvetica",
//     "Helvetica Neue",
//     "Helvetica-Bold",
//     "Helvetica-BoldOblique",
//     "Helvetica-Light",
//     "Helvetica-LightOblique",
//     "Helvetica-Oblique",
//     "HelveticaNeue",
//     "HelveticaNeue-Bold",
//     "HelveticaNeue-BoldItalic",
//     "HelveticaNeue-CondensedBlack",
//     "HelveticaNeue-CondensedBold",
//     "HelveticaNeue-Italic",
//     "HelveticaNeue-Light",
//     "HelveticaNeue-LightItalic",
//     "HelveticaNeue-Medium",
//     "HelveticaNeue-MediumItalic",
//     "HelveticaNeue-Thin",
//     "HelveticaNeue-ThinItalic",
//     "HelveticaNeue-UltraLight",
//     "HelveticaNeue-UltraLightItalic",
//     "Hiragino Maru Gothic ProN",
//     "Hiragino Mincho ProN",
//     "Hiragino Sans",
//     "HiraginoSans-W3",
//     "HiraginoSans-W6",
//     "HiraginoSans-W7",
//     "HiraMaruProN-W4",
//     "HiraMinProN-W3",
//     "HiraMinProN-W6",
//     "Hoefler Text",
//     "HoeflerText-Black",
//     "HoeflerText-BlackItalic",
//     "HoeflerText-Italic",
//     "HoeflerText-Regular",
//     "Impact",
//     "Kailasa",
//     "Kailasa-Bold",
//     "Kefa",
//     "Kefa-Regular",
//     "Khmer Sangam MN",
//     "KhmerSangamMN",
//     "Kohinoor Bangla",
//     "Kohinoor Devanagari",
//     "Kohinoor Gujarati",
//     "Kohinoor Telugu",
//     "KohinoorBangla-Light",
//     "KohinoorBangla-Regular",
//     "KohinoorBangla-Semibold",
//     "KohinoorDevanagari-Light",
//     "KohinoorDevanagari-Regular",
//     "KohinoorDevanagari-Semibold",
//     "KohinoorGujarati-Bold",
//     "KohinoorGujarati-Light",
//     "KohinoorGujarati-Regular",
//     "KohinoorTelugu-Light",
//     "KohinoorTelugu-Medium",
//     "KohinoorTelugu-Regular",
//     "Lao Sangam MN",
//     "LaoSangamMN",
//     "Malayalam Sangam MN",
//     "MalayalamSangamMN",
//     "MalayalamSangamMN-Bold",
//     "Marker Felt",
//     "MarkerFelt-Thin",
//     "MarkerFelt-Wide",
//     "Menlo",
//     "Menlo-Bold",
//     "Menlo-BoldItalic",
//     "Menlo-Italic",
//     "Menlo-Regular",
//     "Mishafi",
//     "Mukta Mahee",
//     "MuktaMahee-Bold",
//     "MuktaMahee-Light",
//     "MuktaMahee-Regular",
//     "Myanmar Sangam MN",
//     "MyanmarSangamMN",
//     "MyanmarSangamMN-Bold",
//     "Noteworthy",
//     "Noteworthy-Bold",
//     "Noteworthy-Light",
//     "Noto Nastaliq Urdu",
//     "Noto Sans Kannada",
//     "Noto Sans Myanmar",
//     "Noto Sans Oriya",
//     "NotoNastaliqUrdu",
//     "NotoNastaliqUrdu-Bold",
//     "NotoSansKannada-Bold",
//     "NotoSansKannada-Light",
//     "NotoSansKannada-Regular",
//     "NotoSansMyanmar-Bold",
//     "NotoSansMyanmar-Light",
//     "NotoSansMyanmar-Regular",
//     "NotoSansOriya",
//     "NotoSansOriya-Bold",
//     "Optima",
//     "Optima-Bold",
//     "Optima-BoldItalic",
//     "Optima-ExtraBlack",
//     "Optima-Italic",
//     "Optima-Regular",
//     "Palatino",
//     "Palatino-Bold",
//     "Palatino-BoldItalic",
//     "Palatino-Italic",
//     "Palatino-Roman",
//     "Papyrus",
//     "Papyrus-Condensed",
//     "Party LET",
//     "PartyLetPlain",
//     "PingFang HK",
//     "PingFang SC",
//     "PingFang TC",
//     "PingFangHK-Light",
//     "PingFangHK-Medium",
//     "PingFangHK-Regular",
//     "PingFangHK-Semibold",
//     "PingFangHK-Thin",
//     "PingFangHK-Ultralight",
//     "PingFangSC-Light",
//     "PingFangSC-Medium",
//     "PingFangSC-Regular",
//     "PingFangSC-Semibold",
//     "PingFangSC-Thin",
//     "PingFangSC-Ultralight",
//     "PingFangTC-Light",
//     "PingFangTC-Medium",
//     "PingFangTC-Regular",
//     "PingFangTC-Semibold",
//     "PingFangTC-Thin",
//     "PingFangTC-Ultralight",
//     "Rockwell",
//     "Rockwell-Bold",
//     "Rockwell-BoldItalic",
//     "Rockwell-Italic",
//     "Rockwell-Regular",
//     "Savoye LET",
//     "SavoyeLetPlain",
//     "Sinhala Sangam MN",
//     "SinhalaSangamMN",
//     "SinhalaSangamMN-Bold",
//     "Snell Roundhand",
//     "SnellRoundhand",
//     "SnellRoundhand-Black",
//     "SnellRoundhand-Bold",
//     "STIX Two Math",
//     "STIX Two Text",
//     "STIXTwoMath-Regular",
//     "STIXTwoText",
//     "STIXTwoText-Italic",
//     "STIXTwoText-Italic_Bold-Italic",
//     "STIXTwoText-Italic_Medium-Italic",
//     "STIXTwoText-Italic_SemiBold-Italic",
//     "STIXTwoText_Bold",
//     "STIXTwoText_Medium",
//     "STIXTwoText_SemiBold",
//     "Symbol",
//     "Tamil Sangam MN",
//     "TamilSangamMN",
//     "TamilSangamMN-Bold",
//     "Thonburi",
//     "Thonburi-Bold",
//     "Thonburi-Light",
//     "Times New Roman",
//     "TimesNewRomanPS-BoldItalicMT",
//     "TimesNewRomanPS-BoldMT",
//     "TimesNewRomanPS-ItalicMT",
//     "TimesNewRomanPSMT",
//     "Trebuchet MS",
//     "Trebuchet-BoldItalic",
//     "TrebuchetMS",
//     "TrebuchetMS-Bold",
//     "TrebuchetMS-Italic",
//     "Verdana",
//     "Verdana-Bold",
//     "Verdana-BoldItalic",
//     "Verdana-Italic",
//     "Zapf Dingbats",
//     "ZapfDingbatsITC",
//     "Zapfino"
// ],
//     "isHeadless":false,
//     "manifest":{
//     "slug":"GSZhiZuo",
//         "splash":{
//         "image":"./src/Resources/Images/app_splash.png",
//             "resizeMode":"cover",
//             "backgroundColor":"#FFFFFF"
//     },
//     "assetBundlePatterns":[
//         "**/*"
//     ],
//         "platforms":[
//         "ios",
//         "android",
//         "web"
//     ],
//         "sdkVersion":"47.0.0",
//         "currentFullName":"@anonymous/GSZhiZuo",
//         "originalFullName":"@anonymous/GSZhiZuo",
//         "version":"1.0.0",
//         "name":"GSZhiZuo",
//         "icon":"./src/Resources/Images/app_icon.png",
//         "logUrl":"http://localhost:8081/logs"
// },
//     "platform":{
//     "ios":{
//         "buildNumber":"1",
//             "userInterfaceIdiom":"handset",
//             "systemVersion":"16.2",
//             "platform":"x86_64"
//     }
// },
//     "appOwnership":null,
//     "manifest2":null,
//     "expoConfig":{
//     "slug":"GSZhiZuo",
//         "splash":{
//         "image":"./src/Resources/Images/app_splash.png",
//             "resizeMode":"cover",
//             "backgroundColor":"#FFFFFF"
//     },
//     "assetBundlePatterns":[
//         "**/*"
//     ],
//         "platforms":[
//         "ios",
//         "android",
//         "web"
//     ],
//         "sdkVersion":"47.0.0",
//         "currentFullName":"@anonymous/GSZhiZuo",
//         "originalFullName":"@anonymous/GSZhiZuo",
//         "version":"1.0.0",
//         "name":"GSZhiZuo",
//         "icon":"./src/Resources/Images/app_icon.png",
//         "logUrl":"http://localhost:8081/logs"
// }
// }
