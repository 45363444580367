/**
 * GSZhiZuo
 * GSBoxBgWebPage.js
 *
 * @author: zengzhiming
 * @date:   2023/7/5
 * @desc:   GSBoxBgWebPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import { WebView } from 'expo-react-native-webview';


class GSBoxBgWebPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }


    render() {

        const {WebUrl} = this.props.route.params || {};

        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <WebView
                            style={{flex: 1,margin:-2,padding:2}}
                            source={{uri: WebUrl}}
                            onLoadStart={()=>{showLoading()}}
                            onLoadEnd={()=>{closeLoading()}}
                        />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getStyles() {

        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                overflow:"hidden",
                backgroundColor: Colors.theme_FFFFFF,
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSBoxBgWebPage)


// export default GSBoxBgWebPage;
