/**
 * GSZhiZuo
 * GSBoxBgSharePage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/26
 * @desc:   GSBoxBgSharePage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
    ScrollView,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import QRCodeSvg from 'react-native-qrcode-svg';
import {saveLocalUriImage} from "../../Common/Utils/GSMediaLibrary";
import {captureViewAsync} from "../../Common/Utils/GSCaptureImage";
import {isWXAppInstalled, registerApp, shareText} from  "../../Common/Utils/GSWeChatShare";
import {plasticBoxBgClearEditBoxListAction} from "../../Common/Redux/GSReduxAction";


class GSBoxBgSharePage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        const {imgInfo, images, boxData} = props.route.params || {};
        // log(Object.keys(images));
        // log(imgInfo, boxData);

        const imgDir = "https://www.gsgoldprice.com/picmake/Model/images/"+imgInfo["directory"];
        const downUrl = "https://www.gsgoldprice.com/picmake/Model/index.aspx?fileDirectory="+imgInfo["directory"];
        // const imgDir = "https://www.gsgoldprice.com/picmake/Model/images/03_92_134_fb97023c603691e21bb405390dfb2cb6/";

        log("==downUrl=>", downUrl);

        this.state = {
            bgImg:images["image1"],
            qrcodeImg:images["image3"],
            imgDir:imgDir,
            downUrl:downUrl,
            boxData:boxData,
            isWeChat:false,
        };

        this.initNavi();
    }

    initNavi = () => {
        // 设置返回按钮
        this.setBackTitle("首页");
        this.setBackPress(()=>{
            this.navigate(SceneKey.BoxBgSelect)
        });
    }

    componentDidMount() {
        // registerApp().then((res)=>{
        //     // 判断微信是否安装
        //     this.isWeChatInstalled();
        // }).catch((res)=>{
        //     // log(-1,res)
        // })
    }

    // 判断微信是否安装
    isWeChatInstalled = () => {
        isWXAppInstalled().then((isInstalled) => {
            if (isInstalled) {
                this.setState({
                    isWeChat:true
                });
            }
        })
    };

    onSavePress = async () => {

        // 处理连续点击
        if (isDoublePress()) return;

        const image = await captureViewAsync(this.imgView, null);
        saveLocalUriImage(image);

        showToast(T("保存成功"));
    }

    onBackPress = () => {
        this.push(SceneKey.Home)
    }

    onDownPress = () => {
        const downUrl = this.state["downUrl"];
        this.push(SceneKey.BoxBgWeb, {"WebUrl":downUrl})
        // const imgDir = this.state["imgDir"];
        // this.push(SceneKey.BoxBgWeb, {"WebUrl":imgDir})
    }

    onSharePress = async () => {

        // 处理连续点击
        if (isDoublePress()) return;

        // 分享地址
        const downUrl = this.state["downUrl"];
        shareText({
            text: downUrl,
            scene: 0,
        }) .catch((err)=>{
            showToast(T("分享失败"), err.message);
        });

        // // 分享地址
        // const downUrl = this.state["downUrl"];
        // shareToSession({
        //     type: 'text',
        //     description: downUrl,
        // }) .catch((err)=>{
        //     showToast(T("分享失败"), err.message);
        // });

        // const imgUri = await captureViewAsync(this.imgView, null);
        // // 微信分享图片
        // shareToSession({
        //     type: 'imageFile',
        //     imageUrl: "file://" + imgUri,
        // }) .catch((err)=>{
        //     showToast(T("分享失败"), err.message);
        // });
    }

    render() {

        const {bgImg, qrcodeImg, imgDir, boxData, isWeChat} = this.state;
        const styles = this.getStyles();

        const aspectRatio = parseFloat(boxData["Length"])/parseFloat(boxData["Height"]);

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <ScrollView  style={{flex: 1}}>
                        <View style={styles.container}>
                            <View style={styles.content_view}>
                                <View ref={(ref)=>{this.imgView = ref}} style={styles.content_poster_view}>
                                    <View style={styles.content_poster_title_view}>
                                        <Text style={styles.content_poster_title_text}>{T("金星制作")}</Text>
                                    </View>
                                    <View style={styles.content_poster_img_view}>
                                        <View style={{width:"100%",aspectRatio:aspectRatio,}}>
                                            <Image style={styles.content_poster_img} resizeMode={"contain"} source={{uri:bgImg}} />
                                        </View>
                                        <View style={styles.content_poster_qrimg_view}>
                                            <Image style={styles.content_poster_img} resizeMode={"contain"} source={{uri:qrcodeImg}} />
                                        </View>
                                    </View>
                                    <View style={styles.content_bottom_view}>
                                        <Text style={styles.content_bottom_title}>{T("长按获取分享图片链接")}</Text>
                                        <QRCodeSvg
                                            size={60}
                                            value={imgDir}
                                        />
                                    </View>
                                </View>
                                <View style={styles.content_button_view}>
                                    <TouchableOpacity onPress={this.onSavePress} style={styles.content_button_btn}>
                                        <Text style={styles.content_button_btn_txt}>{T("保存图片")}</Text>
                                    </TouchableOpacity>
                                    {
                                        isWeChat &&
                                        <TouchableOpacity  onPress={this.onSharePress}  style={styles.content_button_btn}>
                                            <Text style={styles.content_button_btn_txt}>{T("分享图片")}</Text>
                                        </TouchableOpacity>
                                    }
                                    {
                                        isWeb &&
                                        <TouchableOpacity  onPress={this.onDownPress}  style={styles.content_button_btn}>
                                            <Text style={styles.content_button_btn_txt}>{T("下载图片")}</Text>
                                        </TouchableOpacity>
                                    }
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                </SafeAreaView>
            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                minHeight: screenHeight-navBarHeight,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: Colors.theme_FFFFFF,
            },

            content_view:{
                width:"100%",
                paddingHorizontal:30,
            },

            content_poster_view:{
                width:"100%",
                backgroundColor:Colors.boxbg_share_FE5C57_s,
                borderRadius:18,
                paddingHorizontal:12,
            },

            content_poster_img:{
                width:"100%",
                height:"100%"
            },
            content_poster_title_view:{
                alignItems:"center",
            },

            content_poster_title_text:{
                paddingVertical:5,
                paddingHorizontal:10,
                marginVertical:5,
                color:Colors.boxbg_share_FFFAEE_s,
                fontSize:21,
            },

            content_poster_img_view:{
                width:"100%",
                backgroundColor:Colors.boxbg_share_FFFAEE_s,
                borderRadius:8,
                borderWidth:5,
                borderColor:Colors.boxbg_share_FFDE97_s,
                paddingVertical:8,
                paddingHorizontal:18,
            },

            content_poster_qrimg_view:{
                width:"100%",
                height:100,
                marginTop:16,
            },

            content_bottom_view:{
                flexDirection:"row",
                justifyContent:"space-between",
                alignItems:"center",
                margin:10,
            },

            content_bottom_title:{
                fontSize:18,
                color:Colors.theme_FFFFFF_s,
            },

            content_button_view:{
                flexDirection:"row",
                justifyContent:"center",
                marginTop:22,
            },

            content_button_btn:{
                flex:1,
                backgroundColor:Colors.theme_first_s,
                height:40,
                justifyContent:"center",
                borderRadius:20,
                overflow:"hidden",
                marginHorizontal:10,
                alignItems:"center",
            },

            content_button_btn_txt:{
                fontSize:20,
                color:Colors.theme_000000_s,
            },
        });
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSBoxBgSharePage)


// export default GSBoxBgSharePage;
