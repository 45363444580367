/**
 * GSZhiZuo
 * GSNavigation.js
 *
 * @author: zengzhiming
 * @date:   2022/5/20 12:39
 * @desc:   GSNavigation
 **/
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { NavigationContainer, DarkTheme, DefaultTheme } from "@react-navigation/native";
import { createStackNavigator } from '@react-navigation/stack';
// import {HeaderBackButton} from '@react-navigation/elements';


import Brand from '../Main/GoldBar/GSBrandPage'
import Home from '../Main/GoldBar/GSHomePage'
import GoldEdit from '../Main/GoldBar/GSGoldEditPage'
import GoldAddText from "../Main/GoldBar/GSGoldAddTextPage";
import GoldPreview from "../Main/GoldBar/GSGoldPreviewPage";

import Template from "../Main/PlasticBox/GSTemplatePage";
import TempWeb from "../Main/PlasticBox/GSTempWebPage";
import Progress from "../Main/PlasticBox/GSProgressPage";

import BoxList from "../Main/PlasticBox/GSBoxListPage";
import BoxEdit from "../Main/PlasticBox/GSBoxEditPage";
import BoxAddText from "../Main/PlasticBox/GSBoxAddTextPage";
import BoxUserInfo from "../Main/PlasticBox/GSBoxUserInfoPage";

import BoxBgSelect from "../Main/PlasticBoxBg/GSBoxBgSelectPage";
import BoxBgSizeSelect from "../Main/PlasticBoxBg/GSBoxBgSizeSelectPage";
import BoxBgEdit from "../Main/PlasticBoxBg/GSBoxBgEditPage";
import BoxBgAddText from "../Main/PlasticBoxBg/GSBoxBgAddTextPage";
import BoxBgUserInfo from "../Main/PlasticBoxBg/GSBoxBgUserInfoPage";
import BoxBgPreview from "../Main/PlasticBoxBg/GSBoxBgPreviewPage";
import BoxBgShare from "../Main/PlasticBoxBg/GSBoxBgSharePage";
import BoxBgWeb from "../Main/PlasticBoxBg/GSBoxBgWebPage";


import MainTest from '../Main/GSTestPage'

import Test from '../Test/GSTestPage'




// SceneKey
export const SceneKey = {

    Home:'Home',
    Brand:'Brand',
    GoldEdit:'GoldEdit',
    GoldAddText:'GoldAddText',
    GoldPreview:'GoldPreview',


    Template:'Template',
    TempWeb:'TempWeb',
    Progress:'Progress',
    BoxList:'BoxList',
    BoxEdit:'BoxEdit',
    BoxAddText:'BoxAddText',
    BoxUserInfo:'BoxUserInfo',

    BoxBgSelect:'BoxBgSelect',
    BoxBgSizeSelect:'BoxBgSizeSelect',
    BoxBgEdit:'BoxBgEdit',
    BoxBgAddText:'BoxBgAddText',
    BoxBgUserInfo:'BoxBgUserInfo',
    BoxBgPreview:'BoxBgPreview',
    BoxBgShare:'BoxBgShare',
    BoxBgWeb:'BoxBgWeb',


    MainTest:'MainTest',
    Test:'Test',
};



export function GSNavigation(state) {

    const Stack = createStackNavigator();
    const theme = state["theme"] === ThemeType.DARK ? DarkTheme : DefaultTheme
    const {Colors} = Theme;

    return (
        <NavigationContainer theme={theme}>
            <Stack.Navigator
                initialRouteName={SceneKey.Brand}
                screenOptions={({navigation})=>({
                    headerTitleAlign:"center",
                    animation: "slide_from_right", // 划入方式
                    headerShown: true, // 不要隐藏标题
                    headerBackTitle:T("返回"),
                    headerTintColor:Colors.theme_000000_s,
                    headerStyle:{backgroundColor:Colors.theme_first_s},
                })}
            >

                <Stack.Screen
                    name={SceneKey.Brand}
                    component={Brand}
                    options={{
                        title: T("品牌选择"),
                        headerLeft:null, // 隐藏返回按钮
                    }}
                />

                <Stack.Screen
                    name={SceneKey.Home}
                    component={Home}
                    options={{
                        title: T("金星制作"),
                        headerLeft:null, // 隐藏返回按钮
                    }}
                />

                <Stack.Screen
                    name={SceneKey.GoldEdit}
                    component={GoldEdit}
                    options={{
                        headerShown: false,
                    }}
                />

                <Stack.Screen
                    name={SceneKey.GoldAddText}
                    component={GoldAddText}
                    options={{
                        headerShown: false,
                    }}
                />

                <Stack.Screen
                    name={SceneKey.GoldPreview}
                    component={GoldPreview}
                    options={{
                        headerShown: false,
                    }}
                />

                <Stack.Screen
                    name={SceneKey.Progress}
                    component={Progress}
                    options={{
                        title: T("进度查询"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.Template}
                    component={Template}
                    options={{
                        title: T("推荐模板"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.TempWeb}
                    component={TempWeb}
                    options={{
                        title: T("推荐模板"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BoxList}
                    component={BoxList}
                    options={{
                        title: T("透明胶盖选择"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BoxEdit}
                    component={BoxEdit}
                    options={{
                        title: T("暂存"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BoxAddText}
                    component={BoxAddText}
                    options={{
                        headerShown: false,
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BoxUserInfo}
                    component={BoxUserInfo}
                    options={{
                        title: T("联络信息"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BoxBgSelect}
                    component={BoxBgSelect}
                    options={{
                        title: T("填写资料"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BoxBgSizeSelect}
                    component={BoxBgSizeSelect}
                    options={{
                        title: T("尺寸选择"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BoxBgEdit}
                    component={BoxBgEdit}
                    options={{
                        title: T("暂存"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BoxBgAddText}
                    component={BoxBgAddText}
                    options={{
                        headerShown: false,
                    }}
                />


                <Stack.Screen
                    name={SceneKey.BoxBgPreview}
                    component={BoxBgPreview}
                    options={{
                        title: T("图片预览"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BoxBgUserInfo}
                    component={BoxBgUserInfo}
                    options={{
                        title: T("联络信息"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BoxBgShare}
                    component={BoxBgShare}
                    options={{
                        title: T("提交完成"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BoxBgWeb}
                    component={BoxBgWeb}
                    options={{
                        title: T("下载图片"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.MainTest}
                    component={MainTest}
                    options={{
                        title: T("测试"),
                        headerShown: false,
                        headerLeft:null, // 隐藏返回按钮
                    }}
                />

                <Stack.Screen
                    name={SceneKey.Test}
                    component={Test}
                    options={{
                        title: T("测试"),
                    }}
                />

            </Stack.Navigator>

        </NavigationContainer>
    );
}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSNavigation)

