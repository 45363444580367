/**
 * GSZhiZuo
 * GSTheme.js
 *
 * @author: zengzhiming
 * @date:   2023/3/8
 * @desc:   GSTheme
 **/
'use strict';

import {Appearance} from 'react-native';
import {addShare, ShareKey} from "../Storage/GSShare";
import {themeChangeAction} from "../Redux/GSReduxAction";
import {ColorsLight, ColorsDark} from "../../Resources/Colors";
import {ImagesLight, ImagesDark} from "../../Resources/Images";


// 主题类型
const ThemeType = {
    LIGHT:      'light',  // 浅色
    DARK:       'dark',   // 深色
};

const colorsLight = Object.assign({},ColorsDark, ColorsLight);
const colorsDark = Object.assign({},ColorsLight, ColorsDark);
const imagesLight = Object.assign({},ImagesDark, ImagesLight);
const imagesDark = Object.assign({},ImagesLight, ImagesDark);


let currTheme = "";// Appearance.getColorScheme() === ThemeType.DARK ? ThemeType.DARK : ThemeType.LIGHT;
let Images =  {};//currTheme === ThemeType.DARK ? imagesDark : imagesLight;
let Colors =  {};//currTheme === ThemeType.DARK ? colorsDark : colorsLight;

// 添加主题改变监听
Appearance.addChangeListener((theme)=>{
    // log("====>addChangeListener:",theme)
    const newTheme = theme.colorScheme === ThemeType.DARK ? ThemeType.DARK : ThemeType.LIGHT;
    changeTheme(newTheme);
});


const initTheme = () => {
    const newTheme = Appearance.getColorScheme();

    changeTheme(newTheme);
}

// 修改主题
const changeTheme = (newTheme) => {
    currTheme = newTheme;
    Images =  currTheme === ThemeType.DARK ? imagesDark : imagesLight;
    Colors =  currTheme === ThemeType.DARK ? colorsDark : colorsLight;
    addShare(ShareKey.THEME, currTheme);

    themeChangeAction(currTheme);
};

export {ThemeType, currTheme, initTheme, changeTheme, Images, Colors};





