/**
 * GSZhiZuo
 * GSGoldEditPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/2
 * @desc:   GSGoldEditPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
    Animated,
} from 'react-native';
import {State, PanGestureHandler, PinchGestureHandler, RotationGestureHandler, TapGestureHandler} from "react-native-gesture-handler";
import {
    goldBarClearEditBoxListAction,
    goldBarAddEditBoxListAction,
    goldBarUpdatedEditButtonAction,
    goldBarDeselectEditBoxAction,
    goldBarOpenPreviewAction,
    goldBarClosePreviewAction,
    AnimatedDict,
} from "../../Common/Redux/GSReduxAction";
import {BoxModel} from "../../Common/Utils/GSDefined";
import {captureView} from "../../Common/Utils/GSCaptureImage";
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import GSGoldEditImageView from './View/GSGoldEditImageView'
import GSGoldEditTextView from './View/GSGoldEditTextView'
import GSImageToolView from './View/GSImageToolView'
import GSTextToolView from './View/GSTextToolView'

class GSGoldEditPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            imgLoad : false
        };

        // 获取编辑数据
        const { CardList, Width, Length} = props.route.params?.editData || {};

        // 计算图片区域宽高
        this.imgWidth = screenWidth;
        this.imgHeight = screenWidth*(Length/Width);
        const scale = this.imgWidth/Width;

        // 添加编辑框
        let editBoxList = [];
        CardList.map((locInfo, index)=>{
            const {W, H, X, Y, Type} = locInfo || {};
            let editBox = cloneDeep(BoxModel);
            editBox.x = X * scale;
            editBox.y = Y * scale;
            editBox.width = W * scale;
            editBox.height = H * scale;
            editBox.Type = Type;
            editBox.BtnList = [];
            editBoxList.push(editBox);
        });
        goldBarAddEditBoxListAction(editBoxList);
    }

    // 点击取消
    onCancelPress = () => {
        this.goBack();
        goldBarClearEditBoxListAction();
    };



    // 点击预览
    onPreviewPress = () => {
        // 开启预览模式
        goldBarOpenPreviewAction();
        showLoading();
        requestAnimationFrame(()=>{
            // 截取图片
            captureView(this.shotView, null,(code, captureResult)=>{
                closeLoading();
                if (code === 1) {
                    const editData = this.props.route.params?.editData;
                    this.props.navigation.push(SceneKey.GoldPreview,{
                        imgUri:captureResult,
                        editData:editData,
                    });
                } else {
                    // 截图失败
                    showAlert(T("保存图片失败"),captureResult.message,  [T("确定")], ()=>{ });
                }
                // 关闭预览模式
                goldBarClosePreviewAction();
            });
        });
    };




    /*===================手势监听Start====================*/

    // 旋转状态改变
    onRotateHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onRotateHandlerStateChange:',event.nativeEvent);
            const {currBoxIndex, currBtnIndex, editBoxList} = this.props || {};
            if(currBoxIndex < 0 || currBtnIndex < 0){
                return;
            }
            let currEditBtn = {...editBoxList[currBoxIndex]['BtnList'][currBtnIndex]};
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            currEditBtn.lastRotate += event.nativeEvent.rotation;
            currEditBtnAnimated.rotate.setOffset(currEditBtn.lastRotate);
            currEditBtnAnimated.rotate.setValue(0);

            goldBarUpdatedEditButtonAction(currEditBtn);
        }
    };


    // 捏合状态改变
    onPinchHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onPinchHandlerStateChange:',event.nativeEvent);
            const {currBoxIndex, currBtnIndex, editBoxList} = this.props || {};
            if(currBoxIndex < 0 || currBtnIndex < 0){
                return;
            }
            let currEditBtn = {...editBoxList[currBoxIndex]['BtnList'][currBtnIndex]};
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            let sizeScale = currEditBtn.sizeScale;
            sizeScale *= event.nativeEvent.scale;
            // sizeScale = parseInt(sizeScale);
            currEditBtn.sizeScale = sizeScale;
            currEditBtnAnimated.baseScale.setValue(sizeScale);
            currEditBtnAnimated.pinchScale.setValue(1);

            goldBarUpdatedEditButtonAction(currEditBtn);
        }
    };

    // 平移状态改变
    onPanHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onPanHandlerStateChange:',event.nativeEvent);

            const {currBoxIndex, currBtnIndex, editBoxList} = this.props || {};
            if(currBoxIndex < 0 || currBtnIndex < 0){
                return;
            }
            let currEditBtn = {...editBoxList[currBoxIndex]['BtnList'][currBtnIndex]};
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            let lastOffsetX = currEditBtn.lastOffset.x + event.nativeEvent.translationX;
            let lastOffsetY = currEditBtn.lastOffset.y + event.nativeEvent.translationY;

            currEditBtn.lastOffset = {x:lastOffsetX, y:lastOffsetY};
            currEditBtnAnimated.offsetValue.setOffset({x:lastOffsetX, y:lastOffsetY});
            currEditBtnAnimated.offsetValue.setValue({x:0,y:0});

            goldBarUpdatedEditButtonAction(currEditBtn);
        }
    };

    // 点击空白区域
    // contentLastTime = 0;
    onContentTapHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onContentTapHandlerStateChange:',event.nativeEvent);

            // 取消选中
            goldBarDeselectEditBoxAction();
        }
    };

    /*===================手势监听End====================*/



    render() {

        const {ImgPng} = this.props.route.params?.editData || {};

        let emptyEvent = Animated.event([{ nativeEvent: { } }],{useNativeDriver: false});

        const {imgLoad} = this.state || {};
        const {currBoxIndex, currBtnIndex, editBoxList} = this.props || {};
        let deselect = currBoxIndex < 0 || currBtnIndex < 0 ;
        let currEditBtn = deselect ? undefined : editBoxList[currBoxIndex]['BtnList'][currBtnIndex];
        let currEditBtnAnimated = deselect ? undefined : AnimatedDict[currEditBtn.animatedId];

        const {Colors} = Theme;
        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View style={styles.header_navigation}>
                            <TouchableOpacity onPress={this.onCancelPress}>
                                <Text style={styles.header_navigation_text} >{T("取消")}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={this.onPreviewPress}>
                                <Text style={styles.header_navigation_text}>{T("预览")}</Text>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.edit_comp_view}>

                            <TapGestureHandler onHandlerStateChange={this.onContentTapHandlerStateChange}>
                                <PanGestureHandler
                                    onGestureEvent={ deselect ? emptyEvent : Animated.event([{ nativeEvent: {translationX: currEditBtnAnimated.offsetValue.x, translationY: currEditBtnAnimated.offsetValue.y}}],{useNativeDriver: false})}
                                    onHandlerStateChange={deselect ? ()=>{} : this.onPanHandlerStateChange}
                                    minDist={20}
                                >
                                    <Animated.View style={styles.edit_comp_view}>
                                        <RotationGestureHandler
                                            // onGestureEvent={deselect ? emptyEvent :Animated.event([{ nativeEvent: {rotation: currEditBtnAnimated.rotate} }],{useNativeDriver: false})}
                                            // onHandlerStateChange={deselect ? ()=>{} : this.onRotateHandlerStateChange}
                                        >
                                            <Animated.View style={styles.edit_comp_view}>
                                                <PinchGestureHandler
                                                    onGestureEvent={deselect ? emptyEvent :Animated.event([{ nativeEvent: {scale: currEditBtnAnimated.pinchScale}}],{useNativeDriver: false})}
                                                    onHandlerStateChange={deselect ? ()=>{} : this.onPinchHandlerStateChange}
                                                >
                                                    <Animated.View style={styles.edit_comp_view}>

                                                        <View ref={(ref)=>{this.shotView = ref}}
                                                              style={{
                                                                  width:this.imgWidth,
                                                                  height: this.imgHeight,
                                                                  color:Colors.theme_FFFFFF_s,
                                                                  marginBottom:150,
                                                              }} >
                                                            {   // 加载图片编辑区
                                                                imgLoad &&
                                                                editBoxList.map((boxData, index)=>{
                                                                    if (boxData['Type'] === 'image') {
                                                                        return <GSGoldEditImageView key={index} boxData={boxData} boxIndex={index} />
                                                                    }
                                                                })
                                                            }
                                                            <View pointerEvents='none'>
                                                                <Image onLoadStart={(e)=>{
                                                                            if (!this.state["imgLoad"]) {
                                                                                showLoading(T("加载图片..."))
                                                                            }
                                                                        }}
                                                                       onLoadEnd={(e)=>{
                                                                           if (!this.state["imgLoad"]) {
                                                                               closeLoading();
                                                                               this.setState({
                                                                                   imgLoad: true
                                                                               });
                                                                           }
                                                                       }}
                                                                       source={{uri:ImgPng}}
                                                                       style={{
                                                                           position: 'absolute',
                                                                           width:this.imgWidth,
                                                                           height: this.imgHeight,
                                                                       }}
                                                                />
                                                            </View>
                                                            {   // 加载文本编辑区
                                                                imgLoad &&
                                                                editBoxList.map((boxData, index)=>{
                                                                    if (boxData['Type'] === 'text') {
                                                                        return <GSGoldEditTextView key={index} boxData={boxData} boxIndex={index} navigation={this.props.navigation} />
                                                                    }
                                                                })
                                                            }
                                                        </View>

                                                    </Animated.View>
                                                </PinchGestureHandler>
                                            </Animated.View>
                                        </RotationGestureHandler>
                                    </Animated.View>
                                </PanGestureHandler>
                            </TapGestureHandler>
                        </View>

                        <GSTextToolView navigation={this.props.navigation} />
                        <GSImageToolView navigation={this.props.navigation} />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_000000_s,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_000000_s,
            },
            edit_comp_view:{
                flex: 1,
                justifyContent:'center',
                alignItems:'center',
            },
            header_navigation:{
                height:44,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems:'center',
            },
            header_navigation_text:{
                color:Colors.theme_FFFFFF_s,
                paddingTop:5,
                paddingBottom:5,
                paddingRight:16,
                paddingLeft:16,
                fontSize:18,
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
            editBoxList:state.goldBar.editBoxList,
            currBoxIndex:state.goldBar.currBoxIndex,
            currBtnIndex:state.goldBar.currBtnIndex,
        })
    }
)(GSGoldEditPage)


// export default GSGoldEditPage;