/**
 * GSZhiZuo
 * GSGoldEditTextView.js
 *
 * @author: zengzhiming
 * @date:   2023/6/6
 * @desc:   GSGoldEditTextView
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    StyleSheet,
    Text,
    View,
    Animated,
} from 'react-native';
import BasePage from "../../../Common/Component/BaseComponent/GSBasePage";
import {
    goldBarAddEditButtonAction,
    goldBarSelectedEditButtonAction,
    goldBarSelectEditBoxAction,
    goldBarUpdatedEditButtonAction,
    AnimatedDict,
} from "../../../Common/Redux/GSReduxAction";
import {TextModel, minFont, maxFont} from "../../../Common/Utils/GSDefined";
import {State, TapGestureHandler} from "react-native-gesture-handler";

class GSGoldEditTextView extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        // 计算控件位置和宽高
        const {width, height} = props.boxData || {};

        // 设置默认位置
        TextModel.lastOffset = {x:width*0.001 ,y:height*0.2};

    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // 校验大小和偏移量
        const {boxIndex, currBtnIndex} = this.props || {};
        if (nextProps.currBoxIndex === boxIndex && nextProps.currBtnIndex === currBtnIndex) {
            const currEditBtn =  nextProps.editBoxList[boxIndex]['BtnList'][currBtnIndex];
            this.checkMoveOffset(currEditBtn);
            this.checkFontSize(currEditBtn);
        }

        return true;
    }




    // 校验字体大小
    checkFontSize = (editBtn) => {

        if(isEmptyObject(editBtn)){
            return;
        }

        let currEditBtn = {...editBtn};
        let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];

        let sizeScale = currEditBtn.sizeScale;
        sizeScale = parseInt(sizeScale);
        if (sizeScale < minFont) sizeScale = minFont;
        if (sizeScale > maxFont) sizeScale = maxFont;

        if (currEditBtn.sizeScale !== sizeScale) {
            currEditBtn.sizeScale = sizeScale;
            currEditBtnAnimated.baseScale.setValue(sizeScale);
            currEditBtnAnimated.pinchScale.setValue(1);
            goldBarUpdatedEditButtonAction(currEditBtn);
        }

    };


    // 校验移动偏移
    checkMoveOffset = (editBtn) => {

        if(isEmptyObject(editBtn)){
            return;
        }

        let currEditBtn = {...editBtn};
        let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];

        const { height, width } = this.props["boxData"] || {};
        let lastOffsetX = currEditBtn.lastOffset.x;
        let lastOffsetY = currEditBtn.lastOffset.y;

        // 判断是否移出框外
        Frame(currEditBtnAnimated.ref, (frame) => {

            let maxOffsetX = width - frame.width;
            if (lastOffsetX > maxOffsetX) {
                lastOffsetX = maxOffsetX;
            }

            let maxOffsetY = height - frame.height;
            if (lastOffsetY > maxOffsetY) {
                lastOffsetY = maxOffsetY;
            }

            let minOffsetX = 0;
            if (lastOffsetX < minOffsetX) {
                lastOffsetX = minOffsetX;
            }

            let minOffsetY = 0;
            if (lastOffsetY < minOffsetY) {
                lastOffsetY = minOffsetY;
            }

            if (currEditBtn.lastOffset.x !== lastOffsetX || currEditBtn.lastOffset.y !== lastOffsetY) {
                currEditBtn.lastOffset = {x:lastOffsetX, y:lastOffsetY};
                currEditBtnAnimated.offsetValue.setOffset({x:lastOffsetX, y:lastOffsetY});
                currEditBtnAnimated.offsetValue.setValue({x:0,y:0});
                goldBarUpdatedEditButtonAction(currEditBtn);
            }

        });
    };


    // 点击编辑按钮
    onEditButtonTapHandlerStateChange = (event, btnIndex) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onEditButtonTapHandlerStateChange:',event.nativeEvent);

            const {currBoxIndex, currBtnIndex, boxIndex, editBoxList} = this.props || {};
            if(currBoxIndex === boxIndex && currBtnIndex === btnIndex){
                // 判断双击
                if (isDoublePress(2, 1000)) {
                    // 修改文本
                    const currEditBtn =  editBoxList[boxIndex]['BtnList'][currBtnIndex];
                    this.props["navigation"].push(SceneKey.GoldAddText,{currEditBtn:currEditBtn});
                }
            } else {
                // 选中对象
                goldBarSelectedEditButtonAction(boxIndex, btnIndex);
            }
        }

    };


    // 点击空白区域
    onEditBoxTapHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onEditBoxTapHandlerStateChange:',event.nativeEvent);
            
            const {currBoxIndex, currBtnIndex, boxIndex} = this.props || {};
            if(currBoxIndex === boxIndex && currBtnIndex < 0){
                // 判断双击
                if (isDoublePress(3, 1000)) {
                    // 添加文本
                    this.props["navigation"].push(SceneKey.GoldAddText);
                    // let test1= cloneDeep(TextModel);
                    // test1.textValue = '测试文本1';
                    // goldBarAddEditButtonAction(test1);
                }
            }else {
                // 选中编辑区
                goldBarSelectEditBoxAction(boxIndex);
            }
        }
    };

    // 创建文本View
    createEditButtonView = (editButtonList) => {

        const {Colors} = Theme;

        let textViewArray = [];
        const {currBoxIndex, currBtnIndex, boxIndex} = this.props || {};
        editButtonList.map((currEditBtn, btnIndex) => {
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            const selected = currBoxIndex === boxIndex && currBtnIndex === btnIndex;
            const borderColor = selected ? Colors.goldbar_edit_EB4E00_s : Colors.theme_00000000_s;
            textViewArray.push(
                <Animated.View
                    key={btnIndex}
                    ref={(ref)=>{
                        currEditBtnAnimated.ref=ref
                    }}
                    style={{
                        position:'absolute',
                        left:currEditBtnAnimated.offsetValue.x,
                        top:currEditBtnAnimated.offsetValue.y,
                        borderWidth: 1,
                        borderColor:borderColor,
                        padding:3,
                        transform: [
                            { rotate: currEditBtnAnimated.rotate.interpolate({inputRange: [-100, 100], outputRange: ['-100rad', '100rad']}) },
                        ],
                    }}>
                    <TapGestureHandler onHandlerStateChange={(event)=>{this.onEditButtonTapHandlerStateChange(event, btnIndex)}}>
                        <Animated.Text
                            style={{
                                fontSize: Animated.multiply(currEditBtnAnimated.baseScale, currEditBtnAnimated.pinchScale),
                                color:currEditBtn.color,
                                fontFamily:currEditBtn.fontFamily,
                                padding:0,
                                letterSpacing: 0,
                            }}
                        >
                            {currEditBtn.textValue}
                        </Animated.Text>
                    </TapGestureHandler>
                    <View style={{position:'absolute', width: 4, height:4, left:-2, top:-2, backgroundColor:borderColor}} />
                    <View style={{position:'absolute', width: 4, height:4, right:-2, top:-2, backgroundColor:borderColor}} />
                    <View style={{position:'absolute', width: 4, height:4, left:-2, bottom:-2, backgroundColor:borderColor}} />
                    <View style={{position:'absolute', width: 4, height:4, right:-2, bottom:-2, backgroundColor:borderColor}} />
                </Animated.View>
            )
        });
        return textViewArray;
    };


    // 渲染界面
    render() {

        const {Colors} = Theme;
        const styles = this.getStyles();

        const { x, y, height, width, BtnList } = this.props["boxData"] || {};
        const {currBoxIndex, boxIndex, isPreview} = this.props || {};
        const bgColor = isPreview ? Colors.theme_00000000_s : (currBoxIndex === boxIndex ? Colors.goldbar_edit_674D41_s : Colors.goldbar_edit_39393A_s);

        return (
            <View style={[styles.edit_content_view,{width:width, height:height, top:y, left:x, backgroundColor:bgColor}]}>
                <TapGestureHandler onHandlerStateChange={this.onEditBoxTapHandlerStateChange}>
                    <View style={{width:width, height:height}}>
                        {   !isEmptyObject(BtnList) ? this.createEditButtonView(BtnList) :
                            !isPreview &&
                            <View style={styles.edit_content_hint_view}>
                                <Text numberOfLines={1} style={styles.edit_content_hint_text}>{T("双击编辑文字")}</Text>
                            </View>
                        }
                    </View>
                </TapGestureHandler>
            </View>
        );
    }

    getStyles() {

        const {Colors} = Theme;

        return StyleSheet.create({
            edit_content_view:{
                overflow:'hidden',
                position: 'absolute',
            },
            edit_content_hint_view:{
                justifyContent:'center',
                alignItems:'center',
                position:'absolute',
                top:0,
                left:0,
                right:0,
                bottom:0
            },
            edit_content_hint_text:{
                fontSize:8,
                color: Colors.goldbar_edit_B4B4B4_s,
            }
        })
    }

}

// 样式


// Redux Connect
export default connect(
    (state) => ({
        language: state.language,
        theme: state.theme,
        isPreview:state.goldBar.isPreview,
        editBoxList:state.goldBar.editBoxList,
        currBoxIndex:state.goldBar.currBoxIndex,
        currBtnIndex:state.goldBar.currBtnIndex,
    })
)(GSGoldEditTextView)


// export default GSGoldEditTextView;