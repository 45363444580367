/**
 * GSZhiZuo
 * GSFrame.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:28
 * @desc:   GSFrame
 **/
'use strict';

import {Dimensions} from  'react-native';
// 获取屏幕高度
export const getScreenHeight = () => {
    return getScreenSize().screenHeight;
};


// 获取屏幕宽度
export const getScreenWidth = () => {
    return getScreenSize().screenWidth;
};


// 获取屏幕大小
export const getScreenSize = () => {
    const {width , height}  = Dimensions.get('window');
    let screenWidth = width < height ? width : height;
    let screenHeight = width > height ? width : height;
    return {screenWidth, screenHeight};
};

// 获取Frame
export const Frame = (ref, resultBlock) => {

    if (isEmptyObject(ref))  {
        return;
    }

    ref.measure((x, y, width, height, pageX, pageY) => {
        if(resultBlock)resultBlock({x, y, width, height, pageX, pageY});
    });
};

// 判断是否为平板
export const isTablet = () => {
    const {width, height} = Dimensions.get('window');
    return Math.min(width, height) >= 600;
}