/**
 * GSZhiZuo
 * GSHomePage.js
 *
 * @author: zengzhiming
 * @date:   2023/5/26
 * @desc:   GSHomePage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    FlatList,
    ImageBackground,
    Image,
    Linking,
} from 'react-native';
import {DrawerLayout} from 'react-native-gesture-handler';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import DrawerView from './View/GSDrawerView'
import RemindUpdate from "./View/GSRemindUpdateView";
import {getCheckVersion} from "../../Common/Http/GSHttpRequest";

class GSHomePage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            homeData: {},
        };
    }

    componentDidMount() {
        this.headerLeftMenu();
        this.checkAppVersion();
    }

    // 添加导航栏左侧菜单按钮
    headerLeftMenu(){
        const {Images} = Theme;
        const styles = this.getStyles();
        this.props.navigation.setOptions({
            headerLeft: (props) => {
                return (
                    <TouchableOpacity activeOpacity={0.8}  onPress={this.onMenuPress} style={[styles.menu_btn_box]}>
                        <Image source={Images.home_left_menu} style={{width:20, height:16}}/>
                    </TouchableOpacity>
                );
            },
        });
    }


    // 校验App版本
    checkAppVersion = () => {

        if (isWeb){
            return;
        }

        getCheckVersion((tips, updateType, downUrl)=>{

            // log(tips, updateType, downUrl);

            switch (updateType) {
                case 1: // 建议更新
                {
                    this.updateView.showUpdate(tips, T("更新"), ()=>{
                        this.updateView.hiddenUpdate();
                        Linking.openURL(downUrl).then(()=>{});
                    });
                    break;
                }
                case 2: // 强制更新
                {
                    showAlert(tips, "", [T("确定")], (code, title)=>{
                        Linking.openURL(downUrl).then(()=>{});
                    }, true);
                    break;
                }
            }
        }, (code, error)=>{
            log(code, error);
        });

    };

    // 菜单按钮点击
    onMenuPress = () =>{
        if (this.drawer.state.drawerOpened){
            this.drawer.closeDrawer();
        }else {
            this.drawer.openDrawer();
        }
    };


    // 点击侧边栏
    onDrawerPress = (type, data) => {
        if (this.drawer.state.drawerOpened){
            this.drawer.closeDrawer();
        }
        switch (type) {
            case 0: {
                const  title = data['TemplateName'] || T("金星制作");
                this.setTitle(title);
                // 选择列表
                this.setState({
                    homeData:data
                });
                break;
            }
            case 1: {
                // 点击胶箱制作
                this.push(SceneKey.BoxList)
                break;
            }
            case 2: {
                // 点击进度列表
                this.push(SceneKey.Progress)
                break;
            }
            case 3: {
                // 点击返回品牌选择
                this.navigate(SceneKey.Brand)
                break;
            }
            case 4: {
                // 点击推荐模板
                this.push(SceneKey.Template)
                break;
            }
            case 5: {
                // 膠箱背景製作
                this.push(SceneKey.BoxBgSelect)
                break;
            }
            default:{
                break;
            }
        }

    };


    // 点击Item
    onItemPress = (data, index) => {
        // showToast('点击：'+index);
        // 处理连续点击
        if (isDoublePress()) return;

        const ImgPng = data['ImgPng'];
        const {CardList, Length, Width} = this.state.homeData || {};
        let editData = {ImgPng, CardList, Length, Width};
        // log(editData);

        this.push(SceneKey.GoldEdit,{
            editData:editData,
        });
    };



    render() {

        const {homeData} = this.state || {};

        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <DrawerLayout ref={(ref) => {this.drawer = ref}}
                                  drawerWidth={screenWidth * 0.7}
                                  drawerType='front'
                                  renderNavigationView={()=>{return <DrawerView onPress={this.onDrawerPress} />}}
                    >
                        <View style={styles.container}>
                            {
                                <FlatList renderItem={({item, index}) => this.homeItem(index, item, homeData)}
                                          data={homeData['CardPosList']}
                                          keyExtractor={(item, index) => index+''}
                                          numColumns={1}
                                          showsVerticalScrollIndicator = {false}
                                          style={styles.flat_list}
                                />
                            }
                        </View>
                        <RemindUpdate myRef={(ref)=>{this.updateView = ref}} />
                    </DrawerLayout>
                </SafeAreaView>
            </View>
        );
    }

    homeItem(index, data, homeData) {

        const {Images} = Theme;
        const styles = this.getStyles();

        const itemWidth = screenWidth-20;
        const itemHeight = itemWidth*(homeData['Length']/homeData['Width']);
        const imgUri = isEmptyString(data['Img']) ? Images.main_def_img : {uri:data['Img']};

        return (
            <TouchableOpacity style={styles.item} onPress={()=>this.onItemPress(data, index)}>
                <ImageBackground source={imgUri} style={{width:itemWidth, height:itemHeight}}>
                    <View style={styles.item_mask}>
                        <Text style={styles.item_text}>{data['ImgName']}</Text>
                    </View>
                </ImageBackground>
            </TouchableOpacity>
        );
    }


    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            flat_list:{
                marginBottom: 10,
            },
            item:{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
            },
            item_mask:{
                width:'100%',
                height:'100%',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:Colors.goldbar_home_00000066_s,
            },
            item_text:{
                fontSize: 80,
                fontWeight:'bold',
                fontFamily:'Euphemia UCAS',
                color:Colors.theme_FFFFFF_s,
            },
            menu_btn_box: {
                height:'100%',
                flexDirection: 'row',
                alignItems:'center',
                justifyContent:'center',
                paddingLeft: 8,
                paddingRight: 8,
                marginLeft: 8,
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSHomePage)


// export default GSHomePage;