/**
 * GSZhiZuo
 * ReduxStore.js
 *
 * @author: zengzhiming
 * @date:   2021/10/14 16:12
 * @desc:   ReduxStore
 **/
'use strict';
import {createSlice, configureStore } from '@reduxjs/toolkit'

const reduxSlice = createSlice({
    name: 'ReduxSlice',
    initialState: {
        language: "",
        theme:"",
        specialChar:[],
        goldBar:{
            editBoxList:[],
            currBoxIndex:-1,
            currBtnIndex: -1,
            isPreview:false,
        },
        plasticBox:{
            editButtonList: [],
            currIndex: -1,
        },
        plasticBoxBg:{
            editButtonList: [],
            currIndex: -1,
        },
    },
    reducers: {
        languageChange: (state, action) => {
            state.language = action.payload;
        },

        themeChange: (state, action) => {
            state.theme = action.payload;
        },

        specialCharChange: (state, action) => {
            state.specialChar = action.payload;
        },


        goldBarChange:(state, action) => {
            state.goldBar = Object.assign({}, state.goldBar, action.payload);
        },

        goldBarAddEditButton:(state, action) => {

            const boxIndex = state.goldBar.currBoxIndex;
            const newEditBtn = action.payload;
            if (isEmptyObject(newEditBtn) || boxIndex < 0) {
                log("goldBarAddEditButton: 添加按钮失败，参数为空");
                return;
            }

            let editBoxList = state.goldBar.editBoxList;
            let btnList = editBoxList[boxIndex]['BtnList'];
            btnList.push(newEditBtn);
            editBoxList[boxIndex]['BtnList'] = btnList;

            const goldBarUpdate = {
                editBoxList:editBoxList,
                currBoxIndex:boxIndex,
                currBtnIndex: btnList.length-1,
            };

            state.goldBar = Object.assign({}, state.goldBar, goldBarUpdate);
        },

        goldBarUpdatedEditButton:(state, action) => {

            const boxIndex = state.goldBar.currBoxIndex;
            const btnIndex = state.goldBar.currBtnIndex;
            const newEditBtn = action.payload;
            if (isEmptyObject(newEditBtn) || boxIndex < 0 || btnIndex < 0) {
                log("goldBarUpdatedEditButton: 更新按钮失败，参数为空");
                return;
            }


            let editBoxList = state.goldBar.editBoxList;
            let btnList = editBoxList[boxIndex]['BtnList'];
            btnList[btnIndex] = newEditBtn;
            editBoxList[boxIndex]['BtnList'] = btnList;

            const goldBarUpdate = {
                editBoxList:editBoxList,
                currBoxIndex:boxIndex,
                currBtnIndex: btnIndex,
            };

            state.goldBar = Object.assign({}, state.goldBar, goldBarUpdate);
        },


        goldBarDeleteEditButton:(state, action) => {

            const boxIndex = state.goldBar.currBoxIndex;
            const btnIndex = state.goldBar.currBtnIndex;
            if (boxIndex < 0 || btnIndex < 0) {
                log("goldBarDeleteEditButton: 删除按钮失败，参数为空");
                return;
            }

            let editBoxList = state.goldBar.editBoxList;
            let btnList = editBoxList[boxIndex]['BtnList'];
            btnList.splice(btnIndex, 1);
            editBoxList[boxIndex]['BtnList'] = btnList;

            const goldBarUpdate = {
                editBoxList:editBoxList,
                currBoxIndex:boxIndex,
                currBtnIndex: -1,
            };

            state.goldBar = Object.assign({}, state.goldBar, goldBarUpdate);
        },


        plasticBoxChange:(state, action) => {
            state.plasticBox = Object.assign({}, state.plasticBox, action.payload);
        },



        plasticBoxAddEditButton:(state, action) => {

            const newEditBtn = action.payload;
            if (isEmptyObject(newEditBtn)) {
                log("plasticBoxAddEditButton: 添加按钮失败，参数为空");
                return;
            }

            let btnList = state.plasticBox.editButtonList;
            btnList.push(newEditBtn);
            const plasticBoxUpdate = {
                editButtonList: btnList,
                currIndex: btnList.length-1,
            };

            state.plasticBox = Object.assign({}, state.plasticBox, plasticBoxUpdate);
        },

        plasticBoxUpdatedEditButton:(state, action) => {

            const btnIndex = state.plasticBox.currIndex;
            const newEditBtn = action.payload;
            if (isEmptyObject(newEditBtn) || btnIndex < 0) {
                log("plasticBoxUpdatedEditButton: 更新按钮失败，参数为空");
                return;
            }


            let btnList = state.plasticBox.editButtonList;
            btnList[btnIndex] = newEditBtn;

            const plasticBoxUpdate = {
                editButtonList: btnList,
                currIndex: btnIndex,
            };

            state.plasticBox = Object.assign({}, state.plasticBox, plasticBoxUpdate);
        },


        plasticBoxDeleteEditButton:(state, action) => {

            const btnIndex = state.plasticBox.currIndex;
            if (btnIndex < 0) {
                log("plasticBoxDeleteEditButton: 删除按钮失败，参数为空");
                return;
            }

            let btnList = state.plasticBox.editButtonList;
            btnList.splice(btnIndex, 1);

            const plasticBoxUpdate = {
                editButtonList: btnList,
                currIndex: -1,
            };

            state.plasticBox = Object.assign({}, state.plasticBox, plasticBoxUpdate);
        },



        plasticBoxBgChange:(state, action) => {
            state.plasticBoxBg = Object.assign({}, state.plasticBoxBg, action.payload);
        },

        plasticBoxBgAddEditButton:(state, action) => {

            const newEditBtn = action.payload;
            if (isEmptyObject(newEditBtn)) {
                log("plasticBoxBgAddEditButton: 添加按钮失败，参数为空");
                return;
            }

            let btnList = state.plasticBoxBg.editButtonList;
            btnList.push(newEditBtn);
            const plasticBoxBgUpdate = {
                editButtonList: btnList,
                currIndex: btnList.length-1,
            };

            state.plasticBoxBg = Object.assign({}, state.plasticBoxBg, plasticBoxBgUpdate);
        },

        plasticBoxBgUpdatedEditButton:(state, action) => {

            const btnIndex = state.plasticBoxBg.currIndex;
            const newEditBtn = action.payload;
            if (isEmptyObject(newEditBtn) || btnIndex < 0) {
                log("plasticBoxBgUpdatedEditButton: 更新按钮失败，参数为空");
                return;
            }


            let btnList = state.plasticBoxBg.editButtonList;
            btnList[btnIndex] = newEditBtn;

            const plasticBoxBgUpdate = {
                editButtonList: btnList,
                currIndex: btnIndex,
            };

            state.plasticBoxBg = Object.assign({}, state.plasticBoxBg, plasticBoxBgUpdate);
        },


        plasticBoxBgDeleteEditButton:(state, action) => {

            const btnIndex = state.plasticBoxBg.currIndex;
            if (btnIndex < 0) {
                log("plasticBoxBgDeleteEditButton: 删除按钮失败，参数为空");
                return;
            }

            let btnList = state.plasticBoxBg.editButtonList;
            btnList.splice(btnIndex, 1);

            const plasticBoxBgUpdate = {
                editButtonList: btnList,
                currIndex: -1,
            };

            state.plasticBoxBg = Object.assign({}, state.plasticBoxBg, plasticBoxBgUpdate);
        },
    }
})

export const {
    languageChange,
    themeChange ,
    specialCharChange,
    goldBarChange ,
    goldBarAddEditButton,
    goldBarUpdatedEditButton,
    goldBarDeleteEditButton,
    plasticBoxChange ,
    plasticBoxAddEditButton,
    plasticBoxUpdatedEditButton,
    plasticBoxDeleteEditButton,
    plasticBoxBgChange ,
    plasticBoxBgAddEditButton,
    plasticBoxBgUpdatedEditButton,
    plasticBoxBgDeleteEditButton,
} = reduxSlice.actions


export const reduxStore = configureStore({
    reducer: reduxSlice.reducer
})

