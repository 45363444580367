/**
 * GSZhiZuo
 * GSDevice.js
 *
 * @author: zengzhiming
 * @date:   2023/2/29 10:51
 * @desc:   GSDevice
 **/
'use strict';
import {Platform} from 'react-native';

import * as Device from 'expo-device';

/**
 * 是否真机运行
 * 真机和网页运行返回true, 模拟器返回false
 **/
export const isDevice = Device.isDevice;

/**
 * 设备品牌
 * Android: "google", "xiaomi"; iOS: "Apple"; web: null
 **/
export const brand = Device.brand;

/**
 * 设备制造商
 * Android: "Google", "xiaomi"; iOS: "Apple"; web: "Google", null
 **/
export const manufacturer = Device.manufacturer;

/**
 * 苹果设备内部型号ID
 * iOS: "iPhone7,2"; Android: null; web: null
 **/
export const modelId = Device.modelId;

/**
 * 设备型号名称
 * Android: "Pixel 2"; iOS: "iPhone XS Max"; web: "iPhone", null
 **/
export const modelName = Device.modelName;

/**
 * 设备年代等级
 * Android/iOS RAM 2GB:2013, RAM 3GB:2014, RAM 5GB:2015, RAM More:2016;  web null
 **/
export const deviceYearClass = Device.deviceYearClass;

/**
 * 设备的总内存
 * Android/iOS: 17179869184; web: null
 **/
export const totalMemory = Device.totalMemory;

/**
 * 支持的处理器架构版本列表
 * ['arm64 v8', 'Intel x86-64h Haswell', 'arm64-v8a', 'armeabi-v7a", 'armeabi']; web: null
 **/
export const supportedCpuArchitectures = Device.supportedCpuArchitectures;

/**
 * 操作系统的名称
 * Android: "Android"; iOS: "iOS" or "iPadOS"; web: "iOS", "Android", "Windows", "Mac OS"
 **/
export const osName = Device.osName;

/**
 * 操作系统版本
 * Android: "4.0.3"; iOS: "12.3.1"; web: "11.0", "8.1.0"
 **/
export const osVersion = Device.osVersion;

/**
 * 系统构建版本ID
 * Android: "PSR1.180720.075"; iOS: "16F203"; web: null
 **/
export const osBuildId = Device.osBuildId;

/**
 * 系统内部版号
 * Android: "MMB29K"; iOS: "16F203"; web: null,
 **/
export const osInternalBuildId = Device.osInternalBuildId;

/**
 * Android SDK版本
 * Android: 19; iOS: null; web: null
 **/
export const platformApiLevel = Device.platformApiLevel;

/**
 * 用户设置的设备名称
 * "Vivian's iPhone XS"
 **/
export const deviceName = Device.deviceName;



/**
 * 获取设备类型
 * 如果屏幕对角线长度在3英寸到6.9英寸之间，则该方法返回DeviceType.PHONE。
 * 对于7英寸到18英寸之间的长度，该方法返回DeviceType.TABLET。
 * 否则，该方法返回DeviceType.UNKNOWN
 **/
export async function getDeviceTypeAsync(){
    return await Device.getDeviceTypeAsync();
}

/**
 * 获取自上次重新启动设备以来的正常运行时间，以毫秒为单位
 * 4371054
 **/
export async function getUptimeAsync() {
    return Platform.OS === 'web' ? 0 : await Device.getUptimeAsync();
}

export function isDesktopBrowser(){
    if (Platform.OS === 'web'){
        const isDesktopBrowser1 = /Windows|Macintosh|Linux/.test(navigator.userAgent);
        const isDesktopBrowser2 = /Windows|Mac OS|Linux/.test(Device.osName);
        if (isDesktopBrowser1 || isDesktopBrowser2){
            return true;
        }
    }
    return false;
}












