/**
 * GSZhiZuo
 * GSImagePicker.js
 *
 * @author zengzhiming
 * @date   2019/1/24
 * @desc:   GSImagePicker
 **/

"use strict";


import React from 'react';
import * as ImagePicker from 'expo-image-picker';


/**
 * 同步选择单张图片
 *
 * backBlock 数据回调 {code: -1, msg: "未知错误", data: {}}
 **/
export const launchSingleImageLibrary = ( backBlock ) => {
    launchSingleImageLibraryAsync().then((result)=>{
        if (backBlock) backBlock(result["code"], result["msg"], result["data"]);
    })
}

/**
 * 同步选择多张图片
 *
 * backBlock 数据回调 {code: -1, msg: "未知错误", data: []}
 **/
export const launchMultipleImageLibrary = ( backBlock ) => {
    launchMultipleImageLibraryAsync().then((result)=>{
        if (backBlock) backBlock(result["code"], result["msg"], result["data"]);
    })
}

/**
 * 同步相机拍照
 *
 * backBlock 数据回调 {code: -1, msg: "未知错误", data: {}}
 **/
export const launchCameraPhoto = ( backBlock ) => {
    launchCameraPhotoAsync().then((result)=>{
        if (backBlock) backBlock(result["code"], result["msg"], result["data"]);
    })
}


/**
 * 异步选择单张图片
 *
 * 返回：{code: -1, msg: "未知错误", data: {}}
 **/
export const launchSingleImageLibraryAsync = async ( ) => {

    let result = {code: -1, msg: "未知错误", data: {}};

    const mediaPermiss = await ImagePicker.requestMediaLibraryPermissionsAsync(false);
    switch (mediaPermiss["status"]) {
        case "denied": {
            // 没有权限
            result["code"] = -2;
            result["msg"] = "没有相册权限";
            break;
        }
        case "granted": {
            // 启动图片选择
            const imgPicker = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                aspect: [1, 1],
                quality: 0.2,
            });
            if (imgPicker["canceled"] === true) {
                // 取消图片选择
                result["code"] = -1;
                result["msg"] = "取消图片选择";
            } else {
                // 选择中图片回调
                // const imgUri = imgPicker["uri"];
                result["code"] = 1;
                result["msg"] = "图片选择成功";
                result["data"] = imgPicker["assets"][0];

                // log(imgPicker);
            }
            break
        }
    }

    return result;
}



/**
 * 异步选择多张图片
 *
 * 返回：{code: -1, msg: "未知错误", data: []}
 **/
export const launchMultipleImageLibraryAsync = async ( ) => {

    let result = {code: -1, msg: "未知错误", data: []};

    const mediaPermiss = await ImagePicker.requestMediaLibraryPermissionsAsync(false);
    switch (mediaPermiss["status"]) {
        case "denied": {
            // 没有权限
            result["code"] = -2;
            result["msg"] = "没有相册权限";
            break;
        }
        case "granted": {
            // 启动图片选择
            const imgPicker = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsMultipleSelection: true,
                quality: 0.2,
            });
            if (imgPicker["canceled"] === true) {
                // 取消图片选择
                result["code"] = -1;
                result["msg"] = "取消图片选择";
            } else {
                // 选择中图片回调
                // const imgUri = imgPicker["selected"]["0"]["uri"];
                result["code"] = 1;
                result["msg"] = "图片选择成功";
                result["data"] = imgPicker["selected"];
            }
            break
        }
    }

    return result;
}


/**
 * 异步拍照
 *
 * 返回：{code: -1, msg: "未知错误", data: {}}
 **/
export const launchCameraPhotoAsync = async ( ) => {

    let result = {code: -1, msg: "未知错误", data: {}};

    const cameraPermiss = await ImagePicker.requestCameraPermissionsAsync();
    switch (cameraPermiss["status"]) {
        case "denied": {
            // 没有权限
            result["code"] = -2;
            result["msg"] = "没有相机权限";
            break;
        }
        case "granted": {
            // 启动相机拍照
            const imgPicker = await ImagePicker.launchCameraAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                aspect: [1, 1],
                quality: 0.2,
            });
            if (imgPicker["canceled"] === true) {
                // 取消图片选择
                result["code"] = -1;
                result["msg"] = "取消拍照";
            } else {
                // 选择中图片回调
                // const imgUri = imgPicker["uri"];
                result["code"] = 1;
                result["msg"] = "图片选择成功";
                result["data"] = imgPicker["assets"][0];
            }
            break
        }
    }

    return result;
}