/**
 * GSZhiZuo
 * GSTemplatePage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/19
 * @desc:   GSTemplatePage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    FlatList,
    ImageBackground,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import { WebView } from 'expo-react-native-webview';

const LEFT_ITEM_HEIGHT = 32;
const LEFT_ITEM_WIDTH = 80;

class GSTemplatePage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            dataSource:[],
            selIndex:0,
        };

        // 计算Item宽高
        this.RIGHT_ITEM_WIDTH = screenWidth-LEFT_ITEM_WIDTH-10*2;
        this.RIGHT_ITEM_HEIGHT = this.RIGHT_ITEM_WIDTH*0.8;
    }

    componentDidMount() {
        // 获取模板列表数据
        this.getTemplateListData();
    }


    // 获取模板列表数据
    getTemplateListData = () =>{
        storageLoad(StorageKey.TEMPLATE_LIST, (code, dataList) => {
            if (code === 1) {
                // 获取成功
                this.setState({
                    dataSource:dataList,
                });
            } else {
                // 获取失败
                showAlert(T("获取网络数据失败，是否需要重试?"),"",[T("否"), T("是")], (index, title)=>{
                    if (index === 1) {
                        // 重新获取数据
                        this.getTemplateListData();
                    }
                });
            }
        });
    };

    // 点击左边Item
    onLeftItemPress = (data, index) => {
        const rightList = data['list'];
        if (!isEmptyObject(rightList) && rightList.length === 1 && rightList[0]['PageType'] === '_blank' && !isEmptyString(rightList[0]['WebUrl'])) {
            const webUrl = rightList[0]['WebUrl'];
            this.push(SceneKey.TempWeb,{WebUrl:webUrl});
            index = 1;
            // return;
        }

        this.setState({
            selIndex:index,
        });
    };

    // 点击右边Item
    onRightItemPress = (data, index) => {
        const sampleType = data['SampleType']+'';
        if (sampleType === '2') {
            const webUrl = data["WebUrl"];
            this.push(SceneKey.TempWeb,{WebUrl:webUrl});
        }
    };

    // 渲染左边Item
    renderLeftItem = (item, index)=> {
        const {Colors} = Theme;
        let {selIndex} = this.state || {};
        return (
            <TouchableOpacity
                onPress={()=>{this.onLeftItemPress(item, index)}}
                style={{
                    height:LEFT_ITEM_HEIGHT,
                    justifyContent:'center',
                    alignItems:'center',
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.box_template_F5F5F5,
                    backgroundColor:index === selIndex ? Colors.box_template_F5F5F5 : Colors.box_template_EAEAEA,
                }}
            >
                <Text style={{color:index === selIndex ? Colors.theme_first_s : Colors.box_template_2A2121,}} >
                    {item["TypeName"]}
                </Text>
            </TouchableOpacity>
        );
    };

    // 渲染右边Item
    renderRightItem = (item, index)=> {
        const {Colors} = Theme;
        return (
            <TouchableOpacity style={{marginBottom: 10}} onPress={()=>{this.onRightItemPress(item, index)}}>
                <ImageBackground
                    resizeMode={'contain'}
                    source={{uri:item["PicPath"]}}
                    style={{
                        width: this.RIGHT_ITEM_WIDTH,
                        height: this.RIGHT_ITEM_HEIGHT,
                        backgroundColor:Colors.theme_FFFFFF_s,
                    }}
                />
            </TouchableOpacity>
        );
    };

    render() {

        const {dataSource, selIndex} = this.state || {};
        let {list : rightList} = dataSource[selIndex] || {};

        let isHelp = false;
        if (!isEmptyObject(rightList) && rightList.length === 1 && rightList[0]['SampleType'] === '2' && !isEmptyString(rightList[0]['WebUrl'])) {
            isHelp = true;
        }


        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View style={styles.list_view}>
                            <View style={styles.left_view}>
                                <FlatList renderItem={({item, index}) => this.renderLeftItem(item, index)}
                                          data={dataSource}
                                          extraData={selIndex}
                                          keyExtractor={(item, index) => index+''}
                                          numColumns={1}
                                          showsVerticalScrollIndicator = {false}
                                          initialNumToRender={Math.ceil(screenHeight/LEFT_ITEM_HEIGHT)}
                                />
                            </View>
                            <View style={styles.right_view}>
                                {
                                    isHelp ?
                                        <View style={{overflow:"hidden", flex:1,}}>
                                            <WebView
                                                style={{flex:1,margin:-2,padding:2}}
                                                source={{uri: rightList[0]['WebUrl']}}
                                                onLoadStart={()=>{showLoading()}}
                                                onLoadEnd={()=>{closeLoading()}}
                                            />
                                        </View>
                                        :
                                        <FlatList renderItem={({item, index}) => this.renderRightItem(item, index)}
                                                  data={rightList}
                                                  extraData={selIndex}
                                                  keyExtractor={(item, index) => index+''}
                                                  numColumns={1}
                                                  showsVerticalScrollIndicator = {false}
                                        />
                                }
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            list_view:{
                flex: 1,
                flexDirection:'row',
            },
            left_view:{
                width:LEFT_ITEM_WIDTH,
                backgroundColor:Colors.box_template_F5F5F5
            },
            right_view:{
                flex: 1,
                paddingLeft: 10,
                paddingRight:10,
                backgroundColor:Colors.box_template_F5F5F5
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSTemplatePage)


// export default GSTemplatePage;
