/**
 * GSZhiZuo
 * GSGoldEditImageView.js
 *
 * @author: zengzhiming
 * @date:   2023/6/6
 * @desc:   GSGoldEditImageView
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    StyleSheet,
    Text,
    View,
    Animated,
} from 'react-native';
import BasePage from "../../../Common/Component/BaseComponent/GSBasePage";
import {
    goldBarAddEditButtonAction,
    goldBarSelectedEditButtonAction,
    goldBarSelectEditBoxAction,
    goldBarUpdatedEditButtonAction,
    AnimatedDict,
} from "../../../Common/Redux/GSReduxAction";
import {ImageModel, minScale, maxScale, } from "../../../Common/Utils/GSDefined";
import {State, TapGestureHandler} from "react-native-gesture-handler";
import {launchCameraPhoto, launchSingleImageLibrary} from "../../../Common/Utils/GSImagePicker";

class GSGoldEditImageView extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // 校验大小和偏移量
        const {boxIndex, currBtnIndex} = this.props || {};
        if (nextProps.currBoxIndex === boxIndex && nextProps.currBtnIndex === currBtnIndex) {
            const currEditBtn =  nextProps.editBoxList[boxIndex]['BtnList'][currBtnIndex];
            this.checkMoveOffset(currEditBtn);
            this.checkSizeScale(currEditBtn);
        }

        return true;
    }


    // 校验比例大小
    checkSizeScale = (editBtn) => {

        if(isEmptyObject(editBtn)){
            return;
        }

        let currEditBtn = {...editBtn};
        let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];

        let sizeScale = currEditBtn.sizeScale;
        if (sizeScale < minScale) sizeScale = minScale;
        if (sizeScale > maxScale) sizeScale = maxScale;

        if (currEditBtn.sizeScale !== sizeScale) {
            currEditBtn.sizeScale = sizeScale;
            currEditBtnAnimated.baseScale.setValue(sizeScale);
            currEditBtnAnimated.pinchScale.setValue(1);
            goldBarUpdatedEditButtonAction(currEditBtn);
        }
    };


    // 校验移动偏移
    checkMoveOffset = (editBtn) => {

        if(isEmptyObject(editBtn)){
            return;
        }

        let currEditBtn = {...editBtn};
        let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];

        const { height, width } = this.props["boxData"] || {};
        let lastOffsetX = currEditBtn.lastOffset.x;
        let lastOffsetY = currEditBtn.lastOffset.y;

        // 判断是否移出框外
        Frame(currEditBtnAnimated.ref, (frame) => {

            let maxOffsetX = width - 20;
            if (lastOffsetX > maxOffsetX) {
                lastOffsetX = maxOffsetX;
            }

            let maxOffsetY = height - 20;
            if (lastOffsetY > maxOffsetY) {
                lastOffsetY = maxOffsetY;
            }

            let minOffsetX = -frame.width + 20;
            if (lastOffsetX < minOffsetX) {
                lastOffsetX = minOffsetX;
            }

            let minOffsetY = -frame.height + 20;
            if (lastOffsetY < minOffsetY) {
                lastOffsetY = minOffsetY;
            }

            if (currEditBtn.lastOffset.x !== lastOffsetX || currEditBtn.lastOffset.y !== lastOffsetY) {
                currEditBtn.lastOffset = {x: lastOffsetX, y: lastOffsetY};
                currEditBtnAnimated.offsetValue.setOffset({x: lastOffsetX, y: lastOffsetY});
                currEditBtnAnimated.offsetValue.setValue({x: 0, y: 0});
                goldBarUpdatedEditButtonAction(currEditBtn);
            }

        });
    };



    // 点击编辑区域
    onEditBoxTapHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onEditBoxTapHandlerStateChange:',event.nativeEvent);

            const {currBoxIndex, boxIndex, editBoxList, currBtnIndex} = this.props || {};
            const BtnList = editBoxList[boxIndex]['BtnList'];

            if(currBoxIndex === boxIndex){
                // 判断双击
                if (isDoublePress(1, 1000)) {
                    // 修改图片
                    this.showImageActionSheet((code, response)=>{
                        if (code === 1) {
                            // log(response);
                            // 获取成功
                            if (isEmptyObject(BtnList)) {
                                let imageBtn= cloneDeep(ImageModel);
                                imageBtn.imgUri = response.uri;
                                imageBtn.width = response.width;
                                imageBtn.height = response.height;
                                goldBarAddEditButtonAction(imageBtn);
                            }else {
                                let currEditBtn =  this.props["editBoxList"][currBoxIndex]['BtnList'][currBtnIndex];
                                let imageBtn= {...currEditBtn};
                                imageBtn.imgUri = response.uri;
                                imageBtn.width = response.width;
                                imageBtn.height = response.height;
                                goldBarUpdatedEditButtonAction(imageBtn);
                            }
                        } else if (code === 0) {
                            // 获取失败，没有权限
                            showAlert(T("访问相册受限"),T("请在设置中打开相册访问权限"),  [T("确定")], ()=>{ });
                        } else if (code !== 2) {
                            // 获取失败，出现错误
                            showAlert(T("保存图片失败"),response.message,  [T("确定")], ()=>{ });
                        }
                    });
                }
            } else {
                // 选中编辑区
                if (isEmptyObject(BtnList)) {
                    goldBarSelectEditBoxAction(boxIndex);
                }else {
                    goldBarSelectedEditButtonAction(boxIndex, 0);
                }
            }
        }
    };


    // 显示图片选择器
    showImageActionSheet = (callback) => {

        showActionSheet([T("相机"),T("相册")],T("取消"), (index)=>{

            if (index === 0)  {
                // 相机
                launchCameraPhoto( (code, msg, response) => {
                    // log(code, msg, response);
                    callback && callback(code, response);
                });
            } else if (index === 1) {
                // 相册
                launchSingleImageLibrary((code, msg,  response) => {
                    callback && callback(code, response);
                });
            }
        });
    };


    // 创建文本View
    createEditButtonView = (editButtonList) => {

        let textViewArray = [];
        const {height, width} = this.props["boxData"] || {};
        editButtonList.map((currEditBtn, btnIndex) => {
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            const hScale = height/currEditBtn.height;
            const wScale = width/currEditBtn.width;
            const imgScale = hScale > wScale ? hScale : wScale;

            textViewArray.push(
                <Animated.View
                    key={btnIndex}
                    ref={(ref)=>{
                        currEditBtnAnimated.ref=ref
                    }}
                    style={{
                        position:'absolute',
                        left:currEditBtnAnimated.offsetValue.x,
                        top:currEditBtnAnimated.offsetValue.y,
                        transform: [
                            { rotate: currEditBtnAnimated.rotate.interpolate({inputRange: [-100, 100], outputRange: ['-100rad', '100rad']}) },
                        ],
                    }}>
                    <TapGestureHandler onHandlerStateChange={(event)=>{this.onEditBoxTapHandlerStateChange(event)}}>
                        <Animated.Image
                            source={{uri:currEditBtn.imgUri}}
                            style={{
                                // transform: [{ scale: Animated.multiply(data.baseScale, data.pinchScale)}],
                                width:Animated.multiply(new Animated.Value(currEditBtn.width*imgScale), Animated.multiply(currEditBtnAnimated.baseScale, currEditBtnAnimated.pinchScale)),
                                height:Animated.multiply(new Animated.Value(currEditBtn.height*imgScale), Animated.multiply(currEditBtnAnimated.baseScale, currEditBtnAnimated.pinchScale)),
                                resizeMode:'contain',
                            }}
                        >
                        </Animated.Image>
                    </TapGestureHandler>
                </Animated.View>
            )
        });
        return textViewArray;
    };


    // 渲染界面
    render() {

        const {Colors} = Theme;
        const styles = this.getStyles();

        const { x, y, height, width, BtnList } = this.props["boxData"] || {};
        const {currBoxIndex, boxIndex, isPreview} = this.props || {};
        const bgColor = isPreview ? Colors.theme_00000000_s : ( currBoxIndex === boxIndex ? Colors.goldbar_edit_674D41_s : Colors.goldbar_edit_39393A_s);



        return (
            <View style={[styles.edit_content_view,{width:width, height:height, top:y, left:x, backgroundColor:bgColor}]}>
                <TapGestureHandler onHandlerStateChange={this.onEditBoxTapHandlerStateChange}>
                    <View style={{width:width, height:height}}>
                        {   !isEmptyObject(BtnList) ? this.createEditButtonView(BtnList) :
                            !isPreview &&
                            <View style={styles.edit_content_hint_view}>
                                <Text style={styles.edit_content_hint_text}>{T("双击上传图片")}</Text>
                            </View>
                        }
                    </View>
                </TapGestureHandler>
            </View>
        );
    }

    // 样式
    getStyles(){

        const {Colors} = Theme;

        return StyleSheet.create({
            edit_content_view:{
                overflow:'hidden',
                position: 'absolute',
            },
            edit_content_hint_view:{
                justifyContent:'center',
                alignItems:'center',
                position:'absolute',
                top:0,
                left:0,
                right:0,
                bottom:0
            },
            edit_content_hint_text:{
                fontSize:12,
                color: Colors.goldbar_edit_B4B4B4_s,
            }
        });
    };

}





export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
            editBoxList:state.goldBar.editBoxList,
            currBoxIndex:state.goldBar.currBoxIndex,
            currBtnIndex:state.goldBar.currBtnIndex,
        })
    }
)(GSGoldEditImageView)


// export default GSGoldEditImageView;
