/**
 * GSZhiZuo
 * GSCrypto.js
 *
 * @author: zengzhiming
 * @date:   2023/6/13
 * @desc:   GSCrypto
 **/
'use strict';

import MD5 from 'crypto-js/md5'


export const randomUUID = () => {
    return MD5(Date.now()+Math.random()+"").toString();
};


export const md5 = (str) => {
    return MD5(str).toString();
};
