/**
 * GSZhiZuo
 * GSBoxEditPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/19
 * @desc:   GSBoxEditPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
    Animated, PixelRatio,
} from 'react-native';
import { Icon } from '@rneui/themed';
import {captureViewToBase64Async} from "../../Common/Utils/GSCaptureImage";
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import GSDrawSignView from './View/GSDrawSignView';
import GSEditTextView from './View/GSEditTextView';
import BoxToolView from './View/GSBoxToolView'

import {PanGestureHandler, PinchGestureHandler, RotationGestureHandler, TapGestureHandler, State} from 'react-native-gesture-handler';
import {
    plasticBoxDeselectEditButtonAction,
    updatedSpecialCharAction,
    plasticBoxUpdatedEditButtonAction,
    plasticBoxAddEditButtonAction,
    plasticBoxClearEditBoxListAction,
    AnimatedDict, goldBarUpdatedEditButtonAction,
} from "../../Common/Redux/GSReduxAction";
import {TextModel} from "../../Common/Utils/GSDefined";

class GSBoxEditPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.calculateSize(props.route.params?.boxData);

        this.signEmpty = true;
        this.state = {
            isEdit:true,
        };

        this.updatedSpecialCharList();

        this.initNavi();
    }

    initNavi = () => {

        // 设置返回按钮
        this.setBackTitle(T("型号"));
        this.setBackPress(()=>{
            // 保存上次数据
            this.saveLastListData();
            // 清除数据
            plasticBoxClearEditBoxListAction();
            // 返回
            this.goBack();

        });

        // 设置导航栏右边按钮
        this.props.navigation.setOptions({
            headerRight: (props) => {
                return (
                    <TouchableOpacity style={{flexDirection:"row", alignItems:"center", padding:8}} onPress={()=>{this.onRightButtonPress()}} >
                        <Text style={{fontSize:17}}>{T("下一步")}</Text>
                    </TouchableOpacity>
                );
            },
        });
    };


    // 计算数据
    calculateSize = (boxData) => {
        let scale = boxData['Length'] / boxData['Height'];
        this.editWidth = screenWidth-20;
        this.editHeight = screenWidth-20;
        if (scale > 1) {
            this.editHeight = this.editWidth/scale;
        } else if(scale < 1) {
            this.editWidth = this.editHeight*scale;
        }

        // const WidthPX = boxData['Length']*300/25.4;
        // const magniScale = WidthPX/PixelRatio.getPixelSizeForLayoutSize(this.editWidth);
        this.magniScale = 1;
    };

    // 更新特殊字符列表
    updatedSpecialCharList = () => {
        // storageRemove(StorageKey.SPECIAL_CHAR_LIST);
        storageLoad(StorageKey.SPECIAL_CHAR_LIST, (code, specialCharList) => {
            if (code === 1) {
                // 获取成功
                updatedSpecialCharAction(specialCharList);
            } else {
                // 获取失败
                showAlert(T("获取网络数据失败，是否需要重试?"),"",[T("否"), T("是")], (index, title)=>{
                    if (index === 1) {
                        // 重新获取数据
                        this.updatedSpecialCharList();
                    }
                });
            }
        });
    };


    // 导航栏右侧按钮点击
    onRightButtonPress = () => {
        // 取消选中状态
        plasticBoxDeselectEditButtonAction();

        this.setState({
            isEdit:false,
        })

        // 判断编辑是否为空
        const editButtonList = this.props.editButtonList;
        if (isEmptyObject(editButtonList)){
            showToast(T("请输入编辑文本"));
            return;
        }

        // 判断签名是否为空
        if (this.signEmpty) {
            showToast(T("请输入签名"));
            return;
        }

        // 保存上次数据
        this.saveLastListData();

        // 拼接数据
        const boxmodel = this.props.route.params?.boxData?.['ModelName'];
        let boxWord = {boxmodel:boxmodel};
        editButtonList.map((textObj, index)=>{
            boxWord['word'+(index+1)] = textObj.textValue;
        });

        showLoading();
        setTimeout(()=>{
            // 截取图片
            this.captureImages().then((images)=>{
                closeLoading();
                this.setState({
                    isEdit:true,
                })
                // 跳转到用户信息页面
                this.push(SceneKey.BoxUserInfo, {
                    images:images,
                    boxWord:boxWord,
                });
            }).catch((error)=>{
                closeLoading();
                // 截图失败
                showAlert(T("保存图片失败"),error.message,  [T("确定")], ()=>{ });
            });
        },200);


    };

    // 截取图片
    captureImages = async () => {
        const base64Image1 = await captureViewToBase64Async(this.screenView, null);
        const base64Image2 = await captureViewToBase64Async(this.editView, null);
        return {
            'image1':base64Image1,
            'image2':base64Image2,
        };
    };



    // 点击标题
    titleOnPress = () => {
        // 保存上次数据
        const editButtonList = this.props.editButtonList;
        if (!isEmptyObject(editButtonList)){
            this.saveLastListData();
            showToast(T("暂存成功"));
        }
    };


    // 保存上次数据
    saveLastListData = () =>{
        const editButtonList = this.props.editButtonList;
        if (isEmptyObject(editButtonList)){
            return;
        }

        let saveList = [...editButtonList];
        storageSave(StorageKey.BOX_LAST_DATA, saveList);

    };


    // 签名改变回调
    onSignPathChange = (drawPath) => {
        this.signEmpty = isEmptyString(drawPath);
    };

    /*===================手势监听Start====================*/

    // 旋转状态改变
    onRotateHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onRotateHandlerStateChange:',event.nativeEvent);
            if(this.props.currIndex < 0){
                return;
            }
            let currEditBtn = {...this.props.editButtonList[this.props.currIndex]};
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            currEditBtn.lastRotate += event.nativeEvent.rotation;
            currEditBtnAnimated.rotate.setOffset(currEditBtn.lastRotate);
            currEditBtnAnimated.rotate.setValue(0);

            plasticBoxUpdatedEditButtonAction(currEditBtn);
        }
    };


    // 捏合状态改变
    onPinchHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onPinchHandlerStateChange:',event.nativeEvent);
            if(this.props.currIndex < 0){
                return;
            }
            let currEditBtn = {...this.props.editButtonList[this.props.currIndex]};
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            let sizeScale = currEditBtn.sizeScale;
            sizeScale *= event.nativeEvent.scale;
            currEditBtn.sizeScale = sizeScale;
            currEditBtnAnimated.baseScale.setValue(sizeScale);
            currEditBtnAnimated.pinchScale.setValue(1);

            plasticBoxUpdatedEditButtonAction(currEditBtn);

        }
    };

    // 平移状态改变
    onPanHandlerStateChange = (event) => {

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onPanHandlerStateChange:',event.nativeEvent);
            if(this.props.currIndex < 0){
                return;
            }
            let currEditBtn = {...this.props.editButtonList[this.props.currIndex]};
            let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
            let lastOffsetX = currEditBtn.lastOffset.x + event.nativeEvent.translationX;
            let lastOffsetY = currEditBtn.lastOffset.y + event.nativeEvent.translationY;

            currEditBtn.lastOffset = {x:lastOffsetX, y:lastOffsetY};
            currEditBtnAnimated.offsetValue.setOffset({x:lastOffsetX, y:lastOffsetY});
            currEditBtnAnimated.offsetValue.setValue({x:0,y:0});

            plasticBoxUpdatedEditButtonAction(currEditBtn);
        }
    };

    // 点击内容面板
    onContentTapHandlerStateChange = (event) => {

        if (!this.state["isEdit"]) {
            return;
        }

        if (event.nativeEvent.oldState === State.ACTIVE) {
            // log('_onContentTapHandlerStateChange:',event.nativeEvent);
            if(this.props.currIndex < 0){
                // 判断双击
                if (isDoublePress(2, 1000)) {
                    // 添加文本
                    this.push(SceneKey.BoxAddText);
                    // let test1= cloneDeep(TextModel);
                    // test1.textValue = '测试文本1';
                    // plasticBoxAddEditButtonAction(test1);
                }
            } else {
                // 取消选中
                plasticBoxDeselectEditButtonAction();
            }
        }
    };

    /*===================手势监听End====================*/

    render() {

        const {isEdit} = this.state;
        const {currIndex, editButtonList} = this.props || {};
        const picPath = this.props.route.params?.boxData['PicPath'];

        let emptyEvent = Animated.event([{ nativeEvent: { } }],{useNativeDriver: false});
        let deselect = currIndex < 0;

        let currEditBtn = deselect ? undefined : editButtonList[currIndex];
        let currEditBtnAnimated = deselect ? undefined : AnimatedDict[currEditBtn.animatedId];

        const styles = this.getStyles();
        const {Colors} = Theme;

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View ref={(ref)=>{this.screenView = ref}} style={{flex: 1, backgroundColor:Colors.theme_FFFFFF}}>
                            <View style={styles.edit_comp_view}>
                                <TapGestureHandler onHandlerStateChange={this.onContentTapHandlerStateChange}>
                                    <PanGestureHandler
                                        onGestureEvent={ deselect ? emptyEvent : Animated.event([{ nativeEvent: {translationX: currEditBtnAnimated.offsetValue.x, translationY: currEditBtnAnimated.offsetValue.y}}],{useNativeDriver: false})}
                                        onHandlerStateChange={deselect ? ()=>{} : this.onPanHandlerStateChange}
                                        minDist={20}
                                    >
                                        <Animated.View style={styles.edit_comp_view}>
                                            <RotationGestureHandler
                                                // onGestureEvent={deselect ? emptyEvent :Animated.event([{ nativeEvent: {rotation: currEditBtnAnimated.rotate} }],{useNativeDriver: false})}
                                                // onHandlerStateChange={deselect ? ()=>{} : this.onRotateHandlerStateChange}
                                            >
                                                <Animated.View style={styles.edit_comp_view}>
                                                    <PinchGestureHandler
                                                        onGestureEvent={deselect ? emptyEvent :Animated.event([{ nativeEvent: {scale: currEditBtnAnimated.pinchScale}}],{useNativeDriver: false})}
                                                        onHandlerStateChange={deselect ? ()=>{} : this.onPinchHandlerStateChange}
                                                    >
                                                        <Animated.View style={styles.edit_comp_view}>
                                                            <View style={{position:'absolute', top:0, left:0, overflow:"hidden",}}>
                                                                <View  ref={(ref)=>{this.editView = ref}}  style={{position: 'absolute',backgroundColor:'#FFFFFF'}}>
                                                                    {isEdit === false && <GSEditTextView magniScale={this.magniScale} isEdit={isEdit} picPath={picPath} editWidth={this.editWidth*this.magniScale} editHeight={this.editHeight*this.magniScale} />}
                                                                </View>
                                                            </View>
                                                            <View style={{backgroundColor:Colors.theme_FFFFFF_s, width: this.editWidth, height:this.editHeight}}>
                                                                <GSEditTextView magniScale={1} isEdit={isEdit}  picPath={picPath} editWidth={this.editWidth} editHeight={this.editHeight} navigation={this.props.navigation}  />
                                                            </View>
                                                        </Animated.View>
                                                    </PinchGestureHandler>
                                                </Animated.View>
                                            </RotationGestureHandler>
                                        </Animated.View>
                                    </PanGestureHandler>
                                </TapGestureHandler>
                            </View>
                            <View style={styles.draw_sign_view}>
                                <GSDrawSignView onPathChange={this.onSignPathChange} />
                            </View>
                        </View>
                        <BoxToolView navigation={this.props.navigation} />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            edit_comp_view:{
                flex: 1,
                justifyContent:'center',
                alignItems:'center',
            },
            draw_sign_view:{
                width: screenWidth,
                height:180,
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
            currIndex:state.plasticBox.currIndex,
            editButtonList:state.plasticBox.editButtonList,
        })
    }
)(GSBoxEditPage)


// export default GSBoxEditPage;
