/**
 * GSZhiZuo
 * GSHttpFilter.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:19
 * @desc:   GSHttpFilter
 **/

'use strict';

import {httpPostManager, httpGetManager} from './GSHttpManager';
import {spliceURILogString} from '../Utils/GSSpliceURI';
import {applicationId, nativeApplicationVersion} from "../Utils/GSApplication";
import {Platform} from "react-native";

export const HttpCode = {
    SUCCESS:1,
    FAIL:-1
};


/** 创建上传文本信息：{'uri': imgUri, 'type': 'image/jpeg', 'name': 'image.jpeg'}
 *
 * uri string 文件URI
 * type string 文件类型：image/jpeg
 * name string 文件名字：image.jpeg
 */
export const createUploadFile = (uri, name, type) => {
    if (isEmptyString(uri)) {
        return {};
    }

    if (isEmptyString(type)) type = "application/octet-stream";

    // 如果没有名字就取出文件名字
    if (isEmptyString(name)) name = uri.split("/").pop();

    // 如果没有后缀名就添加后缀名
    if(name.indexOf(".") === -1) {
        const ext = uri.split(".").pop();
        name = name + "." + ext;
    }

    return {uri: uri, name: name, type: type}
};

/** 创建上传Base64文件信息
 *
 * base64Data string base64数据
 * type string 文件类型：image/jpeg
 * name string 文件名字：image.jpeg
 */
export const createUploadBase64File =  (base64Data, name, type) => {
    if (isEmptyString(base64Data)) {
        return {};
    }

    const dataArr = base64Data.split(",");
    const byteString = atob(dataArr[1]);
    const u8Arr = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
    }

    // 如果没有类型就添加类型
    if (isEmptyString(type)) type = base64Data.match(/data:(\S*);base64/)[1];

    // 如果没有名字就设置一个名字
    if (isEmptyString(name)) name = "base64File";

    // 如果没有后缀名就添加后缀名
    if(name.indexOf(".") === -1) {
        const typeToExt = {
            "image/png" : "png",
            "image/jpeg" : "jpeg",
            "image/jpg" : "jpg",
            "audio/mpeg" : "mp3",
            "audio/x-m4a" : "m4a",
            "text/plain" : "txt",
        };
        let ext = typeToExt[type] || type.split("/").pop();
        name = name + "." + ext;
    }

    return new File([u8Arr], name, {type:type});
};




/** HttpPost请求
 *
 * uristr string URI字符串
 * params object 参数
 * success function 成功回调
 * failure function 失败回调
 */
export const httpPostFilter = (uristr = '', params = {}, success, failure) => {

    // 添加固定参数
    params = addFixedParams(params);

    log("httpPostFilter: ",spliceURILogString(uristr, params));

    httpPostManager(uristr, params, null)
        .then(
            (jsonData) => {
                const resultstatus = jsonData["resultstatus"] || "";
                if (resultstatus.toLowerCase() === 'true') {
                    // 请求成功
                    if (success) success(HttpCode.SUCCESS, jsonData["list"])
                } else {
                    log("Http Response Fail: "+spliceURILogString(uristr, params));
                    if (failure) failure(HttpCode.FAIL, jsonData);
                }
            },
            (error)=>{
                log("Http Request Error: "+spliceURILogString(uristr, params));
                if (failure) failure(HttpCode.FAIL, {'msg': error.message});
            }
        ).catch(
        (error) => {
            log("Http Request Error: "+spliceURILogString(uristr, params));
            if (failure) failure(HttpCode.FAIL, {'msg': error.message});
        }
    );
};


/** HttpGet请求
 *
 * uristr string URI字符串
 * params object 参数
 * success function 成功回调
 * failure function 失败回调
 */
export const httpGetFilter = (uristr = '', params = {}, success, failure) => {

    // 添加固定参数
    params = addFixedParams(params);

    httpGetManager(uristr, params)
        .then(
            (jsonData) => {
                const resultstatus = jsonData["resultstatus"] || "";
                if (resultstatus.toLowerCase() === 'true') {
                    // 请求成功
                    if (success) success(HttpCode.SUCCESS, jsonData["list"])
                } else {
                    log("Http Response Fail: "+spliceURILogString(uristr, params));
                    if (failure) failure(HttpCode.FAIL, jsonData);
                }
            },
            (error)=>{
                log("Http Request Error: "+spliceURILogString(uristr, params));
                if (failure) failure(HttpCode.FAIL, {'msg': error.message});
            }
        ).catch(
        (error) => {
            log("Http Request Error: "+spliceURILogString(uristr, params));
            if (failure) failure(HttpCode.FAIL, {'msg': error.message});
        }
    );
};


/** HttpPost上传
 *
 * uristr string URI字符串
 * params object 参数
 * datas  object 上传数据 {"uploadFiles":[{'uri': imgUri, 'type': 'image/jpeg', 'name': 'image.jpeg'}, {'uri': imgUri, 'type': 'image/jpeg', 'name': 'image.jpeg'}]}
 * success function 成功回调
 * failure function 失败回调
 */
export const httpUploadFilter = (uristr = '', params = {}, datas = {}, success, failure) => {

    // 添加固定参数
    params = addFixedParams(params);

    httpPostManager(uristr, params, datas)
        .then(
            (jsonData) => {
                // log(jsonData);
                const resultstatus = jsonData["resultstatus"] || "";
                if (resultstatus.toLowerCase() === 'true') {
                    // 请求成功
                    if (success) success(HttpCode.SUCCESS, jsonData["list"])
                } else {
                    log("Http Response Fail: "+spliceURILogString(uristr, params));
                    if (failure) failure(HttpCode.FAIL, jsonData);
                }
            },
            (error)=>{
                log("Http Request Error: "+spliceURILogString(uristr, params));
                if (failure) failure(HttpCode.FAIL, {'msg': error.message});
            }
        ).catch(
        (error) => {
            log("Http Request Error: "+spliceURILogString(uristr, params));
            if (failure) failure(HttpCode.FAIL, {'msg': error.message});
        }
    );
};


/** 添加固定参数
 *
 * params object 参数
 *
 * return object 新参数
 */
const addFixedParams = (params = {}) => {

    // 获取原Data数据
    const jsonData = params['Data'] || {};

    // 固定参数
    let fixedParams = {
        "Ver":nativeApplicationVersion,
        "PackageName":applicationId,
        "Platform":Platform.OS,
    };
    const brandInfo = getShare(ShareKey.BRAND_INFO);
    if (!isEmptyObject(brandInfo)) {
        // 添加品牌
        fixedParams['Brand'] = brandInfo['BrandNameEn'];
    }
    const language = getShare(ShareKey.LANGUAGE)
    if (!isEmptyString(language)) {
        // 添加语言
        fixedParams['Language'] = language;
    }
    const theme = getShare(ShareKey.THEME)
    if (!isEmptyString(theme)) {
        // 添加主题
        fixedParams['Theme'] = theme;
    }

    const newJsonData = Object.assign({},fixedParams, jsonData);
    params['Data'] = JSON.stringify(newJsonData);

    return params;


};
