/**
 * GSZhiZuo
 * GSApplication.js
 *
 * @author: zengzhiming
 * @date:   2023/2/29 10:51
 * @desc:   GSApplication
 **/
'use strict';

import * as Application from 'expo-application';


/**
 * 原生应用程序的版本号
 * Android/iOS: "2.11.0"； web null
 **/
export const nativeApplicationVersion = Application.nativeApplicationVersion;

/**
 * 原生应用程序的内部构建版本
 * iOS"2.11.0"； 安卓："114"； web null
 **/
export const nativeBuildVersion = Application.nativeBuildVersion;

/**
 * 应用程序显示的名称
 * "Expo"，"Yelp""Instagram"； web null
 **/
export const applicationName = Application.applicationName;

/**
 * 应用程序的包名
 * "com.cocoacasts.scribbles"，"com.apple.Pages"； web null
 **/
export const applicationId = Application.applicationId;

/**
 * 安卓ID, 安卓唯一ID
 * Android: "dd96dec43fb81c97"; iOS: null; web: null
 **/
export const androidId = Application.androidId;



/**
 * 获取iOS“供应商标识符”（IDFV）值
 * "68753A44-4D6F-1226-9C60-0050E4C00067"
 **/
export async function getIosIdForVendorAsync() {
    return await Application.getIosIdForVendorAsync();
}



/**
 * 获取 iOS 应用程序发布类型。
 *
 * ApplicationReleaseType.UNKNOWN ＝ 0
 * ApplicationReleaseType.SIMULATOR ＝ 1
 * ApplicationReleaseType.ENTERPRISE ＝ 2
 * ApplicationReleaseType.DEVELOPMENT ＝ 3
 * ApplicationReleaseType.AD_HOC ＝ 4
 * ApplicationReleaseType.APP_STORE ＝ 5
 **/
export async function getIosApplicationReleaseTypeAsync() {
    return await Application.getIosApplicationReleaseTypeAsync();
}



/**
 * 获取iOS推送通知 (APN) 服务环境
 * 'development', 'production', null.
 **/
export async function getIosPushNotificationServiceEnvironmentAsync() {
    return await Application.getIosPushNotificationServiceEnvironmentAsync();
}



/**
 * 获取应用程序安装到设备上的时间
 * Android/iOS: 2019-07-18T18:08:26.121Z  Web: null
 **/
export async function getInstallationTimeAsync() {
    return await Application.getInstallationTimeAsync();
}



/**
 * 获取安卓更新该应用程序的时间
 * Android 2019-07-18T18:08:26.121Z
 **/
export async function getLastUpdateTimeAsync() {
    return await Application.getLastUpdateTimeAsync();
}













