/**
 * GSZhiZuo
 * GSRoot.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:41
 * @desc:   GSRoot
 **/
'use strict';

import './GSGlobals'; // 注册全局变量
import { gestureHandlerRootHOC, enableExperimentalWebImplementation} from 'react-native-gesture-handler';
import { RootSiblingParent } from 'react-native-root-siblings';
import React, {Component} from 'react';
import {LogBox, Text, View} from 'react-native';
import { Provider } from 'react-redux';
import {reduxStore} from '../Common/Redux/GSReduxStore'
import Navigation from './GSNavigation'
import * as SplashScreen from 'expo-splash-screen';


export default class GSRoot extends Component {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        enableExperimentalWebImplementation(true);

        // 继续显示启动页 Prevent native splash screen from autohiding before App component declaration
        SplashScreen.preventAutoHideAsync()
            .then(result => log(`SplashScreen.preventAutoHideAsync() succeeded: ${result}`))
            .catch(console.warn);

        // 屏蔽指定黄色警告
        LogBox.ignoreLogs([
            'Key "cancelled" in the image picker result is deprecated and will be removed in SDK 48, use "canceled" instead',
        ]);

        initLanguage();
        initTheme();

    }


    componentDidMount() {
        // 隐藏启动页 Hides native splash screen
        SplashScreen.hideAsync().then(()=>{});
    }


    render() {

        const NavigationRootHoc = gestureHandlerRootHOC(Navigation);
        return (
            <View style={{flex:1, flexDirection: 'row', justifyContent: 'center', backgroundColor: '#EEEEEE'}}>
                <View style={{width: screenWidth, height: screenHeight}}>
                    <RootSiblingParent>
                        <Provider store={reduxStore}>
                            <NavigationRootHoc />
                        </Provider>
                    </RootSiblingParent>
                </View>
            </View>

        );
    }
}
