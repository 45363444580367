/**
 * GSZhiZuo
 * GSStorageSync.js
 *
 * @author: zengzhiming
 * @date:   2022/5/24 16:02
 * @desc:   GSStorageSync
 **/
'use strict';

import {getDrawerListData, getBoxListData, getProgressListData, getTemplateList, getSpecialCharList, getBrandListData} from '../Http/GSHttpRequest'

// 配置过期时间1分钟
const expires = 1000*60*1;


// 存储Key
export const StorageKey = {
    TEST_DATA:      'TestData',
    BRAND_LIST:     'BrandListData',
    CURRENT_BRAND_INFO: 'CurrentBrandInfo',

    DRAWER_LIST:    'DrawerListData',
    BOX_LIST:       'BoxListData',
    PROGRESS_LIST:  'ProgressListData',
    BOX_LAST_DATA:  'BoxEditLastData',
    BOX_BG_LAST_DATA:  'BoxBgEditLastData',
    TEMPLATE_LIST:  'TemplateListData',
    SPECIAL_CHAR_LIST:  'SpecialCharListData',
    BOX_USER_INFO:  'BoxUserInfo',
    BOX_BG_USER_INFO:  'BoxBgUserInfo',
};

export const storageSync = {
    // sync方法的名字必须和所存数据的key完全相同
    // 参数从params中解构取出
    // 最后返回所需数据或一个promise
    TestData(params) {
        return new Promise((resolve, reject)=>{
            // 异步获取数据
            let syncData = 'StorageSync数据';

            // 保存新数据
            storageSave(StorageKey.TEST_DATA, syncData);

            // 成功则调用resolve
            resolve(syncData);
        });
    },



    // 获取侧边栏数据
    DrawerListData(params){

        const {syncParams: { callBack }} = params;

        return new Promise((resolve, reject)=>{
            getDrawerListData(
                (code, data)=>{
                    // 保存新数据
                    storageSave(StorageKey.DRAWER_LIST, data, expires);
                    // 成功则调用resolve
                    resolve(data);
                    callBack && callBack (1, data);
                },
                (code, data)=>{
                    reject(data);
                    callBack && callBack (-1, data);
                }
            );
        });
    },

    // 获取胶箱列表数据
    BoxListData(params){

        const {syncParams: { callBack }} = params;

        return new Promise((resolve, reject)=>{
            getBoxListData(
                (code, data)=>{
                    // 保存新数据
                    storageSave(StorageKey.BOX_LIST, data, expires);
                    // 成功则调用resolve
                    resolve(data);
                    callBack && callBack (1, data);
                },
                (code, data)=>{
                    reject(data);
                    callBack && callBack (-1, data);
                }
            );
        });
    },

    // 获取进度列表数据
    ProgressListData(params){

        const {syncParams: { callBack }} = params;

        return new Promise((resolve, reject)=>{
            getProgressListData(
                (code, data)=>{
                    // 保存新数据
                    storageSave(StorageKey.PROGRESS_LIST, data, expires);
                    // 成功则调用resolve
                    resolve(data);
                    callBack && callBack (1, data);
                },
                (code, data)=>{
                    reject(data);
                    callBack && callBack (-1, data);
                }
            );
        });
    },

    // 获取模板列表数据
    TemplateListData(params){

        const {syncParams: { callBack }} = params;

        return new Promise((resolve, reject)=>{
            getTemplateList(
                (code, data)=>{
                    // 保存新数据
                    storageSave(StorageKey.TEMPLATE_LIST, data, expires);
                    // 成功则调用resolve
                    resolve(data);
                    callBack && callBack (1, data);
                },
                (code, data)=>{
                    reject(data);
                    callBack && callBack (-1, data);
                }
            );
        });
    },

    // 获取特殊字符列表数据
    SpecialCharListData(params){

        const {syncParams: { callBack }} = params;

        return new Promise((resolve, reject)=>{
            getSpecialCharList(
                (code, data)=>{
                    // 保存新数据
                    storageSave(StorageKey.SPECIAL_CHAR_LIST, data, expires);
                    // 成功则调用resolve
                    resolve(data);
                    callBack && callBack (1, data);
                },
                (code, data)=>{
                    reject(data);
                    callBack && callBack (-1, data);
                }
            );
        });
    },

    // 获取品牌列表数据
    BrandListData(params){

        const {syncParams: { callBack }} = params;

        return new Promise((resolve, reject)=>{
            getBrandListData(
                (code, data)=>{
                    // console.log(data);
                    // 保存新数据
                    storageSave(StorageKey.BRAND_LIST, data, expires);
                    // 成功则调用resolve
                    resolve(data);
                    callBack && callBack (1, data);
                },
                (code, data)=>{
                    reject(data);
                    callBack && callBack (-1, data);
                }
            );
        });
    },

};
