/**
 * GSZhiZuo
 * GSCaptureImage.js
 *
 * @author zengzhiming
 * @date   2019/1/19
 * @desc:   GSCaptureImage
 **/
"use strict";


/**
 * 安装命令
 * npx expo install react-native-view-shot
 * npx pod-install
 */


import {captureRef, captureScreen as shotCaptureScreen} from "react-native-view-shot";


/**
 * 截取View为Base64图片
 *
 * viewRef Object ref
 * options Object 参数
 * {
 *    format: "png" | "jpg"
 *    quality: 0.8,
 *    result: "tmpfile" | "base64" | "data-uri"
 * }
 * callBack function 回调方法
 *
 * 如果安卓出现错误：Trying to resolve view with tag 949 which doesn’t exist
 * 请给viewRef指定背景颜色
 *
 */
export const captureViewToBase64 = (viewRef, options, callBack) => {

    captureViewToBase64Async(viewRef, options).then(
        (imgData) => {
            callBack&&callBack(1, imgData);
        },
        (error) => {
            callBack&&callBack(-1, error);
        }
    );
};

export const captureViewToBase64Async = (viewRef, options={}) => {

    if (isNull(viewRef)) {
        return new Error('The Ref is Null');
    }

    if (isNull(options) || options.length === 0) {
        options = {
            format: "jpg",
            quality: 1,
            result:'data-uri'
        }
    }

    return captureRef(viewRef, options).then(
        (imgData) => {
            imgData =imgData.replace(/[\r\n]+/g, '')
            return imgData;
        }
    );
};




/**
 * 截取View为图片
 *
 * viewRef Object ref
 * options Object 参数
 * {
 *    format: "png" | "jpg"
 *    quality: 0.8,
 *    result: "tmpfile" | "base64" | "data-uri"
 * }
 * callBack function 回调方法
 *
 * 如果安卓出现错误：Trying to resolve view with tag 949 which doesn’t exist
 * 请给viewRef指定背景颜色
 *
 */
export const captureView = (viewRef, options, callBack) => {

    captureViewAsync(viewRef, options).then(
        (uri) => {
            callBack&&callBack(1, uri);
        },
        (error) => {
            callBack&&callBack(-1, error);
        }
    );
};



/**
 * 截取View为图片
 *
 * viewRef Object ref
 * options Object 参数
 * {
 *    format: "png" | "jpg"
 *    quality: 0.8,
 *    result: "tmpfile" | "base64" | "data-uri"
 * }
 * callBack function 回调方法
 *
 * 如果安卓出现错误：Trying to resolve view with tag 949 which doesn’t exist
 * 请给viewRef指定背景颜色
 *
 */
export const captureViewAsync = (viewRef, options) => {

    if (isNull(viewRef)) {
        return new Error('The Ref is Null');
    }


    if (isNull(options) || options.length === 0) {
        options = {
            format: "jpg",
            quality: 1,
            result: "tmpfile"
        }
    }

    return captureRef(viewRef, options);
};

/**
 * 截取屏幕为图片
 *
 * options Object 参数
 * {
 *    format: "png" | "jpg"
 *    quality: 0.8,
 *    result: "tmpfile" | "base64" | "data-uri"
 * }
 * callBack function 回调方法
 */
export const captureScreen = (options, callBack) => {

    if (isNull(options) || options.length === 0) {
        options = {
            format: "jpg",
            quality: 1,
            result: "tmpfile"
        }
    }

    shotCaptureScreen(options).then(
        (uri) => {
            callBack&&callBack(1, uri);
        },
        (error) => {
            callBack&&callBack(-1, error);
        }
    );
};


/**
 * 判断变量是否为空
 */
export const isNull = (obj) => {
    if (obj === undefined) {
        return true;
    } else if (obj === null) {
        return true;
    }
    return false;
};
