/**
 * GSZhiZuo
 * GSBoxBgSizeSelectPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/26
 * @desc:   GSBoxBgSizeSelectPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    FlatList,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";

const ITEM_HEIGHT = 44.0;

class GSBoxBgSizeSelectPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        const dataList = props.route.params?.dataList;

        this.state = {
            boxList:dataList,
        };
    }

    // 点击Item
    onItemPress = (item, index) => {
        // 处理连续点击
        if (isDoublePress()) return;
        this.navigate(SceneKey.BoxBgSelect,{"selSize":item});
    };

    render() {

        const {boxList} = this.state || [];

        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View style={styles.list_header_view}>
                            <Text style={styles.list_header_text_left}>{T("集团编号")}</Text>
                            <Text style={[styles.list_header_text_right,{left:screenWidth*0.35}]}>{T("阔*高（单位:mm）")}</Text>
                        </View>
                        <FlatList renderItem={({item, index}) => this.renderItem(item, index)}
                                  data={boxList}
                                  keyExtractor={(item, index) => index+''}
                                  numColumns={1}
                                  showsVerticalScrollIndicator = {false}
                                  initialNumToRender={Math.ceil(screenHeight/ITEM_HEIGHT)}
                                // getItemLayout={(data, index) => (
                                //     {length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index}
                                // )}
                        />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    renderItem = (item, index)=> {
        const styles = this.getStyles();
        return (
            <TouchableOpacity style={styles.item} onPress={()=>this.onItemPress(item, index)}>
                <Text style={styles.item_text_left}>{item['SizeName']}</Text>
                <Text style={[styles.item_text_right, {left:screenWidth*0.35}]}>{item['Length']} x {item['Height']}</Text>
            </TouchableOpacity>
        );
    };

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            list_header_view:{
                height:30,
                flexDirection: 'row',
                backgroundColor:Colors.boxbg_szie_select_FFF8DA,
                alignItems: 'center',
            },
            list_header_text_left:{
                fontSize:13,
                color:Colors.boxbg_szie_select_80807A,
                marginLeft: 16,
            },
            list_header_text_right:{
                fontSize:13,
                color:Colors.boxbg_szie_select_80807A,
                position: 'absolute',
            },
            item:{
                height:63,
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor:Colors.theme_FFFFFF,
                borderBottomWidth: 0.5,
                borderBottomColor:Colors.boxbg_szie_select_D7D7D7,
            },
            item_text_left:{
                fontSize:19,
                marginLeft: 16,
                color:Colors.theme_000000,
            },
            item_text_right:{
                fontSize:19,
                position: 'absolute',
                color:Colors.theme_000000,
            }
        });
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSBoxBgSizeSelectPage)


// export default GSBoxBgSizeSelectPage;