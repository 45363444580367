/**
 * GSZhiZuo
 * colors.js
 *
 * @author zengzhiming
 * @date   2019/1/18
 **/
"use strict";


export const Colors = {

    theme_000000:"#FFFFFF",
    theme_FFFFFF:"#1B1B1B",

    box_edit_sign_EDEDED:"#222222",
    box_edit_sign_282817:"#B2B2B2",

    box_list_FFF8DA:"#222222",
    box_list_80807A:"#B2B2B2",
    box_list_D7D7D7:"#333333",

    box_userinfo_D7D7D7:"#333333",

    box_template_F5F5F5:"#333333",
    box_template_EAEAEA:"#1B1B1B",
    box_template_2A2121:"#FFFFFF",

    box_progress_DDDDDD:"#666666",
    box_progress_80807A:"#666666",
    box_progress_FFF8DA:"#1B1B1B",

    boxbg_select_666666:"#B2B2B2",
    boxbg_select_F0F4F7:"#333333",

    boxbg_szie_select_FFF8DA:"#222222",
    boxbg_szie_select_80807A:"#B2B2B2",
    boxbg_szie_select_D7D7D7:"#333333",

    boxbg_userinfo_D7D7D7:"#333333",
};

export default Colors;

