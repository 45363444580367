/**
 * GSZhiZuo
 * index.js
 *
 * @author: zengzhiming
 * @date:   2023/3/9
 * @desc:   index
 **/
'use strict';

const Images = {
    launch_screen:require('./launch_screen.png'),
    launch_screen_bg:require('./launch_screen_bg.png'),
    boxbg_arrows_right:require('./boxbg_arrows_right.png'),
    boxbg_def_img:require('./boxbg_def_img.png'),
    draw_sign_back:require('./draw_sign_back.png'),
}

export default Images;