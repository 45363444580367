/**
 * GSZhiZuo
 * GSBoxToolView.js
 *
 * @author: zengzhiming
 * @date:   2023/6/19
 * @desc:   GSBoxToolView
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
} from 'react-native';
import Slider from '@react-native-community/slider';
import BasePage from "../../../Common/Component/BaseComponent/GSBasePage";
import {AnimatedDict, plasticBoxBgUpdatedEditButtonAction} from "../../../Common/Redux/GSReduxAction";
import {minFont, maxFont, BgToolColor, ToolFont} from "../../../Common/Utils/GSDefined";

const defOffset = 1;

class GSBoxToolView extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            toolSel:2,
            sizeScale:0,
        };
    }


    // 选中工具
    onToolButtonPress = (tag) => {

        if (tag === 0) {
            // 点击编辑
            const {currIndex, editButtonList} = this.props || {};
            const currEditBtn = editButtonList[currIndex];
            this.props["navigation"].push(SceneKey.BoxBgAddText,{currEditBtn:currEditBtn});
            tag = 2;
        }

        this.setState({
            toolSel:tag,
        });
    };


    // 数据改变
    onDataChange = (type, currEditBtn, changeValue) => {
        let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
        switch (type) {
            case 1:{ // 字体颜色
                currEditBtn.color = changeValue.color;
                plasticBoxBgUpdatedEditButtonAction(currEditBtn);
                break;
            }
            case 2:{ // 字体大小
                let sizeScale = changeValue.sizeScale;
                currEditBtn.sizeScale = sizeScale;
                currEditBtnAnimated.baseScale.setValue(sizeScale);
                currEditBtnAnimated.pinchScale.setValue(1);
                plasticBoxBgUpdatedEditButtonAction(currEditBtn);
                break;
            }
            case 3:{ // 字体工具
                currEditBtn.fontFamily = changeValue.fontFamily;
                plasticBoxBgUpdatedEditButtonAction(currEditBtn);
                break;
            }
            case 4:{ // 移动工具
                let lastOffsetX = currEditBtn.lastOffset.x + changeValue.lastOffset.x;
                let lastOffsetY = currEditBtn.lastOffset.y + changeValue.lastOffset.y;
                let lastOffset =  {x:lastOffsetX,y:lastOffsetY};
                currEditBtn.lastOffset = lastOffset;
                currEditBtnAnimated.offsetValue.setOffset(lastOffset);
                currEditBtnAnimated.offsetValue.setValue({x:0,y:0});
                plasticBoxBgUpdatedEditButtonAction(currEditBtn);
                break;
            }
        }

    };


    // 字体大小改变
    onFontSizeChange = (type, currEditBtn, changeValue) => {
        let currEditBtnAnimated = AnimatedDict[currEditBtn.animatedId];
        let sizeScale = changeValue.sizeScale;
        currEditBtn.sizeScale = sizeScale;
        currEditBtnAnimated.baseScale.setValue(sizeScale);
        currEditBtnAnimated.pinchScale.setValue(1);

        this.setState({
            sizeScale:sizeScale,
        });
    };

    render() {

        const {toolSel} = this.state;
        const {currIndex} = this.props || {};

        const {ButtonList} = this.getListDict();
        const styles = this.getStyles();

        return (
            currIndex >= 0 &&
            <View style={styles.tool_view}>
                <TouchableOpacity activeOpacity={1} style={styles.tool_details_stance}>
                    {this.toolDetailsView(toolSel)}
                </TouchableOpacity>
                <View style={styles.tool_main_view}>
                    {
                        ButtonList.map((obj, index)=>
                            <TouchableOpacity key={index} style={styles.tool_main_button} onPress={()=>this.onToolButtonPress(obj.tag)}>
                                <Image style={styles.tool_main_image} source={toolSel===obj.tag ? obj.selIcon : obj.defIcon} />
                            </TouchableOpacity>
                        )
                    }
                </View>
            </View>
        );
    }



    // 工具详情
    toolDetailsView = (tag) => {

        const {currIndex, editButtonList} = this.props || {};
        const currEditBtn = {...editButtonList[currIndex]};
        const {color, fontFamily, sizeScale} = currEditBtn;

        const {Images, Colors} = Theme;
        const {ColorList, FontList, MoveList} = this.getListDict();
        const styles = this.getStyles();

        switch(tag){
            case 1:{ // 字体颜色
                return (
                    <View style={styles.tool_details_view}>
                        {
                            ColorList.map((obj, index)=>
                                <TouchableOpacity key={index} style={styles.tool_details_color_btn} onPress={()=>this.onDataChange(tag, currEditBtn, obj.data)}>
                                    <Image style={color === obj.color ? styles.tool_details_color_img_sel : styles.tool_details_color_img}
                                           source={color === obj.color ? obj.selIcon : obj.defIcon} />
                                </TouchableOpacity>
                            )
                        }
                    </View>
                );
            }
            case 2:{ // 字体大小
                return (
                    <View style={styles.tool_details_view}>
                        <View style={styles.tool_details_size_view}>

                            <Slider style={styles.tool_details_size_slider}
                                    trackStyle={{height:2}}
                                    thumbStyle={{height:26, width:26, borderRadius:18,}}
                                    maximumValue={maxFont}
                                    minimumValue={minFont}
                                    thumbTintColor={Colors.theme_first_s}
                                    minimumTrackTintColor={Colors.theme_first_s}
                                    maximumTrackTintColor={Colors.theme_FFFFFF_s}
                                    thumbTouchSize={{width: 80, height: 80}}
                                    onValueChange={(value)=>this.onFontSizeChange(tag, currEditBtn, {sizeScale:value})}
                                    onSlidingComplete={(value)=>this.onDataChange(tag, currEditBtn, {sizeScale:value})}
                                    value={sizeScale}
                                    step={1}
                            />
                            <TouchableOpacity style={{position:'absolute', left:0}} onPress={()=>{this.onDataChange(tag, currEditBtn, {sizeScale:sizeScale-1})}}>
                                <Image style={{margin:10,width:18,height:18}} source={Images.tool_szie_reduce} />
                            </TouchableOpacity>
                            <TouchableOpacity  onPress={()=>{this.onDataChange(tag, currEditBtn, {sizeScale:sizeScale+1})}}>
                                <Image style={{margin:10,width:18,height:18}} source={Images.tool_szie_add} />
                            </TouchableOpacity>
                            <Text style={styles.tool_details_size_text}>{sizeScale}</Text>
                        </View>

                    </View>
                );
            }
            case 3:{ // 字体工具
                return (
                    <View style={styles.tool_details_view}>
                        {
                            FontList.map((obj, index)=>
                                <TouchableOpacity key={index} style={styles.tool_details_font_btn} onPress={()=>this.onDataChange(tag, currEditBtn, obj.data)}>
                                    <Text style={[styles.tool_details_font_text, {fontFamily:obj.fontFamily}, {color:fontFamily === obj.fontFamily ? Colors.theme_first_s: Colors.theme_FFFFFF_s}]}>{obj.name}</Text>
                                </TouchableOpacity>
                            )
                        }
                    </View>
                );
            }
            case 4:{ // 移动工具
                return (
                    <View style={styles.tool_details_view}>
                        {
                            MoveList.map((obj, index)=>
                                <TouchableOpacity key={index} style={styles.tool_details_move_btn} onPress={()=>this.onDataChange(tag, currEditBtn, obj.data)}>
                                    <Image style={styles.tool_main_image} source={obj.icon} />
                                </TouchableOpacity>
                            )
                        }
                    </View>
                );
            }
            default:break;
        }

    };


    getListDict(){

        const {Images} = Theme;

        return {
            ButtonList : [
                {
                    tag:0,
                    defIcon:Images.tool_btn_edit_def,
                    selIcon:Images.tool_btn_edit_def,
                },
                {
                    tag:1,
                    defIcon:Images.tool_btn_color_def,
                    selIcon:Images.tool_btn_color_sel,
                },
                {
                    tag:2,
                    defIcon:Images.tool_btn_size_def,
                    selIcon:Images.tool_btn_size_sel,
                },
                {
                    tag:3,
                    defIcon:Images.tool_btn_font_def,
                    selIcon:Images.tool_btn_font_sel,
                },
                {
                    tag:4,
                    defIcon:Images.tool_btn_move_def,
                    selIcon:Images.tool_btn_move_sel,
                },
            ],
            ColorList : [
                {
                    color:BgToolColor.White,
                    data:{color:BgToolColor.White},
                    defIcon:Images.tool_color_bg_white_def,
                    selIcon:Images.tool_color_bg_white_sel,
                },
                {
                    color:BgToolColor.Beige,
                    data:{color:BgToolColor.Beige},
                    defIcon:Images.tool_color_bg_beige_def,
                    selIcon:Images.tool_color_bg_beige_sel,
                },
                {
                    color:BgToolColor.Brown,
                    data:{color:BgToolColor.Brown},
                    defIcon:Images.tool_color_bg_brown_def,
                    selIcon:Images.tool_color_bg_brown_sel,
                },
                {
                    color:BgToolColor.Purple,
                    data:{color:BgToolColor.Purple},
                    defIcon:Images.tool_color_bg_purple_def,
                    selIcon:Images.tool_color_bg_purple_sel,
                },
                {
                    color:BgToolColor.Black,
                    data:{color:BgToolColor.Black},
                    defIcon:Images.tool_color_bg_black_def,
                    selIcon:Images.tool_color_bg_black_sel,
                },
            ],
            FontList : [
                {
                    name:T("标楷体"),
                    fontFamily:ToolFont.BiaoKaiTi,
                    data:{fontFamily:ToolFont.BiaoKaiTi},
                },
                {
                    name:T("新细明体"),
                    fontFamily:ToolFont.MingLiu,
                    data:{fontFamily:ToolFont.MingLiu},
                },
                {
                    name:T("黑体"),
                    fontFamily:ToolFont.HeiTi,
                    data:{fontFamily:ToolFont.HeiTi},
                },
                {
                    name:T("宋体"),
                    fontFamily:ToolFont.SongTi,
                    data:{fontFamily:ToolFont.SongTi},
                },
                {
                    name:T("楷体"),
                    fontFamily:ToolFont.KaiTi,
                    data:{fontFamily:ToolFont.KaiTi},
                },
            ],
            MoveList : [
                {
                    data:{lastOffset:{x:-defOffset,y:0}},
                    icon:Images.tool_move_left,
                },
                {
                    data:{lastOffset:{x:defOffset,y:0}},
                    icon:Images.tool_move_right,
                },
                {
                    data:{lastOffset:{x:0,y:-defOffset}},
                    icon:Images.tool_move_up,
                },
                {
                    data:{lastOffset:{x:0,y:defOffset}},
                    icon:Images.tool_move_down,
                },
            ],
        }
    };

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            tool_view:{
                width:screenWidth,
                height:150,
                position:'absolute',
                bottom:0,
            },
            tool_details_stance: {
                flex:1,
                backgroundColor:Colors.theme_00000000_s,
            },
            tool_details_view: {
                flex:1,
                backgroundColor:Colors.theme_second_s,
                borderBottomWidth: 0.5,
                borderBottomColor:Colors.theme_FFFFFF_s,
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'row',
            },
            tool_details_size_view: {
                flexDirection:'row',
                justifyContent:'center',
                alignItems:'center',
                marginLeft:20,
                marginRight:20,
            },
            tool_details_size_slider: {
                marginLeft:40,
                width:200,
                height:80,
            },
            tool_details_size_text: {
                width:45,
                paddingTop:5,
                paddingBottom:5,
                margin:10,
                borderRadius:5,
                overflow:'hidden',
                textAlign:'center',
                color:Colors.theme_FFFFFF_s,
                backgroundColor:Colors.boxbg_edit_tool_FF9E7F_s,
            },
            tool_details_font_btn:{
                alignItems:'center',
                justifyContent:'center',
            },
            tool_details_font_text:{
                fontSize:17,
                margin:10,
            },
            tool_details_move_btn:{
                width:80,
                height:80,
                alignItems:'center',
                justifyContent:'center',
            },
            tool_details_move_img:{
                width:20,
                height:20,
                resizeMode:'contain',
            },
            tool_details_color_btn:{
                width:80,
                height:80,
                alignItems:'center',
                justifyContent:'center',
            },
            tool_details_color_img:{
                resizeMode:'contain',
                width:20,
                height:20
            },
            tool_details_color_img_sel:{
                resizeMode:'contain',
                width:25,
                height:25
            },
            tool_main_view: {
                height:60,
                backgroundColor:Colors.theme_second_s,
                flexDirection:'row',
            },
            tool_main_button: {
                flex:1,
                alignItems:'center',
                justifyContent:'center',
            },
            tool_main_image: {
                width:20,
                height:20,
                resizeMode:'contain',
            },
        })
    }
}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
            currIndex:state.plasticBoxBg.currIndex,
            editButtonList:state.plasticBoxBg.editButtonList,
        })
    }
)(GSBoxToolView)


// export default GSBoxToolView;