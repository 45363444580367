/**
 * GSZhiZuo
 * GSTestPage.js
 *
 * @author: zengzhiming
 * @date:   2022/8/22 14:37
 * @desc:   GSTestPage
 **/
'use strict';

import React, {Component} from 'react';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity
} from 'react-native';


class GSTestPage extends Component {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }


    onPress = () => {
        alert('点击按钮');
    };

    render() {
        return (
            <SafeAreaView style={{flex: 1}}>
                <View style={styles.container}>
                    <TouchableOpacity onPress={this.onPress}>
                        <Text>点击按钮</Text>
                    </TouchableOpacity>
                </View>
            </SafeAreaView>
        );
    }
}


const styles = StyleSheet.create({
    container: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});


export default GSTestPage;
