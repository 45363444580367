/**
 * GSZhiZuo
 * GSDefined.js
 *
 * @author: zengzhiming
 * @date:   2023/6/5
 * @desc:   GSDefined
 **/
'use strict';
import {Animated} from "react-native";

const defFont = 17;
export const minFont = 10;
export const maxFont = 100;

export const minScale = 0.5;
export const maxScale = 2;


export const ToolColor = {
    Blue: '#0000EE',
    White:'#FFFFFF',
    Red:  '#FF0000',
    Brown:'#2B0200',
};

export const BgToolColor = {
    White:'#FFFFFF',
    Beige: '#FED385',
    Brown:"#871003",
    Purple:  '#5C2483',
    Black:'#000000',
};

export const ToolFont = {
    BiaoKaiTi:"DFKai-SB",
    MingLiu:"MingLiU",
    HeiTi:"SimHei",
    SongTi:"SimSun",
    KaiTi:"KaiTi",
};



export const BoxModel = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    Type:'',
    BtnList:[],
};


export const TextModel = {
    textValue:'',
    textDirection:1,
    fontFamily:ToolFont.BiaoKaiTi,
    color:BgToolColor.Brown,

    /* Pinching */
    sizeScale : defFont,

    /* Rotation */
    lastRotate : 0,

    /* Pan */
    lastOffset : {x:0, y:0},

    animatedId:undefined,
};


export const ImageModel = {
    imgUri:'',
    width:0,
    height:0,

    /* Pinching */
    sizeScale : 1,

    /* Rotation */
    lastRotate : 0,

    /* Pan */
    lastOffset : {x:0, y:0},

    animatedId:undefined,
};





