/**
 * GSZhiZuo
 * GSRemindUpdateView.js
 *
 * @author: zengzhiming
 * @date:   2023/6/1
 * @desc:   GSRemindUpdateView
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
} from 'react-native';
import BaseView from "../../../Common/Component/BaseComponent/GSBaseView";

class GSRemindUpdateView extends BaseView {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            message : '',
            btnTitle : '',
            clickBlock:undefined,
            showUpdate : false,
        };
    }

    componentDidMount() {
        this.props.myRef && this.props.myRef(this);
    }


    // 显示更新
    showUpdate = (message, btnTitle, clickBlock) => {

        if (isEmptyString(message)){
            return;
        }

        if (isEmptyString(btnTitle)){
            btnTitle = 'Update';
        }

        this.setState({
            message : message,
            btnTitle : btnTitle,
            clickBlock : clickBlock,
            showUpdate : true,
        });
    };

    // 隐藏更新
    hiddenUpdate = () => {
        this.setState({
            message : '',
            btnTitle : '',
            clickBlock:undefined,
            showUpdate : false,
        });
    };


    render() {

        const styles = this.getStyles();

        let {message, btnTitle, showUpdate, clickBlock } = this.state;

        return (
            showUpdate &&
            <View style={[styles.update_view]}>
                <Text style={styles.update_msg}>{message}</Text>
                <TouchableOpacity onPress={clickBlock}>
                    <Text style={styles.update_btn}>{btnTitle}</Text>
                </TouchableOpacity>
            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            update_view:{
                height:30,
                alignItems: 'center',
                position: 'absolute',
                left: 0,
                right: 0,
                backgroundColor:Colors.goldbar_home_remind_FFF7D3_s,
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
            update_msg:{
                marginLeft: 16,
                fontSize:14,
                color:Colors.goldbar_home_remind_312E17_s,
            },
            update_btn:{
                marginRight:16,
                fontSize:14,
                paddingLeft:8,
                paddingRight:8,
                paddingTop:4,
                paddingBottom:4,
                borderRadius:5,
                overflow:'hidden',
                color:Colors.theme_FFFFFF_s,
                backgroundColor:Colors.goldbar_home_remind_312E17_s,
            }
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSRemindUpdateView)


// export default GSRemindUpdateView;