/**
 * GSZhiZuo
 * GSBoxBgPreviewPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/26
 * @desc:   GSBoxBgPreviewPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import QRCodeSvg from 'react-native-qrcode-svg';
import {md5, randomUUID} from "../../Common/Utils/GSCrypto";
import {captureViewToBase64Async} from "../../Common/Utils/GSCaptureImage";


class GSBoxBgPreviewPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        const {images, boxWord, boxData} = props.route.params

        const imgMD5 = md5(images["image1"]).toString();
        const fileName = "background_"+imgMD5+".jpg";
        const directory = boxData["SizeName"]+"_"+boxData["Length"]+"_"+boxData["Height"]+"_"+randomUUID();
        // const imgUrl = "https://www.gsgoldprice.com/picmake/Model/images/"+directory+"/"+fileName;
        const imgUrl = "https://www.gsgoldprice.com/picmake/Model/index.aspx?fileDirectory="+directory+"/";

        this.state = {
            directory:directory,
            fileName:fileName,
            imgUrl:imgUrl,
        };

        this.setHeaderRightButton();
    }


    setHeaderRightButton = () => {
        // 设置导航栏右边按钮
        this.props.navigation.setOptions({
            headerRight: (props) => {
                return (
                    <TouchableOpacity style={{flexDirection:"row", alignItems:"center", padding:8}} onPress={()=>{this.onRightButtonPress()}} >
                        <Text style={{fontSize:17}}>{T("下一步")}</Text>
                    </TouchableOpacity>
                );
            },
        });
    }

    onRightButtonPress = async () => {

        const {images, boxData, boxWord} = this.props.route.params;
        const {directory, fileName, imgUrl} = this.state;

        const imgInfo = {
            directory:directory,
            fileName:fileName,
        };

        const image3 = await captureViewToBase64Async(this.qrcodeView, null);

        // 跳转到用户信息页面
        this.push(SceneKey.BoxBgUserInfo,{
            images:{
                ...images,
                "image3":image3
            },
            boxWord:boxWord,
            imgInfo:imgInfo,
            boxData:boxData,
        });
    };

    render() {

        const {Images, Colors} = Theme;
        const {directory, fileName, imgUrl} = this.state;
        const {images, boxData, boxWord} = this.props.route.params;

        const images1 = images["image1"].replace("http://", "https://");
        const aspectRatio = parseFloat(boxData["Length"])/parseFloat(boxData["Height"]);

        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View style={styles.content_view}>
                            <View style={{width:"100%", aspectRatio:aspectRatio}}>
                                <Image style={{width:"100%", height:"100%", backgroundColor:Colors.box_edit_sign_EDEDED}} resizeMode={"contain"} source={{uri:images1}} />
                            </View>
                            <View ref={(ref)=>{this.qrcodeView = ref}}  style={styles.connect_qrcode_view}>
                                <View style={styles.connect_qrcode_item_view}>
                                    <Image style={styles.connect_qrcode_item_img} resizeMode={"stretch"} source={Images.boxbg_preview_qrcode_1} />
                                    <Text style={styles.connect_qrcode_item_title}>{"lukfook.com"}</Text>
                                </View>
                                <View style={styles.connect_qrcode_item_view}>
                                    <Image style={styles.connect_qrcode_item_img} resizeMode={"stretch"} source={Images.boxbg_preview_qrcode_2} />
                                    <Text style={styles.connect_qrcode_item_title}>{T("电铸产品")}</Text>
                                </View>
                                <View style={styles.connect_qrcode_item_view}>
                                    <QRCodeSvg
                                        size={80}
                                        value={imgUrl}
                                    />
                                    <Text style={styles.connect_qrcode_item_title}>{T("下载背景图")}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: Colors.theme_FFFFFF,
            },
            content_view:{
                width:"100%",
                paddingHorizontal:40,
            },
            connect_qrcode_view:{
                flexDirection:"row",
                paddingVertical:8,
                width:"100%",
                marginTop:30,
                borderWidth:1,
                borderColor:Colors.theme_000000_s,
                backgroundColor:Colors.theme_FFFFFF_s,
            },
            connect_qrcode_item_view:{
                flex:1,
                alignItems:"center",
            },
            connect_qrcode_item_img:{
                width:80,
                height:80,
            },
            connect_qrcode_item_title:{
                marginTop:8,
                fontSize:10,
                color:Colors.boxbg_preview_555555_s,
            },
        })
    }
}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSBoxBgPreviewPage)


// export default GSBoxBgPreviewPage;
