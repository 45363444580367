/**
 * GSZhiZuo
 * GSBoxBgSelectPage.js
 *
 * @author: zengzhiming
 * @date:   2023/6/26
 * @desc:   GSBoxBgSelectPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    FlatList,
    Image,
    ScrollView,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import {getBoxBackgroundListData} from '../../Common/Http/GSHttpRequest';

class GSBoxBgSelectPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            dataList:[],
            selData:{
                selGroup:{},
                selImg:{},
                selSize:{},
            },
        };

        this.getBoxBackgroundList();
        this.headerRightButton();

    }

    // 设置导航栏右边按钮
    headerRightButton(){

        this.props.navigation.setOptions({
            headerRight: (props) => {
                return (
                    <TouchableOpacity style={{flexDirection:"row", alignItems:"center", padding:8}} onPress={()=>{this.onRightButtonPress()}} >
                        <Text style={{fontSize:17}}>{T("下一步")}</Text>
                    </TouchableOpacity>
                );
            },
        });
    }

    // 主页面刷新
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const selSize = nextProps.route?.params?.["selSize"];
        if (this.props.route?.params?.["selSize"] != selSize) {
            nextState["selData"]["selSize"] = selSize;
        }
        return true;
    }


    getBoxBackgroundList = () => {
        showLoading(T("加载数据..."));
        getBoxBackgroundListData((code, data)=>{
            // log(data);
            const selGroup = data[0];
            const selImg = selGroup["list"][0];
            closeLoading();
            this.setState({
                dataList:data,
                selData:{
                    selGroup:selGroup,
                    selImg:selImg,
                    selSize:{},
                },
            });
        },(code, data)=>{
            closeLoading();
            showToast(T("获取数据失败"));
        })
    };

    onRightButtonPress = () => {

        const {selSize} = this.state["selData"];

        if (isEmptyObject(selSize)) {
            showToast(T("请先选择尺寸"));
            return;
        }

        this.push(SceneKey.BoxBgEdit,{"boxData":selSize});
    };


    onMemorialItemPress = (item, index) => {

        const selImg = item?.["list"]?.[0] || {};
        this.setState({
            selData:{
                selGroup:item,
                selImg:selImg,
                selSize:{},
            },
        });
    };

    onPictureItemPress = (item, index, selGroup) => {

        this.setState({
            selData:{
                selGroup:selGroup,
                selImg:item,
                selSize:{},
            },
        });
    };

    onSizeSelect = (selData, selImg) => {
        if (isEmptyObject(selImg)) {
            showToast(T("请先选择图样"));
            return;
        }
        this.push(SceneKey.BoxBgSizeSelect,{"dataList":selImg["list"]});
    }

    render() {

        const {dataList, selData} = this.state;
        const {selGroup, selImg, selSize} = selData;

        const sizeStr = isEmptyObject(selSize) ? "" :  "【"+selSize["SizeName"]+"】"+selSize["Length"]+" x "+selSize["Height"];
        const sizeImg = isEmptyObject(selSize) ? "" :  selSize["ImageUrl"];

        const {Images} = Theme;
        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <ScrollView style={styles.container}>
                        {
                            // <View style={styles.row_view}>
                            //     <View>
                            //         <Text style={styles.row_title_text}>{T("纪念日")}</Text>
                            //     </View>
                            //     <View style={{marginTop:10}}>
                            //         <FlatList
                            //             data={dataList}
                            //             renderItem={({item, index}) => this.renderMemorialItem(item, index)}
                            //             numColumns={3}
                            //             showsVerticalScrollIndicator = {false}
                            //             keyExtractor={(item, index) => index+""}
                            //             scrollEnabled={false}
                            //         />
                            //     </View>
                            // </View>
                        }
                        <View style={styles.row_view}>
                            <View>
                                <Text style={styles.row_title_text}>{T("图样")}</Text>
                            </View>
                            <View style={{marginTop:10, marginLeft:30}}>
                                <FlatList
                                    data={selGroup["list"]}
                                    renderItem={({item, index}) => this.renderPictureItem(item, index)}
                                    numColumns={2}
                                    showsVerticalScrollIndicator = {false}
                                    keyExtractor={(item, index) => index+""}
                                    scrollEnabled={false}
                                />
                            </View>
                        </View>
                        <View style={styles.row_view}>
                            <TouchableOpacity onPress={()=>{this.onSizeSelect(selData, selImg)}} style={styles.row_view_btn}>
                                <View><Text  style={styles.row_title_text}>{T("尺寸")}</Text></View>
                                <View style={styles.row_title_right_view}>
                                    {sizeImg !== "" && <Image style={{width:20, height:20}} source={{uri:sizeImg}} />}
                                    <Text style={styles.row_title_right_text}>{sizeStr}</Text>
                                    <Image style={{width:8, height:14}} source={Images.boxbg_arrows_right} />
                                </View>
                            </TouchableOpacity>
                        </View>
                    </ScrollView>
                </SafeAreaView>
            </View>
        );
    }


    renderMemorialItem = (item, index)=> {

        // if (index > 0) {
        //     return <View />
        // }

        const {selGroup} = this.state["selData"];
        const isSel = selGroup == item;
        const {Colors} = Theme;

        const styles = this.getStyles();
        return (
            <TouchableOpacity
                onPress={()=>{this.onMemorialItemPress(item, index)}}
                style={[styles.row_list_btn,{backgroundColor: isSel ? Colors.theme_first_s : Colors.boxbg_select_F0F4F7}]}
            >
                <Text style={{fontSize:18, color:isSel ? Colors.theme_000000_s : Colors.boxbg_select_666666,}} >
                    {item["TypeName"]}
                </Text>
            </TouchableOpacity>
        );
    };

    renderPictureItem = (item, index)=> {

        // if (index > 0) {
        //     return <View />
        // }

        const {Images, Colors} = Theme;
        const {selGroup, selImg} = this.state["selData"];
        const borderColor = selImg == item ? Colors.theme_first_s:Colors.boxbg_select_F0F4F7;


        const imgUrl = isEmptyString(item["ImageUrl"]) ? Images.boxbg_def_img : {uri:item["ImageUrl"]};

        const styles = this.getStyles();

        return (
            <TouchableOpacity
                onPress={()=>{this.onPictureItemPress(item, index, selGroup)}}
                style={styles.list_item_btn}
            >
                <View style={[styles.list_item_img_box,{borderColor:borderColor}]}>
                    <Image style={styles.list_item_img} source={imgUrl} />
                </View>
                {/*<Text style={styles.list_item_title} >*/}
                {/*    {item["ImageName"]}*/}
                {/*</Text>*/}
            </TouchableOpacity>
        );
    }


    getStyles() {
        const {Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            container: {
                flex: 1,
                backgroundColor: Colors.theme_FFFFFF,
            },
            row_view: {
                padding: 16,
                borderBottomColor: Colors.boxbg_select_F0F4F7,
                borderBottomWidth: 1
            },
            row_view_btn: {
                flexDirection: "row",
                justifyContent: "space-between",
            },
            row_title_text: {
                fontSize: 18,
                fontWeight: "bold",
                color:Colors.theme_000000,
            },
            row_title_right_view: {
                flexDirection: "row",
                alignItems: "center"
            },
            row_title_right_text: {
                fontSize: 18,
                marginRight: 18,
                color:Colors.theme_000000,
            },
            row_img_view: {
                flexDirection: "row",
                justifyContent: "center",
                marginVertical: 16,
            },

            row_list_btn: {
                width: "31.33%",
                paddingVertical: 3,
                margin: "1%",
                alignItems: "center",
                borderRadius: 5,
                overflow: "hidden",
            },
            list_item_btn:{
                width:"40%",
                marginHorizontal:"5%",
                alignItems:"center",
                marginVertical:8,
            },
            list_item_img_box:{
                borderRadius:8,
                overflow:"hidden",
                borderWidth:3,
                padding:5,
                width:"100%",
                aspectRatio:1,
            },
            list_item_img:{
                width:"100%",
                height:"100%",
                borderRadius:5,
                overflow:"hidden",
            },
            list_item_title:{
                fontSize:18,
                color:Colors.theme_000000,
                marginTop:12
            },

        });
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSBoxBgSelectPage)


// export default GSBoxBgSelectPage;
